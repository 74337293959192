import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { TypographyHeading, CustomTypography } from "../../../styles";
import { getCustomerTabNotes, getCustomerTabExtraNotes } from "../../../api";
import moment from "moment";
import Note from "./Note";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

const Notes = ({ id }) => {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState(false);
  const [extraNotes, setExtraNotes] = useState([]);

  const handleNote = () => {
    setOpen(!open);
  };

  function createMarkup(data) {
    return { __html: data };
  }

  useEffect(() => {
    const fetchNotes = async () => {
      const data = await getCustomerTabNotes(id);
      setNotes(data.per_product_information);
    };
    const fetchExtraNotes = async () => {
      const data = await getCustomerTabExtraNotes(id);
      // console.log(data);
      if (data.error === "none") {
        console.log(data.ordersnote);
        setExtraNotes(data.ordersnote);
      }
    };
    if (id) {
      fetchNotes();
      fetchExtraNotes();
    }
  }, [id, res]);
  return (
    <Container className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TypographyHeading>Customer Notes</TypographyHeading>
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={handleNote}
        >
          Add Note
        </Button>
      </Box>
      {open && <Note setOpen={setOpen} id={id} setRes={setRes} />}
      <Paper sx={{ p: 3, mt: 4, width: "70vw" }} className={classes.fullWidth}>
        <Typography>Notes</Typography>
        <Divider />
        {(notes || []).map((item) => {
          console.log(item.additional_information, 1);
          return (
            <Box key={item.id}>
              <Box
                sx={{
                  backgroundColor: "#d7cad2",
                  padding: "10px",
                  position: "relative",
                  lineHeight: "1.4",
                  fontSize: "13px",
                  color: "#3c434a",
                }}
                my={2}
              >
                <CustomTypography>
                  {item.additional_information}
                </CustomTypography>
                <Box
                  sx={{
                    position: "absolute",
                    borderTop: "10px solid transparent",
                    borderBottom: "10px solid transparent",
                    borderLeft: "10px solid #d7cad2",
                    bottom: "-10px",
                    left: "20px",
                  }}
                />
              </Box>
              <Box color="#999" fontSize="11px" lineHeight="1.5">
                {moment(item.date_created).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                <span> By Customer (Generated On Printyo)</span>
              </Box>
            </Box>
          );
        })}
        {(extraNotes || []).map((item) => {
          return (
            <Box key={item.id}>
              <Box
                sx={{
                  backgroundColor: "#d7cad2",
                  padding: "10px",
                  position: "relative",
                  lineHeight: "1.4",
                  fontSize: "13px",
                  color: "#3c434a",
                }}
                my={2}
              >
                <Box>
                  <Box>
                    {/* {item.ordernotetext && item.ordernotetext[0]
                      ? item.ordernotetext[0].text
                      : ""} */}
                    <div
                      style={{ display: "inline-block" }}
                      dangerouslySetInnerHTML={createMarkup(
                        item.ordernotetext && item.ordernotetext[0]
                          ? item.ordernotetext[0].text
                          : ""
                      )}
                    ></div>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    borderTop: "10px solid transparent",
                    borderBottom: "10px solid transparent",
                    borderLeft: "10px solid #d7cad2",
                    bottom: "-10px",
                    left: "20px",
                  }}
                />
              </Box>
              <Box color="#999" fontSize="11px" lineHeight="1.5">
                {item.ordernotetext && item.ordernotetext[0]
                  ? moment(item.ordernotetext[0].created_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  : ""}{" "}
                <span>
                  {" "}
                  By {item.ordernoteuser ? item.ordernoteuser.name : "Customer"}
                </span>
              </Box>
            </Box>
          );
        })}
      </Paper>
    </Container>
  );
};

export default Notes;
