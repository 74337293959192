import React from "react";
import { Route } from "react-router-dom";
import AccessDenied from "./components/AccessDenied";

export const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  const authrole = parseInt(localStorage.getItem("auth_role"));
  const authpermission = JSON.parse(localStorage.getItem("auth_permission"));
  console.log(roles);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authpermission.includes(roles)) {
          return <Component {...props} />;
        } else {
          return <AccessDenied />;
        }
      }}
    />
  );
};
