import { track } from "@amplitude/analytics-browser";
import { identify, Identify } from "@amplitude/analytics-browser";
import { createLogs } from ".";
import axios from "axios";
export default function TrackEvents(eventname, eventproperties) {
  const auth_email = localStorage.getItem("auth_email");
  const name = localStorage.getItem("auth_name");
  //  eventproperties = { ...eventproperties, fullname: name };
  const identifyObj = new Identify();
  identifyObj.set("user_name", name);

  // track(eventname, eventproperties, {
  //   user_id: auth_email,
  // });

  var data = new FormData();
  data.append("email", auth_email);
  data.append("name", name);
  data.append("logs", eventproperties.process);
  data.append("orderid", eventname);
  const addLogs = createLogs(data);
  console.log(addLogs);
}
