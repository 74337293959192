import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
  marginBottom: "1rem",
}));
export const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
}));
export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));
