import React, { useCallback, useState } from "react";
import {
  DialogTitle,
  Dialog,
  IconButton,
  Stack,
  DialogContent,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
        width: "100%",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "unset !important",
    },
  },
}));

// const TypographyHeading = styled(Typography)(({ theme }) => ({
//     fontSize: '14px',
//     lineHeight: '1.4em',
//     fontWeight: 'bold',
// }));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));

const AddShipping = (props) => {
  const classes = useStyles();
  const {
    setOpenShippingModal,
    openShippingModal,
    shippingInfo,
    setShippingInfo,
    expressPrice,
    standardPrice,
  } = props;

  const [customShipping, setCustomShipping] = useState(false);
  const [customShippingInfo, setCustomShippingInfo] = useState({
    customShippingMethod: "prinntyo_custom_shipping_method",
    customShippingName: "",
    customShippingPrice: "",
  });

  const handleClose = () => {
    setOpenShippingModal(false);
    setCustomShipping(false);
  };

  const handleAddShipping = () => {
    if (shippingInfo.shippingName === "Australian Post Standard") {
      // setShippingPrice(standardPrice)
      setShippingInfo({ ...shippingInfo, shippingPrice: standardPrice });
    } else if (shippingInfo.shippingName === "Australian Post Express") {
      // setShippingPrice(expressPrice)
      setShippingInfo({ ...shippingInfo, shippingPrice: expressPrice });
    } else if (customShipping) {
      setShippingInfo({
        ...shippingInfo,
        shippingMethod: customShippingInfo.customShippingMethod,
        shippingName: customShippingInfo.customShippingName,
        shippingPrice: customShippingInfo.customShippingPrice,
      });
    } else {
      setShippingInfo({ ...shippingInfo, shippingPrice: "0" });
    }
    setOpenShippingModal(false);
    setCustomShipping(false);
  };

  const handleChange = useCallback(
    (e) => {
      // setShippingMethod(e.target.value)
      setShippingInfo({ ...shippingInfo, shippingName: e.target.value });
    },
    [setShippingInfo, shippingInfo]
  );

  const handleCustomShippingChange = (e) => {
    setCustomShippingInfo({
      ...customShippingInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleCustomShipping = () => {
    setCustomShipping(!customShipping);
    setShippingInfo({ shippingName: "", shippingPrice: "" });
    setCustomShippingInfo({
      customShippingMethod: "prinntyo_custom_shipping_method",
      customShippingName: "",
      customShippingPrice: "",
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={openShippingModal}
      className={classes.modal}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Add shipping</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Box minWidth={400} className={classes.fullWidth}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              sx={{
                color: "#3c434a",
                fontSize: "13px",
                lineHeight: "1.4em",
                fontWeight: "bold",
              }}
            >
              Shipping
            </FormLabel>
            <RadioGroup
              aria-label="shipping"
              defaultValue="shipping"
              name="shipping"
              onChange={handleChange}
              value={shippingInfo.shippingName}
            >
              <FormControlLabel
                value="Free pick-up from store"
                control={<Radio fontSize="small" />}
                sx={{ my: 1 }}
                label={
                  <>
                    <TypographySubHeading>
                      Free pick-up from store
                    </TypographySubHeading>
                    <CustomTypography>
                      Address: 5 Wall Street, Thomastown, VIC 3074
                    </CustomTypography>
                  </>
                }
              />
              <FormControlLabel
                value="Australian Post Standard"
                control={<Radio fontSize="small" />}
                sx={{ my: 1 }}
                label={
                  <>
                    <TypographySubHeading>
                      Australian Post Standard:
                    </TypographySubHeading>
                    <TypographySubHeading>
                      $ {standardPrice}
                    </TypographySubHeading>
                    <CustomTypography>
                      Delivered in 3-7 business days
                    </CustomTypography>
                  </>
                }
              />
              <FormControlLabel
                value="Australian Post Express"
                control={<Radio fontSize="small" />}
                sx={{ my: 1 }}
                label={
                  <>
                    <TypographySubHeading>
                      Australian Post Express:
                    </TypographySubHeading>
                    <TypographySubHeading>${expressPrice}</TypographySubHeading>
                    <CustomTypography>
                      Delivered in 1-2 business days
                    </CustomTypography>
                  </>
                }
              />
            </RadioGroup>
            {customShipping ? (
              <Box my={2}>
                <TextField
                  type="text"
                  size="small"
                  label="Shipping name"
                  name="customShippingName"
                  onChange={handleCustomShippingChange}
                  value={customShippingInfo.customShippingName}
                  fullWidth
                />
                <TextField
                  size="small"
                  label="Shipping price"
                  name="customShippingPrice"
                  type="number"
                  onChange={handleCustomShippingChange}
                  value={customShippingInfo.customShippingPrice}
                  fullWidth
                  sx={{ mt: "16px" }}
                />
              </Box>
            ) : null}
          </FormControl>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{ textTransform: "none", mr: "16px" }}
              onClick={handleCustomShipping}
            >
              {customShipping ? "Hide custom shipping" : "Add custom shipping" }
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleAddShipping}
            >
              Add
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddShipping;
