import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getAllRoles } from "../../api";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
}));

const AddUser = (props) => {
  const {
    openModel,
    handleClose,
    handleAddUser,
    loading,
    userInfo,
    handleChange,
    passError,
    nameValError,
    emailValError,
    passValError,
  } = props;
  const classes = useStyles();
  const [rolelist, setRoleList] = useState();
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const data = await getAllRoles();
        if (data.status === 200) {
          setRoleList(data.roles);
        }
      } catch (error) {
        console.log("error form Users", error);
      }
    };
    fetchRoles();
  }, []);

  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Add User</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.fullWidth}>
        <TextField
          required
          helperText={nameValError.helperText}
          error={nameValError.error}
          size="small"
          label="Name"
          name="name"
          type="text"
          fullWidth
          onChange={handleChange}
          value={userInfo.name}
          style={{ marginBottom: "20px" }}
        />
        <TextField
          required
          helperText={emailValError.helperText}
          error={emailValError.error}
          size="small"
          label="Email"
          name="email"
          fullWidth
          type="email"
          onChange={handleChange}
          value={userInfo.email}
          style={{ marginBottom: "20px" }}
        />
        <InputLabel id="demo-simple-select-label">User Role</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Select Role"
          onChange={handleChange}
          fullWidth
          name="roleid"
          defaultValue={3}
          style={{ marginBottom: "20px" }}
        >
          {rolelist?.map((role) => {
            return <MenuItem value={role.id}>{role.role_name}</MenuItem>;
          })}
        </Select>
        <TextField
          required
          helperText={passValError.helperText}
          error={passValError.error}
          size="small"
          label="Password"
          name="password"
          type="password"
          onChange={handleChange}
          value={userInfo.password}
          fullWidth
          style={{ marginBottom: "20px" }}
        />
        <TextField
          required
          helperText={passError.helperText}
          error={passError.error}
          size="small"
          label="Confirm Password"
          name="password_confirmation"
          type="password"
          onChange={handleChange}
          value={userInfo.password_confirmation}
          fullWidth
        />
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <Button
          onClick={handleAddUser}
          disabled={loading}
          variant="contained"
          size="small"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;
