import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  makeStyles,
  Grid,
  CardContent,
  CardActions,
  IconButton,
} from "@material-ui/core";
import ForwardIcon from "@material-ui/icons/Forward";
import TodayOrders from "./TodayOrders";
import {
  getTotalOrderCount,
  getTodayOrderCount,
  getLeadOnDate,
  getTodaySales,
} from "../../api";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "1.8rem",
    lineHeight: "1.4em",
    marginBottom: "10px",
  },
  card: {
    minWidth: "250px",
    backgroundColor: "#17a2b8",
    color: "#fff",
    // height: '120px',
    // padding: '10px 20px'
  },
  content: {
    marginTop: ".5rem",
  },
  cardAction: {
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.1)",
  },
  subHeading: {
    marginBottom: "1rem",
  },
}));

const DashBoard = () => {
  const classes = useStyles();
  const [totalOrders, setTotalOrders] = useState(0);
  const [todayOrders, setTodayOrders] = useState(0);
  const [leads, setLeads] = useState([]);
  const [todaysale, setTodaySale] = useState(0);
  // const [page, setPage] = useState(1)

  useEffect(() => {
    let rowDate = new Date();
    let year = rowDate.getFullYear();
    let month = rowDate.getMonth() + 1 + "";
    if (month.length === 1) month = "0" + month;
    let day = rowDate.getDate() + "";
    if (day.length === 1) day = "0" + day;
    const date = day + month + year;

    const getTodayLeads = async () => {
      try {
        const data = await getLeadOnDate(date);
        setLeads(data);
      } catch (error) {
        console.log(error);
      }
    };
    const getTotalOrder = async () => {
      try {
        const data = await getTotalOrderCount();
        setTotalOrders(data.orders_count);
      } catch (error) {
        console.log(error);
      }
    };
    const todayOrderCount = async () => {
      try {
        const data = await getTodayOrderCount();
        setTodayOrders(data.orders_count);
      } catch (error) {
        console.log(error);
      }
    };

    const todaySaleCount = async () => {
      try {
        const data = await getTodaySales();
        setTodaySale(data.responseData.total_cost.toFixed(2));
        console.log(data.responseData.total_cost);
      } catch (error) {
        console.log(error);
      }
    };

    getTodayLeads();
    getTotalOrder();
    todayOrderCount();
    todaySaleCount();
  }, []);
  const authrole = JSON.parse(localStorage.getItem("auth_role"));

  return (
    <Layout>
      <Box p={2} bgcolor="#f0f0f1" minHeight={`calc(100vh - 70px)`}>
        <Typography className={classes.heading}>Dashboard</Typography>
        <Grid container spacing={2}>
          {/* <Grid item xs={6} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant='h4'>
                                    {totalOrders}
                                </Typography>
                                <Typography variant='h6' className={classes.content}>
                                    Total Orders
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.cardAction}>
                                <Typography>More info</Typography>
                                <IconButton component={Link} to="/order/1">
                                    <ForwardIcon fontSize='small' />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#28a745" }}
            >
              <CardContent>
                <Typography variant="h4">{todayOrders}</Typography>
                <Typography variant="h6" className={classes.content}>
                  Today's Orders
                </Typography>
              </CardContent>
              <CardActions className={classes.cardAction}>
                <Typography>More info</Typography>
                <IconButton component={Link} to="/pending-order/1">
                  <ForwardIcon fontSize="small" />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#ffc107" }}
            >
              <CardContent>
                <Typography variant="h4">{leads ? leads.length : 0}</Typography>
                <Typography variant="h6" className={classes.content}>
                  Today's leads
                </Typography>
              </CardContent>
              <CardActions className={classes.cardAction}>
                <Typography>More info</Typography>
                <IconButton component={Link} to="/leads">
                  <ForwardIcon fontSize="small" />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
          {authrole == 1 ? (
            <Grid item xs={12} sm={6} md={3}>
              <Card
                className={classes.card}
                style={{ backgroundColor: "#ff1493" }}
              >
                <CardContent>
                  <Typography variant="h4">
                    {leads ? `$${todaysale}` : 0}
                  </Typography>
                  <Typography variant="h6" className={classes.content}>
                    Today's Total Sale
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardAction}>
                  <Typography>More info</Typography>
                  <IconButton component={Link} to="/leads">
                    <ForwardIcon fontSize="small" />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ) : null}
          {/* <Grid item xs={6} sm={6} md={3}>
                        <Card className={classes.card} style={{ backgroundColor: '#dc3545' }}>
                            <CardContent>
                                <Typography variant='h4'>
                                    500
                                </Typography>
                                <Typography variant='h6' className={classes.content}>
                                    Fullfilled orders
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.cardAction}>
                                <Typography>More info</Typography>
                                <IconButton component={Link} to="/fulfilled/1">
                                    <ForwardIcon fontSize='small' />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid> */}
        </Grid>
        <Box mt={4}>
          <Typography variant="h6" className={classes.subHeading}>
            {" "}
            Today's orders
          </Typography>
          <TodayOrders todayOrders={todayOrders} />
        </Box>
      </Box>
    </Layout>
  );
};

export default DashBoard;
