import React from 'react'
import Layout from './Layout'
import { Box, Typography } from "@mui/material";

const AccessDenied = () => {
    return (
        <Layout>
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            width="100%"
            minHeight="80vh"
            >
                <Box 
                    width="100%" 
                    maxWidth="500px" 
                    backgroundColor="#fff"
                    textAlign="center"
                    padding="30px"
                >
                    <Typography component="h1" style={{fontSize: '50px', lineHeight: 1.3, fontWeight: '600', marginBottom: '16px'}}>Access Denied!</Typography>
                    <Typography style={{fontSize: '16px', lineHeight: 1.3,}}>You are not permitted to visit this page, please contact to the administrator</Typography>
                </Box>
            </Box>
        </Layout>
    )
}

export default AccessDenied
