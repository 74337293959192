import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Box,
  IconButton,
  Grid,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
    textTransform: "uppercase",
    wordBreak: "break-all",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "16px",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "210px",
    },
  },
}));

const TrackIdPopup = (props) => {
  const { id, openModel, handleClose, trackData } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>
              Tracking Details for Order #{id}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Box mb={2} width={500} className={classes.fullWidth}>
          <Grid container spacing={2} className={classes.margin}>
            <Grid item xs={5}>
              <Typography className={classes.heading}>Tracking Id</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.heading}>
                Tracking Method
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>Dispatch Date</Typography>
            </Grid>
          </Grid>
          <Divider />
          {trackData
            ? trackData.map((item) => {
                return (
                  <Grid
                    container
                    key={item.id}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item xs={5}>
                      <Typography className={classes.typography}>
                        {item.trackingid}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.typography}>
                        {item.ordertrackingmethod}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.typography}>
                        {moment(item.created_at).format("ll")}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TrackIdPopup;
