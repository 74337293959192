import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const PodsDataModal = (props) => {
  const {
    id,
    name,
    email,
    tel,
    product,
    message,
    store,
    openModel,
    handleClose,
  } = props;

  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="show-data-modal-container"
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className="show-data-modal-heading">
              Pods #{id}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className="show-data-modal">
        <Box>
          <Box display="flex" flexWrap="wrap">
            <Box className="show-data-modal-metadata-container">
              <Box display="flex" flexWrap="wrap" className="meta-data">
                <Typography className="meta-data-head">Name</Typography>
                <Typography className="meta-data-value">{name}</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" className="meta-data">
                <Typography className="meta-data-head">Email</Typography>
                <Typography className="meta-data-value">{email}</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" className="meta-data">
                <Typography className="meta-data-head">Phone</Typography>
                <Typography className="meta-data-value">{tel}</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" className="meta-data">
                <Typography className="meta-data-head">Product</Typography>
                <Typography className="meta-data-value">{product}</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" className="meta-data">
                <Typography className="meta-data-head">Store</Typography>
                <Typography className="meta-data-value">{store}</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" className="meta-data">
                <Typography className="meta-data-head">Message</Typography>
                <Typography className="meta-data-value">{message}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PodsDataModal;
