import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import {
  Typography,
  Button,
  Stack,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Autocomplete,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Container,
  Grid,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Layout from "../../components/Layout";
import {
  fetchAllCategoryForTool,
  getInventoryProductTypeById,
  updateProductTypeForTool,
  getProductsForTool,
  getSingleProduct,
} from "../../api";
import { width } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    marginTop: "1rem",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
    marginBottom: "1rem",
  },
  btn: {
    textTransform: "none",
    marginLeft: "10px",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  paper: {
    padding: "1rem",
  },
  content: {
    color: "#777",
    lineHeight: "1.6em",
    fontSize: "14px",
    marginTop: "2px",
  },
  comment: {
    backgroundColor: "#d7cad2",
    padding: "10px",
    position: "relative",
    lineHeight: "1.4",
    fontSize: "13px",
    color: "#3c434a",
  },
  commentDesign: {
    position: "absolute",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid #d7cad2",
    bottom: "-10px",
    left: "20px",
  },
  imgwidth: {
    width: "100%",
  },
}));

const UpdateProductType = () => {
  const classes = useStyles();
  const { producttypeId } = useParams();
  const [producttype, setProductType] = useState("");
  const [producttypecategory, setProductTypeCategory] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [productcatlist, setProductCatList] = useState(null);

  const userid = localStorage.getItem("auth_id");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [addedProduct, setAddedProduct] = useState({});
  const [currentproduct, setCurrentProduct] = useState();
  const [currentproductname, setCurrentProductName] = useState();
  const [productValError, setProductValError] = useState({
    helperText: "",
    error: false,
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      var data = new FormData();
      data.append("producttypeoftype", producttype);
      data.append("producttypecategory", producttypecategory);
      data.append("producttypemainproduct", addedProduct.id);
      console.log(addedProduct.id);
      if (document.getElementById("fileupload").files[0]) {
        data.append(
          "productartwork",
          document.getElementById("fileupload").files[0]
        );
      }
      const updateTool = await updateProductTypeForTool(producttypeId, data);
    } catch (error) {
      console.log("error form inventory", error);
    }
    setLoading(false);
    setLoaded(!loaded);
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name == "update-producttype") {
      setProductType(e.target.value);
    }
    if (e.target.name == "update-producttype-category") {
      setProductTypeCategory(e.target.value);
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const inventorydata = await getInventoryProductTypeById(producttypeId);
        if (inventorydata.status === 200) {
          const currentprod = await getSingleProduct(
            inventorydata.producttype.producttype_product
          );
          if (currentprod) {
            setCurrentProductName(currentprod.name);
          }
          setProductInfo(inventorydata.producttype);
          setProductType(inventorydata.producttype.producttypeof_type);
          setProductTypeCategory(
            inventorydata.producttype.producttype_category
          );
          setCurrentProduct(inventorydata.producttype.producttype_category);
        }
      } catch (error) {
        console.log("error form inventory", error);
      }
    };
    fetchProductData();
    const fetchproduct = async () => {
      var data;
      data = await getProductsForTool(value);
      setOptions(data);
    };
    fetchproduct();
  }, [producttypeId, loaded]);

  useEffect(() => {
    const fetchAllCategory = async () => {
      try {
        const inventorydata = await fetchAllCategoryForTool();
        console.log(typeof inventorydata);
        setProductCatList(inventorydata);
      } catch (error) {
        console.log("error form inventory", error);
      }
    };
    fetchAllCategory();
  }, [producttypeId]);

  return (
    <>
      <Layout>
        <Container>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "1.5em",
                my: 2,
              }}
            >
              Product type Id # {producttypeId} ({productInfo.producttype})
            </Typography>
            <Paper style={{ padding: 16 }}>
              <Box
                sx={{
                  width: "700px",
                  maxWidth: "100%",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="update-producttype-label">
                          Product Type
                        </InputLabel>
                        <Select
                          id="update-producttype"
                          value={producttype}
                          onChange={handleChange}
                          name="update-producttype"
                        >
                          <MenuItem>Select Product Type</MenuItem>
                          <MenuItem value="simple">Simple</MenuItem>
                          <MenuItem value="variable">Variable</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="update-producttype-category-label">
                          Product Type Category
                        </InputLabel>
                        <Select
                          id="update-producttype-category"
                          value={producttypecategory}
                          onChange={handleChange}
                          name="update-producttype-category"
                        >
                          <MenuItem>Select Product Type Category</MenuItem>
                          {productcatlist &&
                            productcatlist.map((item) => {
                              return (
                                <MenuItem
                                  key={item.term_id}
                                  value={item.term_id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {currentproductname
                          ? `Current Selected Product : ${currentproductname}`
                          : null}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Autocomplete
                          id="asynchronous-demo"
                          size="small"
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option) => {
                            return (
                              <Box component="li" {...props}>
                                {option?.name}
                              </Box>
                            );
                          }}
                          onInputChange={(event, newInputValue) => {
                            setValue(newInputValue);
                          }}
                          options={options}
                          onChange={(e, v) => {
                            setOptions(v ? [v, ...options] : options);
                            setValue(v);
                            setAddedProduct(v);
                          }}
                          // loading={loading}
                          sx={{ width: "100%", margin: "0" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              required
                              helperText={productValError.helperText}
                              error={productValError.error}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" component="label">
                        Upload File
                        <input type="file" hidden id="fileupload" />
                      </Button>
                    </Grid>
                    {productInfo.product_artwork ? (
                      <img
                        src={`https://printyocrm.printyo.net.au/${productInfo.product_artwork}`}
                        className={classes.imgwidth}
                      />
                    ) : null}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={loading}
                      >
                        {loading ? "Processing" : "Update"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Paper>
          </Stack>
        </Container>
      </Layout>
    </>
  );
};

export default UpdateProductType;
