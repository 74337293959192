import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getSingleOrderDetails } from "../../api";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider, makeStyles } from "@material-ui/core";
import TrackEvents from "../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "210px",
      padding: "16px !important",
    },
  },
}));

const PinPopupMessage = (props) => {
  const classes = useStyles();
  const { id, handleClose, open, res, setRes } = props;
  const [loading, setLoading] = useState(false);
  const [orderdata, setOrderData] = useState(false);

  useEffect(() => {
    const getPinnedOrderMessage = async () => {
      if (id) {
        try {
          setLoading(true);
          const data = await getSingleOrderDetails(id);
          setLoading(false);
          setOrderData(data);
          TrackEvents(`${id}`, {
            process: "Open Important Order Message",
          });
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    };
    getPinnedOrderMessage();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TypographyHeading>Important Order Message</TypographyHeading>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />

        <Box sx={{ textAlign: "center" }}>
          <TypographySubHeading>
            {loading ? "Please wait message is loading" : null}
            {!loading && orderdata.pinnedordermessage
              ? orderdata.pinnedordermessage
              : " Order is not marked as important"}
          </TypographySubHeading>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PinPopupMessage;
