import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { Stack, TextField, InputAdornment } from "@mui/material";
import {
  createPodCustomer,
  createPodCustomerCoupon,
  getAllCustomers,
  getAllPods,
  updatePodCustomer,
} from "../../api";
import LinearProgress from "@material-ui/core/LinearProgress";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Layout from "../../components/Layout";
import CircularProgress from "@mui/material/CircularProgress";
import PodsDataModal from "../../components/PodsDataModal";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell>Id</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Phone no.</TableCell>
        <TableCell>Product</TableCell>
        <TableCell>View</TableCell>
        <TableCell>Approve</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [pods, setPods] = useState([]);
  const [podsCount, setPodsCount] = useState(0);
  const [searchvalue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setPageLimit] = useState(10);
  const [rowData, setRowData] = useState({});
  const [openModel, setOpenModel] = React.useState(false);
  const [creating, setCreating] = useState(false);
  const [buttonText, setButtonText] = useState("Yes");
  const [processingRow, setProcessingRow] = useState(null);
  const [updatedRow, setUpdatedRow] = useState(false);
  const userid = localStorage.getItem("auth_id");

  const [rejecting, setRejecting] = useState(false);
  const [recjectText, setRejectText] = useState("No");

  useEffect(() => {
    const getCustomers = async () => {
      try {
        setLoading(true);
        const pods = await getAllPods(page + 1, limit);
        setPods(pods.pods);
        setLoading(false);
        setPodsCount(pods.totalcount);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getCustomers();
  }, [page, search, limit, updatedRow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
    setPage(0);
  };

  //Handle Search
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChangeRowsPerPage = (e) => {
    setPageLimit(e.target.value);
  };

  const handleModelOpen = useCallback((e, row) => {
    e.stopPropagation();
    setRowData(row);
    setOpenModel(true);
  }, []);

  const handleCloseModel = () => {
    setOpenModel(false);
    setRowData({});
  };

  const handleCustomer = async (data) => {
    setProcessingRow(data.id);
    setCreating(true);
    setButtonText("Creating Customer");
    const formdata = {
      email: data.email,
      first_name: data.name,
      billing: {
        first_name: data.name,
        email: data.email,
        phone: data.tel,
      },
      meta_data: [
        {
          key: "pod_customer",
          value: "yes",
        },
      ],
    };
    try {
      const updatecustomerdata = new FormData();

      updatecustomerdata.append("approved", 1);
      updatecustomerdata.append("userid", userid);

      const updatecustomer = await updatePodCustomer(
        updatecustomerdata,
        data.id
      );

      if (updatecustomer.status == 200) {
        const createCustomer = await createPodCustomer(formdata);
        if (createCustomer.status == 400) {
          if ((createCustomer.data.status = 400)) {
            alert("User already exits");
            setButtonText("Yes");
            setCreating(false);
            return;
          }
        } else if (createCustomer.status == 201) {
          if (createCustomer.data.id) {
            setButtonText("Creating Coupon");

            const couponFormData = {
              code: data.email,
              discount_type: "percent",
              amount: "10",
              individual_use: true,
              exclude_sale_items: true,
              minimum_amount: "100.00",
            };

            // Run createPodCustomerCoupon after createPodCustomer is successful
            const createCoupon = await createPodCustomerCoupon(couponFormData);
            if (createCoupon.status == 400) {
              if ((createCoupon.data.status = 400)) {
                alert("Coupon already exits");
                setButtonText("Yes");
                setCreating(false);
                return;
              }
            } else if (createCoupon.data.status == 201) {
              if (createCoupon) {
                // Additional code after creating the coupon, if needed
                setButtonText("Created");
                setCreating(false);
                setUpdatedRow(!updatedRow);
              }
            }
          }
        }
      }
    } catch (error) {
      // Handle errors here
      console.error("Error creating customer:", error);
      setButtonText("Yes");
      setCreating(false);
    }
  };

  const rejectCustomer = async (data) => {
    setProcessingRow(data.id);
    setRejecting(true);
    setRejectText("Rejecting Customer");
    try {
      const updatecustomerdata = new FormData();

      updatecustomerdata.append("approved", 2);
      updatecustomerdata.append("userid", userid);

      const updatecustomer = await updatePodCustomer(
        updatecustomerdata,
        data.id
      );

      if (updatecustomer.status == 200) {
        setRejecting(false);
        setRejectText("No");
        setUpdatedRow(!updatedRow);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error rejecting customer:", error);
      setRejectText("Yes");
      setRejecting(false);
    }
  };

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={`secBox`}
      >
        <Box display="flex" alignItems="center" mb={3}>
          <Typography className="heading">Pods</Typography>
        </Box>
        {/* <Stack direction="row" justifyContent="flex-end" mt={2}>
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="outlined-start-adornment"
              className={`searchinput`}
              sx={{
                m: 1,
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="Search Customer"
              onChange={handleSearch}
              value={searchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack> */}
        <Paper className={`paper`}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table
              className={`table custom-table`}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead rowCount={podsCount.length} />
              <TableBody>
                {pods.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell id={labelId} scope="row" className={`orderId`}>
                        {row.name}
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.tel}</TableCell>
                      <TableCell>{row.product}</TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleModelOpen(e, row)}>
                          <VisibilityOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell className="button-td">
                        {row.approved === "1" ? (
                          <Button variant="contained" className="approved-btn">
                            Approved
                          </Button>
                        ) : row.approved === "2" ? (
                          <Button variant="contained" className="rejected-btn">
                            Rejected
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              className="agree-btn"
                              onClick={() => {
                                handleCustomer(row);
                              }}
                            >
                              {creating && processingRow === row.id ? (
                                <CircularProgress />
                              ) : null}
                              {creating && processingRow === row.id
                                ? buttonText
                                : "Yes"}
                            </Button>
                            <Button
                              variant="contained"
                              className="reject-btn"
                              onClick={() => {
                                rejectCustomer(row);
                              }}
                            >
                              {rejecting && processingRow === row.id ? (
                                <CircularProgress />
                              ) : null}
                              {rejecting && processingRow === row.id
                                ? recjectText
                                : "No"}
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            component="div"
            count={podsCount}
            rowsPerPageOptions={[10, 25, 50, 100]}
            page={page}
            rowsPerPage={limit}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <PodsDataModal
          handleClose={handleCloseModel}
          openModel={openModel}
          setOpenModel={setOpenModel}
          {...rowData}
        />
      </Box>
    </Layout>
  );
}
