import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Box,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { deleteUser } from "../../api";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  modal: { 
    "& .MuiDialog-paper" : {
        [theme.breakpoints.down('xs')]: {
            margin: "16px",
            width: "100%",
        },
    },
  },
  fullWidth: {
      [theme.breakpoints.down('xs')]: {
        padding: "16px !important",
        width: "100% !important",
        minWidth: "210px",
      },
  },
}))

const ConfirmationPopup = (props) => {
  const classes = useStyles()
  const { id, handleClose, open, setRes} = props;
  const [loading, setLoading] = useState(false);

  const handleConfirmed = async () => {
    const data = new FormData();
    data.append("_method", "delete");
    setLoading(true);
    let result = await deleteUser(id, data);
    setLoading(false);
    setRes(result.success);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogContent dividers sx={{ width: 500 }}  className={classes.fullWidth}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TypographyHeading>Delete User</TypographyHeading>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <TypographySubHeading>
          Do you want to delete user {id}?
        </TypographySubHeading>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          variant="contained"
          size="small"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing" : "Confirmed"}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
