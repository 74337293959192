import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getPaymentDetails } from "../../../api";

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const PaymentDetails = ({ id }) => {
  const [payments, setPayment] = useState([]);
  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const data = await getPaymentDetails(id);
        if (data.data.status === 200) {
          setPayment(data.response);
          console.log("fetchPaymentDetails ", data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (id) {
      fetchPaymentDetails();
    }
  }, [id]);

  return (
    <Box sx={{ mt: 3 }}>
      {payments.length > 0 && (
        <TypographyHeading style={{ fontSize: "15px", marginTop: 4 }}>
          Payment Details
        </TypographyHeading>
      )}

      {payments.length > 0 &&
        payments.map((item, index) => {
          return (
            <Paper sx={{ my: 1, padding: "2rem 1rem" }}>
              <Stack direction="row" alignItems="center" mb={2}>
                <TypographyHeading>OrderId:</TypographyHeading>
                <CustomTypography sx={{ ml: 1 }}>
                  #{item.order_id}
                </CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Name:</TypographySubHeading>
                <CustomTypography>{item.name}</CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Email:</TypographySubHeading>
                <CustomTypography>{item.email}</CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Status:</TypographySubHeading>
                <CustomTypography>{item.payment_status}</CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Phone:</TypographySubHeading>
                <CustomTypography>{item.phone}</CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Payment for:</TypographySubHeading>
                <CustomTypography>
                  {item.payment_for ? item.payment_for : "N/A"}
                </CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Transaction Id:</TypographySubHeading>
                <CustomTypography>{item.transid}</CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Invoice Id:</TypographySubHeading>
                <CustomTypography>{item.invoiceid}</CustomTypography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TypographySubHeading>Created at:</TypographySubHeading>
                <CustomTypography>{item.created_at}</CustomTypography>
              </Stack>
            </Paper>
          );
        })}
    </Box>
  );
};

export default PaymentDetails;
