import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getUserUnreadNotification, markUserNotificationAsRead } from "../api";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  listRow: {
    padding: "0 0 8px 0 !important",
  },
  listBox: {
    cursor: "pointer",
    backgroundColor: "rgb(40, 167, 69)",
    color: "#fff",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    marginBottom: "16px",
    borderRadius: "4px",
    padding: "8px 16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  listBoxText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  listDate: {
    fontSize: "11px !important",
    color: "#999",
    marginTop: "8px ​!important",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
        width: "100%",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
}));

const Notifications = (props) => {
  const { handleClose, open, res } = props;
  const classes = useStyles();
  const [NotificationList, setNotificationList] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const notstate = 2;
  // const [notificationtype, setNotificationType] = useState("");
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setWaiting(true);
        const data = await getUserUnreadNotification();
        if (data.status === 200) {
          setNotificationList(data.notifications);
        }
        setWaiting(false);
      } catch (error) {
        console.log("error form notifications", error);
      }
    };
    fetchNotifications();
  }, [notstate]);
  // const ShowNotificationType = (type) => {
  //   if (type === "internalnotes") {
  //     setNotificationType("Internal Notes");
  //   } else if (type === "") {
  //     setNotificationType("Internal Notes");
  //   }
  // };
  // const handleNotificationClick = (notificationdata) => {
  //   const orderid = notificationdata.orderid;
  //   const host = window.location.host;
  //   if (notificationdata.notification_type === "internalnotes") {
  //     window.location.href = `${host}/orders/1/details?id=${orderid}&tab=1`;
  //   } else if (
  //     notificationdata.notification_type === "emailreply" &&
  //     notificationdata.notification_for === "activity"
  //   ) {
  //     window.location.href = `${host}/orders/1/details?id=${orderid}&tab=1`;
  //   } else if (
  //     notificationdata.notification_type === "emailreply" &&
  //     notificationdata.notification_for === "mockup"
  //   ) {
  //     window.location.href = `${host}/orders/1/details?id=${orderid}&tab=2`;
  //   }
  // };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Notification-popup"
      fullWidth
      maxWidth="md"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Notifications</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Box bgcolor="#fff" className={classes.box}>
          <Box>
            <List>
              {NotificationList.map((items, i) => (
                <ListItem
                  key={i}
                  style={{
                    display: "block",
                  }}
                  className={classes.listRow}
                  onClick={async () => {
                    const orderid = items.data.orderid;
                    const host =
                      window.location.protocol + "//" + window.location.host;
                    try {
                      setWaiting(true);
                      const markdata = await markUserNotificationAsRead(
                        items.id
                      );
                      if (markdata.status === 200) {
                        console.log(markdata);
                      }
                      setWaiting(false);
                    } catch (error) {
                      console.log(
                        "error form notifications mark as read",
                        error
                      );
                    }
                    if (items.data.notification_type === "internalnotes") {
                      window.location.href = `${host}/orders/1/details?id=${orderid}&tab=1`;
                    } else if (
                      items.data.notification_type === "emailreply" &&
                      items.data.notification_for === "activity"
                    ) {
                      window.location.href = `${host}/orders/1/details?id=${orderid}&tab=1`;
                    } else if (
                      items.data.notification_type === "emailreply" &&
                      items.data.notification_for === "mockup"
                    ) {
                      window.location.href = `${host}/orders/1/details?id=${orderid}&tab=2`;
                    }
                  }}
                >
                  <ListItemText className={classes.listBox}>
                    <span className={classes.listBoxText}>
                      You have new {items.data.notification_for} notification
                      for orderID <strong>{items.data.orderid} </strong>
                    </span>
                  </ListItemText>
                  <Typography className={classes.listDate}>
                    created at{" "}
                    {moment(items.data.created_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
          {waiting && <LinearProgress />}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Notifications;
