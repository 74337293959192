import React, { useCallback, useState } from "react";
import {
  DialogTitle,
  Dialog,
  IconButton,
  Stack,
  DialogContent,
  Autocomplete,
  TextField,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@material-ui/core/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { getProducts, getVariation } from "../../../api";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
        width: "100%",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "unset !important",
    },
  },
}));

// const TypographyHeading = styled(Typography)(({ theme }) => ({
//     fontSize: '14px',
//     lineHeight: '1.4em',
//     fontWeight: 'bold',
// }));
// const CustomTypography = styled(Typography)(({ theme }) => ({
//     fontSize: '13px',
//     lineHeight: '1.4em',
// }));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));

const AddProduct = (props) => {
  const classes = useStyles();
  const { openModal, setOpenModal, products, setProducts } = props;
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [qty, setQty] = useState("1");
  const [attributes, setAttributes] = useState({});
  const [searchType, setSearchType] = useState("sku");
  const [loadingproduct, setLoadingProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const fetchProducts = async () => {
      if (value && value.length >= 2) {
        var data;
        if (searchType === "sku") {
          setLoadingProduct(true);
          data = await getProducts("", value, "variable");
          if (data.length == 0) {
            data = await getProducts("", value, "simple");
          }
          setOptions(data);
        } else {
          setLoadingProduct(true);
          data = await getProducts(value, "", "variable");
          if (data.length == 0) {
            data = await getProducts(value, "", "simple");
          }
          setOptions(data);
        }
      }
      setLoadingProduct(false);
    };
    fetchProducts();
  }, [value, searchType]);

  React.useEffect(() => {
    const fetchCustomer = async () => {
      let products = await getProducts("", "", "variable");
      setOptions([...products]);
    };
    fetchCustomer();
  }, []);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleAddProducts = () => {
    setLoading(true);
    let total = 0;
    if (value && qty > 0) {
      if (value.type === "simple") {
        value.meta_data[0].value.forEach((item) => {
          if (
            parseFloat(qty) < parseFloat(item.max_qty) + 1 &&
            parseFloat(qty) > parseFloat(item.min_qty) - 1
          ) {
            total = total + item.price * qty;
            setProducts([
              ...products,
              { product: value, qty, attributes, total, price: item.price },
            ]);
            setOpenModal(false);
            setValue({});
            setAttributes({});
            setQty("1");
          } else {
            total = qty * value.price;
            setProducts([
              ...products,
              { product: value, qty, attributes, total, price: value.price },
            ]);
            setOpenModal(false);
            setValue({});
            setAttributes({});
            setQty("1");
          }
        });
      } else if (value.type === "variable") {
        const getPrice = async () => {
          let res = await getVariation(value.id, value.variations[0]);
          if (res) {
            res.meta_data[0].value.forEach((item) => {
              if (
                parseFloat(qty) < parseFloat(item.max_qty) + 1 &&
                parseFloat(qty) > parseFloat(item.min_qty) - 1
              ) {
                total = total + item.price * qty;
                setProducts([
                  ...products,
                  { product: value, qty, attributes, total, price: item.price },
                ]);
                setOpenModal(false);
                setAttributes({});
                setValue({});
                setQty("1");
              } else {
                total = qty * value.price;
                setProducts([
                  ...products,
                  {
                    product: value,
                    qty,
                    attributes,
                    total,
                    price: value.price,
                  },
                ]);
                setOpenModal(false);
                setAttributes({});
                setValue({});
                setQty("1");
              }
            });
          }
        };
        getPrice();
      }
    }
    setLoading(false);
  };

  const handleChange = useCallback(
    (e) => {
      setAttributes({ ...attributes, [e.target.name]: e.target.value });
    },
    [attributes]
  );

  const handlesearchType = (e) => {
    setSearchType(e.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={openModal} className={classes.modal}>
      {loadingproduct && <LinearProgress />}
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Add product</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Box minWidth={400} className={classes.fullWidth}>
          <Stack justifyContent="space-between" direction="row">
            <TypographySubHeading>Product name</TypographySubHeading>
            <TypographySubHeading sx={{ mr: 2 }}>Quantity</TypographySubHeading>
          </Stack>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Search on</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchType}
              label="Template"
              size="small"
              onChange={handlesearchType}
            >
              <MenuItem value="product">Product</MenuItem>
              <MenuItem value="sku">Sku</MenuItem>
            </Select>
          </FormControl>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            my={2}
          >
            <Autocomplete
              id="asynchronous-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              size="small"
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => {
                return (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                );
              }}
              filterOptions={(x) => x}
              onInputChange={(event, newInputValue) => {
                setValue(newInputValue);
              }}
              options={options}
              onChange={(e, v) => {
                setOptions(v ? [v, ...options] : options);
                setValue(v);
              }}
              // loading={loading}

              sx={{ width: "70%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Enter ${searchType}`}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
            <TextField
              type="number"
              sx={{ width: "20%" }}
              size="small"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
            />
          </Stack>
          {value && value.type === "variable" && (
            <>
              {value.attributes.map((item) => {
                return (
                  <FormControl component="fieldset" key={item.name}>
                    <FormLabel
                      component="legend"
                      sx={{
                        color: "#3c434a",
                        fontSize: "13px",
                        lineHeight: "1.4em",
                        fontWeight: "bold",
                      }}
                    >
                      {item.name}
                    </FormLabel>
                    <RadioGroup
                      aria-label={item.name}
                      defaultValue={item.name}
                      name={item.name}
                      onChange={handleChange}
                      row
                    >
                      {item.options.map((option, i) => {
                        return (
                          <FormControlLabel
                            value={option}
                            control={<Radio fontSize="small" />}
                            label={option}
                            key={option}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                );
              })}
            </>
          )}
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleAddProducts}
              disabled={loading}
              >
                {loading ? "Adding" : "Add"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddProduct;
