import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
} from "@material-ui/core";
import { Typography, Stack, Paper, LinearProgress } from "@mui/material";
import { fetchLogs } from "../../api";
import moment from "moment";
import Layout from "../../components/Layout";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Log</TableCell>
        <TableCell>Created At</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    marginTop: "1rem",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
    marginBottom: "1rem",
  },
  btn: {
    textTransform: "none",
    marginLeft: "10px",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  paper: {
    padding: "1rem",
  },
  content: {
    color: "#777",
    lineHeight: "1.6em",
    fontSize: "14px",
    marginTop: "2px",
  },
  comment: {
    backgroundColor: "#d7cad2",
    padding: "10px",
    position: "relative",
    lineHeight: "1.4",
    fontSize: "13px",
    color: "#3c434a",
  },
  commentDesign: {
    position: "absolute",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid #d7cad2",
    bottom: "-10px",
    left: "20px",
  },
}));

const LogsByOrderDetails = () => {
  const classes = useStyles();
  const { orderid } = useParams();
  const [loginfo, setLogInfo] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOrderLog = async () => {
      try {
        const orderlog = await fetchLogs(orderid);
        if (orderlog.status === 200) {
          setLogInfo(orderlog.logs);
        }
      } catch (error) {
        console.log("error form logs", error);
      }
    };
    fetchOrderLog();
  }, [orderid]);

  return (
    <Layout>
      <Container>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              my: 2,
            }}
          >
            OrderId #{orderid}
          </Typography>
        </Stack>
        <Paper>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              <TableBody>
                {loginfo?.map((logs, index) => {
                  let date = moment(logs.created_at).format("ll H:m");
                  return (
                    <>
                      <TableRow hover tabIndex={-1} key={logs.id}>
                        <TableCell>{logs.user_name}</TableCell>
                        <TableCell>{logs.user_email}</TableCell>
                        <TableCell>{logs.logs_message}</TableCell>
                        <TableCell>{date}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
        </Paper>
      </Container>
    </Layout>
  );
};

export default LogsByOrderDetails;
