import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getOrderDetails, getParcelItemId, getParcel } from "../api";
import { TypographySubHeading } from "../styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import LoadAusPostLabelForPrint from "./LoadAusPostLabelForPrint";

const useStyles = makeStyles((theme) => ({
  dialogBoxPadding: {
    padding: "10px 25px !important",
  },
}));

const LoadAusPostLabel = (props) => {
  const { handleCloseLabel, openLabel, orderid } = props;
  const classes = useStyles();
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [formFields, setFormFields] = useState([
    { length: "", width: "", height: "", weight: "" },
  ]);
  const [openLabelForPrint, setOpenLabelForPrint] = React.useState(false);
  const [printData, setPrintData] = React.useState(null);
  const [auspostaccount, setAusPostAccount] = useState("0007708009");

  // const handleChangeLabelForAusPost = (event) => {
  //   if (event.target.name == "length") {
  //     setLength(event.target.value);
  //   }
  //   if (event.target.name == "width") {
  //     setWidth(event.target.value);
  //   }
  //   if (event.target.name == "height") {
  //     setHeight(event.target.value);
  //   }
  //   if (event.target.name == "weight") {
  //     setWeight(event.target.value);
  //   }
  // };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleAusPostAccount = (event) => {
    setAusPostAccount(event.target.value);
  };

  const addFields = () => {
    let object = { length: "", width: "", height: "", weight: "" };
    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const handleConfirmedLabel = async (event) => {
    setLoadingLabel(true);
    event.preventDefault();
    let parceldata = {
      orderid: orderid,
      item: formFields,
      account: auspostaccount,
    };
    console.log(parceldata);
    const getParcelProductId = await getParcel(parceldata);
    if (getParcelProductId) {
      if (getParcelProductId.success) {
        setPrintData(getParcelProductId);
        const price = getParcelProductId.price.total_cost;
        // setOpenLabelForPrint(true);
        alert(
          "Label Generated Successfully. It will cost around $" +
            price.toFixed(2)
        );
        setLoadingLabel(false);
      } else {
        if (getParcelProductId.labelerror) {
          alert(getParcelProductId.labelerror.errors[0].message);
        } else {
          alert("Something went wrong while adding the labels");
        }
        setLoadingLabel(false);
      }
    } else {
      alert(
        "Something went on Printyo Server. Please check all data fields are entered properly"
      );
      setLoadingLabel(false);
    }
    handleCloseLabel();
    // console.log(getParcelProductId);
  };

  const handleCloseLabelForPrint = (value) => {
    setOpenLabelForPrint(false);
  };
  return (
    <>
      <LoadAusPostLabelForPrint
        openLabel={openLabelForPrint}
        handleCloseLabel={handleCloseLabelForPrint}
        printData={printData}
        orderid={orderid}
      />
      <Dialog
        open={openLabel}
        onClose={handleCloseLabel}
        aria-labelledby="form-dialog-title"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={handleCloseLabel}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <form onSubmit={handleConfirmedLabel}>
          <DialogContent className={classes.dialogBoxPadding}>
            <Typography sx={{ mb: 2 }}>
              Enter the details to generate e-parcel Label for orderid {orderid}
            </Typography>
            {formFields.map((form, index) => {
              return (
                <>
                  {/* <Grid xs={12}>
                    <InputLabel id="demo-simple-select-label">
                      Select Aus Post Account
                    </InputLabel>
                    <Select
                      labelId="simple-select-fulllocation"
                      id="simple-select-fulllocation"
                      value={auspostaccount}
                      label="Select AusPost Account"
                      onChange={handleAusPostAccount}
                    >
                      <MenuItem value={"0007708009"}>0007708009</MenuItem>
                      <MenuItem value={"0007654171"}>0007654171</MenuItem>
                    </Select>
                  </Grid> */}
                  <div key={index}>
                    <Grid container spacing={2}>
                      {index > 0 ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : null}
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-label">
                          Length
                        </InputLabel>
                        <TextField
                          type="text"
                          value={form.length}
                          onChange={(event) => handleFormChange(event, index)}
                          rows={1}
                          size="small"
                          name="length"
                          placeholder="Length"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-label">
                          Width
                        </InputLabel>
                        <TextField
                          type="text"
                          value={form.width}
                          onChange={(event) => handleFormChange(event, index)}
                          rows={2}
                          size="small"
                          name="width"
                          placeholder="Width"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-label">
                          Height
                        </InputLabel>
                        <TextField
                          type="text"
                          value={form.height}
                          onChange={(event) => handleFormChange(event, index)}
                          rows={2}
                          size="small"
                          name="height"
                          placeholder="Height"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-label">
                          Weight
                        </InputLabel>
                        <TextField
                          type="text"
                          value={form.weight}
                          onChange={(event) => handleFormChange(event, index)}
                          rows={2}
                          size="small"
                          name="weight"
                          placeholder="Weight"
                        />
                      </Grid>
                      {index > 0 ? (
                        <Button
                          type="button"
                          onClick={() => removeFields(index)}
                          size="small"
                          color="error"
                          variant="contained"
                          sx={{ mb: 2 }}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Grid>
                  </div>
                </>
              );
            })}
            <Button
              type="button"
              onClick={addFields}
              size="small"
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Add More Items Details..
            </Button>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              size="small"
              color="primary"
              disabled={loadingLabel}
            >
              {loadingLabel ? "Processing" : "Confirm"}
            </Button>
            <Button
              onClick={handleCloseLabel}
              variant="outlined"
              size="small"
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default LoadAusPostLabel;
