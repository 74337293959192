import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "350px",
    },
  },
}));

const OrderInfo = (props) => {
  const { id, openModel, handleClose, artData } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Order #{id}</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        {artData && artData[0] ? (
          artData.map((items, index) => {
            return (
              <Box mb={2} key={index} width={400} className={classes.fullWidth}>
                <Box
                  display="flex"
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                >
                  <Typography
                    className={classes.heading}
                    style={{ marginRight: "10px" }}
                  >
                    Artwork Type:
                  </Typography>
                  <Typography className={classes.typography}>
                    {items ? items.design_work_details.design_style : null}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  my={2}
                  justifyContent="space-between"
                >
                  <Typography
                    className={classes.heading}
                    style={{ marginRight: "10px" }}
                  >
                    Product Name:
                  </Typography>
                  <Typography className={classes.typography}>
                    {items ? items.design_work_details_prodname : null}
                  </Typography>
                </Box>
                {artData && items.design_work_details.additional_information ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                  >
                    <Typography
                      className={classes.heading}
                      style={{ marginRight: "10px" }}
                    >
                      Additional Information:
                    </Typography>
                    <Typography className={classes.typography}>
                      {artData[0].design_work_details.additional_information}
                    </Typography>
                  </Box>
                ) : null}
                {items && items.design_work_image
                  ? items.design_work_image.map((item, index) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          key={item.id}
                          my={2}
                          justifyContent="space-between"
                        >
                          <Typography
                            className={classes.heading}
                            style={{ marginRight: "10px" }}
                          >
                            Artwork Image:
                          </Typography>
                          <a
                            href={item.image_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography className={classes.typography}>
                              View Image-{index}
                            </Typography>
                          </a>
                        </Box>
                      );
                    })
                  : null}
                <Divider />
              </Box>
            );
          })
        ) : (
          <Box pl={3} pr={3} pb={2} width={400} className={classes.fullWidth}>
            <Typography
              className={classes.heading}
              style={{ marginRight: "10px" }}
            >
              {artData.message}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderInfo;
