import React from "react";
import { Route, Switch } from "react-router-dom";
import Order from "./app/order";
import Inventory from "./app/inventory";
import DashBoard from "./app/dashBoard";
import Users from "./app/users";
import Settings from "./app/settings";
import AdvSettings from "./app/advSettings";
import Print from "./app/print";
import Fulfilled from "./app/fulfilled";
import PendingOrder from "./app/pendingOrder";
import ShippingPrice from "./app/shippingprice";
import importantOrder from "./app/importantOrder";
import Leads from "./app/leads";
import Role from "./app/role";
import ViewUserRole from "./app/role/ViewUserRole";
import ViewOrder from "./app/viewOrder";
import AddOrder from "./app/order/AddOrder";
import Product from "./app/product";
import ProductCategory from "./app/inventory/ProductCategory";
import UpdateProductType from "./app/inventory/UpdateProductType";
import Analytics from "./app/analytics";
import LoginPage from "./app/auth/login";
import Enquiry from "./app/enquiryComponent";
import AddEnquiry from "./app/enquiryComponent/AddEnquiry";
import ViewEnquiry from "./app/viewEnquiry";
import Profile from "./app/profile";
import AdminPrivateRoutes from "./AdminPrivateRoute";
import AccessDenied from "./components/AccessDenied";
import Notifications from "./components/Notifications";
import AusPost from "./app/auspost";
import AusPostLabel from "./app/auspostlabel";
import AusPostGenerateOrder from "./app/auspostgeneratedorder";
import Logs from "./app/logs";
import LogsByOrderDetails from "./app/logs/LogsByOrderDetails";
import { ProtectedRoute } from "./ProtectedRoute";
import ManifestByMonth from "./app/auspostmonthlyorder";
import Customer from "./app/customers";
import Pods from "./app/pod";

const Routes = () => {
  const roleid = parseInt(localStorage.getItem("auth_role"));
  console.log(typeof roleid);

  return (
    <Switch>
      <Route path="/" exact component={DashBoard} />
      <Route path="/login" exact component={LoginPage}></Route>
      <ProtectedRoute
        path="/pending-order/:pending"
        exact
        component={PendingOrder}
        roles={"pending-order"}
      />
      <ProtectedRoute
        path="/orders/:order"
        exact
        component={Order}
        roles={"orders"}
      />
      <ProtectedRoute
        path="/add-order"
        exact
        component={AddOrder}
        roles={"orders"}
      />
      <Route
        path="/product/:product"
        exact
        component={Product}
        roles={"product"}
      />
      <Route
        path="/analytics"
        exact
        component={Analytics}
        roles={"analytics"}
      />
      <ProtectedRoute
        path="/shipping-price/:pricepage"
        exact
        component={ShippingPrice}
        roles={"orders"}
      />
      <ProtectedRoute
        path="/enquiry/:enquiry"
        exact
        component={Enquiry}
        roles={"enquiry"}
      />
      <ProtectedRoute
        path="/enquiry/:orderId/details"
        exact
        component={ViewEnquiry}
        roles={"enquiry"}
      />
      <ProtectedRoute
        path="/add-enquiry"
        exact
        component={AddEnquiry}
        roles={"enquiry"}
      />
      <ProtectedRoute
        path="/orders/:orderId/details"
        exact
        component={ViewOrder}
        roles={"orders"}
      />
      <ProtectedRoute
        path="/pending-order/:orderId/details"
        exact
        component={ViewOrder}
        roles={"pending-order"}
      />
      <ProtectedRoute
        path="/important-order/:important"
        exact
        component={importantOrder}
        roles={"important-order"}
      />
      <ProtectedRoute
        path="/important-order/:orderId/details"
        exact
        component={ViewOrder}
        roles={"important-order"}
      />
      {/* <Route path="/order/:orderId/details" exact component={ViewOrder} /> */}
      {/* <Route path="/order/:printId/details/:print" exact component={ViewOrder} /> */}
      {/* <Route path="/order/:fulfilledId/details/:fulfilled" exact  component={ViewOrder}/> */}
      <ProtectedRoute
        path="/fulfilled/:fulfilled/details"
        exact
        component={ViewOrder}
        roles={"fulfilled"}
      />
      <ProtectedRoute
        path="/print/:orderId/details/:print"
        exact
        component={ViewOrder}
        roles={"print"}
      />
      <ProtectedRoute path="/leads" exact component={Leads} roles={"leads"} />
      <ProtectedRoute
        path="/print/:print"
        exact
        component={Print}
        roles={"print"}
      />
      <ProtectedRoute
        path="/fulfilled/:fulfilled"
        exact
        component={Fulfilled}
        roles={"fulfilled"}
      />
      <ProtectedRoute
        path="/inventory/:inventory"
        exact
        component={Inventory}
        roles={"inventory"}
      />
      <ProtectedRoute
        path="/inventory/:producttypeId/details/:producttypePageId"
        exact
        component={ProductCategory}
        roles={"inventory"}
      />
      <ProtectedRoute
        path="/inventory/:producttypeId/update-producttype"
        exact
        component={UpdateProductType}
        roles={"inventory"}
      />
      <ProtectedRoute
        path="/notifications"
        exact
        component={Notifications}
        roles={"notifications"}
      />
      <ProtectedRoute path="/pods" exact component={Pods} roles={"print"} />
      <ProtectedRoute
        path="/customers"
        exact
        component={Customer}
        roles={"print"}
      />
      <Route path="/profile" exact component={Profile} />
      <ProtectedRoute
        path="/settings"
        exact
        component={Settings}
        roles={"settings"}
      />
      <ProtectedRoute
        path="/advanced-settings"
        exact
        component={AdvSettings}
        roles={"advanced-settings"}
      />
      <ProtectedRoute path="/users" exact component={Users} roles={"users"} />
      <ProtectedRoute
        path="/auspost"
        exact
        component={AusPost}
        roles={"users"}
      />
      <ProtectedRoute
        path="/auspost-label"
        exact
        component={AusPostLabel}
        roles={"users"}
      />
      <ProtectedRoute
        path="/generatedorder-auspost"
        exact
        component={AusPostGenerateOrder}
        roles={"users"}
      />
      <ProtectedRoute
        path="/monthly-manifest"
        exact
        component={ManifestByMonth}
        roles={"user-roles"}
      />
      <ProtectedRoute
        path="/user-roles"
        exact
        component={Role}
        roles={"user-roles"}
      />
      <ProtectedRoute
        path="/user-roles/:userrolesId/details"
        exact
        component={ViewUserRole}
        roles={"user-roles"}
      />
      <ProtectedRoute
        path="/logs"
        exact
        component={Logs}
        roles={"user-roles"}
      />
      <ProtectedRoute
        path="/logs/:orderid"
        exact
        component={LogsByOrderDetails}
        roles={"user-roles"}
      />
      {roleid === 1 ? <AdminPrivateRoutes /> : <AccessDenied />}
    </Switch>
  );
};

export default Routes;
