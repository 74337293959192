import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import OrderInfo from "../../components/OrderInfo";
import { getOrders, getOrderImg, getCustomerNotes } from "../../api";
import moment from "moment";
import ArtworkDetail from "../../components/ArtworkDetails";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import OrderNotes from "../../components/OrderNotes";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import OrderStatus from "../../components/utils/OrderStatus";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        <TableCell>Date</TableCell>
        <TableCell> Order </TableCell>
        <TableCell>Order details</TableCell>
        <TableCell>Artwork details</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Notes</TableCell>
        {/* <TableCell>View</TableCell> */}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 900,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    color: "#c6e1c6",
  },
  select: {
    color: "#fff",
  },
}));

const TodayTable = ({ todayOrders }) => {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [openModel, setOpenModel] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [rowData, setRowData] = useState({});
  const [artData, setArtData] = useState([]);
  const [artLoading, setArtLoading] = useState(false);
  const [id, setId] = useState(null);
  const [artDetailModal, setArtDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);

  useEffect(() => {
    const getOrderList = async () => {
      if (todayOrders > 0) {
        try {
          setLoading(true);
          const data = await getOrders(page + 1, todayOrders);
          setOrders(data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    };
    getOrderList();
  }, [page, todayOrders]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseModel = useCallback(() => {
    setOpenModel(false);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleModelOpen = useCallback((e, row) => {
    e.stopPropagation();
    setRowData(row);
    setOpenModel(true);
  }, []);

  const handleArtWork = useCallback((e, row) => {
    e.stopPropagation();
    setId(row.id);
    const getArtworkdetails = async () => {
      if (row.id) {
        try {
          setArtLoading(true);
          const data = await getOrderImg(row.id);
          setArtData(data);
          setArtLoading(false);
          setArtDetailModal(true);
        } catch (error) {
          setArtLoading(false);
          console.log(error);
        }
      }
    };
    getArtworkdetails();
  }, []);

  const handleNotesOpen = useCallback((e, id) => {
    e.stopPropagation();
    setId(id);
    const fetchNotes = async () => {
      if (id) {
        try {
          setNotesLoading(true);
          const data = await getCustomerNotes(id);
          setNotes(data);
          setNotesLoading(false);
          setOpenNotes(true);
        } catch (error) {
          setNotesLoading(false);
          console.log(error);
        }
      }
    };
    fetchNotes();
  }, []);

  const handleNotesClose = useCallback((e, row) => {
    setId(null);
    setOpenNotes(false);
  }, []);

  const handleCloseArtModel = useCallback(() => {
    setArtDetailModal(false);
    setArtData([]);
    setId(null);
  }, []);

  const handleOrderDetails = useCallback(
    (e, id) => {
      e.stopPropagation();
      history.push({
        pathname: `/orders/1/details`,
        search: `?id=${id}&tab=0`,
      });
    },
    [history]
  );

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={orders.length}
            />
            <TableBody>
              {orders.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                let date = moment(row.date_created).format("ll");
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    key={row.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={classes.orderId}
                      onClick={(e) => handleOrderDetails(e, row.id)}
                    >
                      {`#${row.id} ${row.billing.first_name} ${row.billing.last_name}`}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={(e) => handleModelOpen(e, row)}>
                        <VisibilityOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={(e) => handleArtWork(e, row)}>
                        {artLoading && id === row.id ? (
                          <CircularProgress
                            className={classes.loading}
                            size={20}
                            thickness={4}
                          />
                        ) : (
                          <FaceOutlinedIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <OrderStatus row={row} />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={(e) => handleNotesOpen(e, row.id)}>
                        {notesLoading && id === row.id ? (
                          <CircularProgress
                            className={classes.loading}
                            size={20}
                            thickness={4}
                          />
                        ) : (
                          <ChatOutlinedIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {loading && <LinearProgress />}
        </TableContainer>
        <TablePagination
          component="div"
          count={todayOrders}
          labelRowsPerPage=""
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <OrderInfo
        handleClose={handleCloseModel}
        openModel={openModel}
        setOpenModel={setOpenModel}
        {...rowData}
      />
      <ArtworkDetail
        id={id}
        handleClose={handleCloseArtModel}
        openModel={artDetailModal}
        setOpenModel={setOpenModel}
        artData={artData}
      />
      <OrderNotes
        handleClose={handleNotesClose}
        handleOpen={handleNotesOpen}
        notes={notes}
        open={openNotes}
        id={id}
      />
    </>
  );
};

export default TodayTable;
