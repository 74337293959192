import React from "react";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Field } from "formik";

const Billing = ({ errors, setFieldValue }) => {
  const handleBillingDetailsChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };
  return (
    <>
      <Stack
        spacing={3}
        sx={{
          "& .MuiFormControl-root": {
            width: "100%",
            "& label": {
              fontSize: "14px",
            },
            "& input": {
              fontSize: "14px",
              color: "#6B7280",
            },
          },
        }}
      >
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="billing_first_name"
            size="small"
            as={TextField}
            error={errors.billing_first_name}
            helperText={errors.billing_first_name}
            variant="outlined"
            placeholder="First Name"
            label="First Name"
            onChange={handleBillingDetailsChange}
          />
          <Field
            name="billing_last_name"
            size="small"
            as={TextField}
            error={errors.billing_last_name}
            helperText={errors.billing_last_name}
            variant="outlined"
            placeholder="Last Name"
            label="Last Name"
            onChange={handleBillingDetailsChange}
          />
        </Stack>
        <Field
          name="billing_company"
          size="small"
          as={TextField}
          error={errors.billing_company}
          helperText={errors.billing_company}
          variant="outlined"
          placeholder="Company name"
          label="Company name"
          onChange={handleBillingDetailsChange}
        />
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="billing_email"
            size="small"
            as={TextField}
            error={errors.billing_email}
            helperText={errors.billing_email}
            variant="outlined"
            placeholder="Email address"
            label="Email address"
            onChange={handleBillingDetailsChange}
          />
          <Field
            name="billing_phone"
            size="small"
            as={TextField}
            error={errors.billing_phone}
            helperText={errors.billing_phone}
            variant="outlined"
            placeholder="Phone"
            label="Phone"
            onChange={handleBillingDetailsChange}
          />
        </Stack>
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="billing_address_1"
            size="small"
            as={TextField}
            error={errors.billing_address_1}
            helperText={errors.billing_address_1}
            variant="outlined"
            placeholder="Address line 1"
            label="Address line 1"
            onChange={handleBillingDetailsChange}
          />
          <Field
            name="billing_address_2"
            size="small"
            as={TextField}
            error={errors.billing_address_2}
            helperText={errors.billing_address_2}
            variant="outlined"
            placeholder="Address line 2"
            label="Address line 2"
            onChange={handleBillingDetailsChange}
          />
        </Stack>
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="billing_city"
            size="small"
            as={TextField}
            error={errors.billing_city}
            helperText={errors.billing_city}
            variant="outlined"
            placeholder="City"
            label="City"
            onChange={handleBillingDetailsChange}
          />
          <Field
            name="billing_state"
            size="small"
            as={TextField}
            error={errors.billing_state}
            helperText={errors.billing_state}
            variant="outlined"
            placeholder="State"
            label="State"
            onChange={handleBillingDetailsChange}
          />
        </Stack>
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="billing_country"
            size="small"
            as={TextField}
            error={errors.billing_country}
            helperText={errors.billing_country}
            variant="outlined"
            placeholder="Country"
            label="Country"
            onChange={handleBillingDetailsChange}
          />
          <Field
            name="billing_postcode"
            size="small"
            as={TextField}
            error={errors.billing_postcode}
            helperText={errors.billing_postcode}
            variant="outlined"
            placeholder="Postcode / ZIP"
            label="Postcode / ZIP"
            onChange={handleBillingDetailsChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Billing;
