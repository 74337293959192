import React from "react";
import { Box, Typography } from "@material-ui/core";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";

const Offline = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      backgroundColor="#fff"
    >
      <SignalWifiOffIcon sx={{ fontSize: 100 }} />
      <Typography variant="h5" align="center">
        You are not connected to internet
      </Typography>
      <Typography variant="h6" align="center">
        Please check your internet connection and try again
      </Typography>
    </Box>
  );
};

export default Offline;
