import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { Stack, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  getApprovedMockup,
  getCustomerTabNotes,
  getDispatchOrder,
  trackingOrder,
  generateOrder,
} from "../../api";
import OrderInfo from "../../components/OrderInfo";
import ArtworkDetail from "./ArtWorkPopup";
import OrderNotes from "../../components/OrderNotes";
import Layout from "../../components/Layout";
import TrackIdPopup from "./TrackIdPop";
import OrderStatusForFullfilled from "../../components/utils/OrderStatusForFullfilled";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

function EnhancedTableHead(props) {
  // const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell> Select </TableCell>
        <TableCell> Order </TableCell>
        <TableCell>Order details</TableCell>
        <TableCell>Approved mockup</TableCell>
        <TableCell>Tracking Id</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Notes</TableCell>
        {/* <TableCell>View</TableCell> */}
        <TableCell>Dispatch date</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
  },
  orderbyheading: {
    fontSize: "18px",
    lineHeight: 1.3,
  },

  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "10px 0 0",
    },
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  editIcon: {
    height: "15px !important",
    marginLeft: "7px",
    cursor: "pointer",
  },
  showgreen: {
    backgroundColor: "#a2f8a2",
    color: "#ffffff",
  },
  orderbybox: {
    backgroundColor: "#fff",
    padding: "1rem",
    marginTop: "1rem",
    borderRadius: "4px",
  },
  orderbyButton: {
    display: "inline-block",
    padding: "9px 10px",
    minWidth: "100px",
  },
  secBox: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  secTopBtns: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 0 !important",
    },
  },
  secTopBar: {
    flexWrap: "wrap",
  },
  searchInputBox: {
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  printOrdersBtn: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
    "& .MuiButton-root": {
      [theme.breakpoints.down("xs")]: {
        margin: "0 !important",
      },
    },
  },
}));

const DateComponent = ({ date }) => {
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <div>{date}</div>
      </Box>
    </>
  );
};

export default function EnhancedTable() {
  const currentPageId = parseInt(getTheCurrentUrl());
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(currentPageId);
  const [openModel, setOpenModel] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [rowData, setRowData] = useState({});
  const [artData, setArtData] = useState([]);
  const [artLoading, setArtLoading] = useState(false);
  const [id, setId] = useState(null);
  const [artDetailModal, setArtDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [orderbyformat, setOrderByFormat] = useState("DESC");
  const currentRowsPerPage = localStorage.getItem("fulfilledRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    currentRowsPerPage ? currentRowsPerPage : 10
  );
  const [searchvalue, setSearchValue] = React.useState("");
  localStorage.setItem("FulfilledPage", currentPageId + 1);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const handleCloseModel = () => {
    setOpenModel(false);
    setRowData({});
  };

  const handleSelectAllClick = (event) => {
    const value = event.target.value;
    if (selected.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selected.filter((s) => s !== value);
      setSelected(newSelected);
    } else {
      // if value not present
      setSelected([...selected, value]);
    }
  };

  const handleClick = useCallback((event, name) => {
    console.log("table clicked");
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/fulfilled/${newPage + 1}`,
    });
  };

  const handleOrderDetails = useCallback(
    (e, id) => {
      const curl = window.location.pathname;
      const cpageId = curl.split("/")[2];
      e.stopPropagation();
      window.open(`/fulfilled/${cpageId}/details?id=${id}&tab=0`, "_blank");
      // history.push({
      //   pathname: `/fulfilled/${cpageId}/details`,
      //   search: `?id=${id}&tab=0`
      // });
    },
    [history]
  );

  const handleModelOpen = useCallback((e, row) => {
    e.stopPropagation();
    setRowData(row);
    setOpenModel(true);
  }, []);

  const handleArtWork = useCallback((e, row) => {
    e.stopPropagation();
    setId(row.id);
    const getArtworkdetails = async () => {
      if (row.id) {
        try {
          setArtLoading(true);
          const data = await getApprovedMockup(row.id);
          if (data.status === 200) {
            setArtData(data.approvedmockups);
          }
          setArtLoading(false);
          setArtDetailModal(true);
        } catch (error) {
          setArtLoading(false);
          console.log(error);
        }
      }
    };
    getArtworkdetails();
  }, []);

  const handleCloseArtModel = useCallback(() => {
    setArtDetailModal(false);
    setArtData([]);
    setId(null);
  }, []);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setSearchValue(e.target.value);
  };

  const handleNotesOpen = useCallback((e, id) => {
    e.stopPropagation();
    setId(id);
    const fetchNotes = async () => {
      if (id) {
        try {
          setNotesLoading(true);
          const data = await getCustomerTabNotes(id);
          setNotes(data.per_product_information);
          setNotesLoading(false);
          setOpenNotes(true);
        } catch (error) {
          setNotesLoading(false);
          console.log(error);
        }
      }
    };
    fetchNotes();
  }, []);

  const handleNotesClose = useCallback((e, row) => {
    setId(null);
    setOpenNotes(false);
  }, []);

  const handleAscDesc = () => {
    if (orderbyformat === "DESC") {
      setOrderByFormat("ASC");
    } else {
      setOrderByFormat("DESC");
    }
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("fulfilledRowsPerPage");
    } else {
      localStorage.setItem("fulfilledRowsPerPage", e.target.value);
    }
  };
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const data = await getDispatchOrder(
          page + 1,
          rowsPerPage,
          search,
          orderbyformat
        );
        setOrders(data.responseData.response);
        setCount(data.responseData.order_count.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchOrder();
  }, [page, search, rowsPerPage, orderbyformat]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Tracking id
  const [trackData, setTrackData] = useState([]);
  const [trackLoading, setTrackLoading] = useState(false);
  const [trackModal, setTrackModal] = useState(false);

  const handleTrackOrder = useCallback((e, row) => {
    e.stopPropagation();
    setId(row.id);
    const getTrackdetails = async () => {
      if (row.id) {
        try {
          setTrackLoading(true);
          const data = await trackingOrder(row.id);
          if (data.status === 200) {
            setTrackData(data.orderstatus);
          }
          setTrackLoading(false);
          setTrackModal(true);
        } catch (error) {
          setTrackLoading(false);
          console.log(error);
        }
      }
    };
    getTrackdetails();
  }, []);

  const handleCloseTrackModel = useCallback(() => {
    setTrackModal(false);
    setTrackData([]);
    setId(null);
  }, []);

  const handleGenerateAusPostOrder = async () => {
    const auspostresponse = await generateOrder(selected);
    if (auspostresponse.success) {
      alert("Order Created successfully");
    }
    // console.log(selected);
  };
  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        <Box display="flex" alignItems="center" className={classes.secTopBar}>
          <Typography className={classes.heading} style={{ marginRight: 16 }}>
            Fullfilled Orders
          </Typography>
          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleAscDesc}
          >
            {orderbyformat === "DESC"
              ? "Show in DESC Order"
              : "Show in ASC Order"}
          </Button>

          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleGenerateAusPostOrder}
          >
            Generated AusPost Order
          </Button>
        </Box>

        <Stack
          direction="row"
          justifyContent="flex-end"
          mt={2}
          flexWrap="wrap"
          className={classes.searchInputBox}
        >
          {/* <TextField
            id="outlined-start-adornment"
            sx={{
              m: 1,
              width: "30ch",
              fontSize: "14px",
              backgroundColor: "#fff",
              height: "100%",
              marginBottom: "6px",
            }}
            size="small"
            placeholder="search"
            onChange={handleSearch}
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="outlined-start-adornment"
              className={classes.searchInput}
              sx={{
                m: 1,
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="search"
              onChange={handleSearch}
              value={searchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
        <Paper className={classes.paper}>
          <TableContainer>
            {loading && <LinearProgress />}
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}

                // numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                // rowCount={orders.length}
              />
              <TableBody>
                {orders.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced - table - checkbox - ${index} `;
                  let date =
                    row.dispatchdate === "N/A"
                      ? "N/A"
                      : moment(row.dispatchdate).format("ll");
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      key={row.id}
                    >
                      <TableCell>
                        <Checkbox
                          value={row.id}
                          onChange={handleSelectAllClick}
                          // checked={selected.includes(row.id)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        className={classes.orderId}
                        onClick={(e) => handleOrderDetails(e, row.id)}
                      >
                        {`#${row.id} ${row.billing.first_name} ${row.billing.last_name} `}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleModelOpen(e, row)}>
                          <VisibilityOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleArtWork(e, row)}>
                          {artLoading && id === row.id ? (
                            <CircularProgress
                              className={classes.loading}
                              size={20}
                              thickness={4}
                            />
                          ) : (
                            <FaceOutlinedIcon fontSize="small" />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleTrackOrder(e, row)}>
                          {trackLoading && id === row.id ? (
                            <CircularProgress
                              className={classes.loading}
                              size={20}
                              thickness={4}
                            />
                          ) : (
                            <LocalShippingIcon fontSize="small" />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <OrderStatusForFullfilled row={row} />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleNotesOpen(e, row.id)}>
                          {notesLoading && id === row.id ? (
                            <CircularProgress
                              className={classes.loading}
                              size={20}
                              thickness={4}
                            />
                          ) : (
                            <ChatOutlinedIcon fontSize="small" />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <DateComponent date={date} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <OrderInfo
          handleClose={handleCloseModel}
          openModel={openModel}
          setOpenModel={setOpenModel}
          {...rowData}
        />
        <ArtworkDetail
          id={id}
          handleClose={handleCloseArtModel}
          openModel={artDetailModal}
          setOpenModel={setOpenModel}
          artData={artData}
        />
        <TrackIdPopup
          id={id}
          handleClose={handleCloseTrackModel}
          openModel={trackModal}
          setOpenModel={setOpenModel}
          trackData={trackData}
        />
        <OrderNotes
          handleClose={handleNotesClose}
          handleOpen={handleNotesOpen}
          notes={notes}
          open={openNotes}
          id={id}
        />
      </Box>
    </Layout>
  );
}
