import { useHistory } from "react-router-dom";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pagename = url.split("/")[1];
  return pagename;
}
function GoBackButton() {
  const pagename = getTheCurrentUrl();
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    gobackbtn: {
      padding: "4px 8px",
      fontSize: "13px",
      textTransform: "none",
      backgroundColor: "#ffffff",
      marginRight: "12px",
      [theme.breakpoints.down('xs')]: {
        marginBottom: "8px",
      },
    },
  }));
  const classes = useStyles();
  //console.log(getTheCurrentUrl());

  return (
    <>
      {pagename === "pending-order" && (
        <>
          <Button
            className={classes.gobackbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() =>
              history.push({
                pathname: `/pending-order/${localStorage.getItem(
                  "PendingNewPage"
                )}`,
              })
            }
          >
            Back
          </Button>
        </>
      )}
      {pagename === "important-order" && (
        <>
          <Button
            className={classes.gobackbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() =>
              history.push({
                pathname: `/important-order/${localStorage.getItem(
                  "ImportantNewPage"
                )}`,
              })
            }
          >
            Back
          </Button>
        </>
      )}
      {pagename === "orders" && (
        <Button
          className={classes.gobackbtn}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() =>
            history.push({
              pathname: `/orders/${localStorage.getItem("OrderNewPage")}`,
            })
          }
        >
          Back
        </Button>
      )}
      {pagename === "print" && (
        <Button
          className={classes.gobackbtn}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() =>
            history.push({
              pathname: `/print/${localStorage.getItem("PrintNewPage")}`,
            })
          }
        >
          Back
        </Button>
      )}
      {pagename === "fulfilled" && (
        <Button
          className={classes.gobackbtn}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() =>
            history.push({
              pathname: `/fulfilled/${localStorage.getItem("FulfilledPage")}`,
            })
          }
        >
          Back
        </Button>
      )}
    </>
  );
}
export default GoBackButton;
