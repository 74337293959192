import React, { useState } from "react";
import { Button, Box, TextField, Typography, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { sendSms } from "../../../api";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core";
import TrackEvents from "../../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  activityDetailBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

const Sms = ({ billing, id }) => {
  const classes = useStyles();
  const [phone, setPhone] = useState(billing ? billing.phone : "");
  const [name, setName] = useState(
    `${billing.first_name || ""} ${billing.last_name}`
  );
  const [msg, setMsg] =
    useState(`Dear ${name}. Please check your email, we have sent a mock for your order.
Thanks
Printyo Design Team 
**NO REPLY**`);
  const [loading, setLoading] = useState(false);
  const [alertString, setAlertString] = useState("");
  const [alert, setAlert] = useState(false);
  const [responseSeverity, setResponseSeverity] = useState("");
  const userid = localStorage.getItem("auth_id");
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleMsgChange = (e) => {
    setMsg(e.target.value);
  };

  const handleSubmit = async () => {
    if (!msg) {
      return;
    }
    var data = new FormData();
    data.append("smsnumber", phone);
    data.append("smsmessage", msg);
    data.append("userid", userid);
    try {
      setLoading(true);
      let result = await sendSms(id, data);
      //console.log("sms response", result);
      setLoading(false);
      setAlert(true);
      setAlertString("Message sent successfully");
      setResponseSeverity("success");
      // TrackEvents("SMS sent In Pending Order Activity", {
      //   orderId: id,
      // });
      TrackEvents(`${id}`, {
        process: "SMS sent Under Activity",
      });
      //setMsg("");
    } catch (error) {
      //console.log(error);
      setLoading(false);
      setAlert(true);
      setAlertString("Something went wrong while sending message to the user");
      setResponseSeverity("error");
    }
  };

  return (
    <Paper
      sx={{ p: 2, mt: 4, width: "70vw" }}
      className={classes.activityDetailBox}
    >
      {alert ? (
        <Alert
          severity={responseSeverity}
          sx={{
            margin: "10px 0 20px",
          }}
        >
          {alertString}
        </Alert>
      ) : null}
      <Typography
        sx={{
          mr: 2,
          color: "#3c434a",
          fontSize: "15px",
          lineHeight: "1.4em",
          fontWeight: "bold",
        }}
      >
        Send Sms
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Typography
          sx={{
            mr: 2,
            color: "#3c434a",
            fontSize: "15px",
            lineHeight: "1.4em",
          }}
        >
          Phone number:
        </Typography>
        <TextField
          id="input-with-sx"
          size="small"
          type="number"
          variant="outlined"
          value={phone}
          onChange={handlePhoneChange}
        />
      </Box>
      <TextField
        id="filled-multiline-flexible"
        multiline
        margin="dense"
        rows={4}
        value={msg}
        onChange={handleMsgChange}
        fullWidth
      />
      <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Sending" : "send"}
        </Button>
      </Box>
    </Paper>
  );
};

export default Sms;
