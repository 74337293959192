import React from "react";
import {
  Typography,
  Divider,
  Paper,
  Stack,
  Container,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import LinearProgress from "@mui/material/LinearProgress";
import Notes from "./notes";

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
  marginBottom: "1rem",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "400",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    // margin: "0",
    // fontWeight: "400",
    // letterSpacing: "0.00938em",
    // fontSize: "13px",
    // lineHeight: "1.4em",
  },
  title: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  container: {
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
  },
  orderDetailTitle: {
    [theme.breakpoints.down("xs")]: {
      flex: "unset !important",
    },
  },
  orderDetailsNotes: {
    "& .MuiContainer-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiButton-root": {
      display: "none",
    },
    "& .MuiTypography-root": {
      margin: "0",
    },
    "& .MuiPaper-root": {
      width: "100%",
      marginTop: "24px",
    },
  },
}));

const EnquiryDetails = ({ id, enquiry, loading }) => {
  const classes = useStyles();

  return (
    <>
      {loading && <LinearProgress />}
      <Container className={classes.container}>
        <Paper sx={{ padding: "1rem" }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              marginBottom: "1rem",
            }}
          >
            Enquiry #{id} details
          </Typography>
          <Divider />
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Name -{" "}
            </Typography>
            <Typography>{enquiry.name}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Email -{" "}
            </Typography>
            <Typography>{enquiry.email}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Phone -{" "}
            </Typography>
            <Typography>{enquiry.phone}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Product -{" "}
            </Typography>
            <Typography>{enquiry.product_name}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Category -{" "}
            </Typography>
            <Typography>{enquiry.category_name}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Quantity -{" "}
            </Typography>
            <Typography>{enquiry.quantity}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" my={2}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "1em",
                marginRight: "8px",
              }}
            >
              Status -{" "}
            </Typography>
            <Typography>{enquiry.status}</Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "1em",
              marginTop: "1rem",
              marginBottom: "8px",
            }}
          >
            Message
          </Typography>
          <Typography>{enquiry.comment}</Typography>
        </Paper>
      </Container>
      <Box sx={{ mt: 4 }} className={classes.orderDetailsNotes}>
        <Notes enquiry={enquiry} id={id} />
      </Box>
    </>
  );
};

export default EnquiryDetails;
