import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddProduct from "./AddProduct";
import AddCustomProduct from "./AddCustomProduct";
import AddShipping from "./AddShipping";
import CloseIcon from "@mui/icons-material/Close";
import { getShippingPrice } from "../../../api";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
// const fetchdata = () => {
//     var axios = require('axios');
//     var config = {
//         method: 'get',
//         url: 'https://digitalapi.auspost.com.au/postage/parcel/domestic/calculate.json?from_postcode=3014&to_postcode=3076&length=5&width=5&height=2&weight=4&service_code=AUS_PARCEL_REGULAR',
//         headers: {
//             "auth-key": 'cb05757b-60ef-4fed-bea7-7f1c77d27331',
//         }
//     };
//     axios(config)
//         .then(function (response) {
//             console.log(JSON.stringify(response.data));
//         })
//         .catch(function (error) {
//             console.log(error);
//         })
// }

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const PriceListContainer = ({
  shippingDetails,
  products,
  setProducts,
  totalPrice,
  setTotalPrice,
  shippingInfo,
  setShippingInfo,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openShippingModal, setOpenShippingModal] = useState(false);
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [standardPrice, setStandardPrice] = useState(9.99);
  const [expressPrice, setExpressPrice] = useState(11.99);
  const [shippingButtonDisable, setshippingButtonDisable] = useState(false);
  const [changeTotal, setChangeTotal] = useState(0);
  useEffect(() => {
    let total = 0;
    products.map((item) => (total = total + item.total));
    //console.log(total);
    if (shippingInfo.shippingPrice && shippingInfo.shippingPrice > 0) {
      total = total + parseFloat(shippingInfo.shippingPrice);
    }
    setTotalPrice(parseFloat(total).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, shippingInfo.shippingPrice, changeTotal]);

  const getParcel = (
    total_quantity,
    service_code = "AUS_PARCEL_REGULAR",
    weight
  ) => {
    let parcel = {
      length: 0,
      width: 0,
      height: 0,
      service_code,
      from_postcode: "3014",
      to_postcode: shippingDetails,
      weight,
    };
    if (total_quantity === 1) {
      parcel["length"] = 20;
      parcel["width"] = 13;
      parcel["height"] = 13;
    } else if (total_quantity > 1 && total_quantity <= 3) {
      parcel["length"] = 27;
      parcel["width"] = 16;
      parcel["height"] = 12;
    } else if (total_quantity > 3 && total_quantity <= 5) {
      parcel["length"] = 35;
      parcel["width"] = 35;
      parcel["height"] = 12;
    } else if (total_quantity > 5 && total_quantity <= 8) {
      parcel["length"] = 35;
      parcel["width"] = 35;
      parcel["height"] = 12;
    } else if (total_quantity > 8 && total_quantity <= 10) {
      parcel["length"] = 50;
      parcel["width"] = 37;
      parcel["height"] = 10;
    } else if (total_quantity > 10 && total_quantity <= 20) {
      parcel["length"] = 50;
      parcel["width"] = 37;
      parcel["height"] = 24;
    } else if (total_quantity > 20 && total_quantity <= 30) {
      parcel["length"] = 50;
      parcel["width"] = 74;
      parcel["height"] = 24;
    } else if (total_quantity > 30 && total_quantity <= 40) {
      parcel["length"] = 50;
      parcel["width"] = 74;
      parcel["height"] = 24;
    } else if (total_quantity > 40 && total_quantity <= 50) {
      parcel["length"] = 50;
      parcel["width"] = 74;
      parcel["height"] = 36;
    } else {
      parcel["length"] = 100;
      parcel["width"] = 50;
      parcel["height"] = 49;
    }
    return parcel;
  };

  const handleShipping = async () => {
    //console.log(shippingDetails);
    setshippingButtonDisable(true);
    if (!shippingDetails) {
      setshippingButtonDisable(false);
      return alert("please enter the shipping details");
    }
    let mugCount = 0;
    let cupIds = [168, 161, 139];
    let weight = 400;
    products.forEach((item) => {
      item.product.categories.some((data) => {
        if (cupIds.includes(data.id)) {
          mugCount = mugCount + parseInt(item.qty);
          return true;
        } else {
          return false;
        }
      });
    });

    let weight_in_kg = (weight * mugCount) / 1000;
    // console.log('weight_in_kg', weight_in_kg, weight, mugCount)
    if (weight_in_kg <= 0) weight_in_kg = 0.4;
    if (weight_in_kg > 22) {
      weight_in_kg = 22;
    }

    if (totalPrice < 200 && mugCount > 15) {
      let parcel = getParcel(mugCount, "AUS_PARCEL_EXPRESS", weight_in_kg);
      let data = await getShippingPrice(parcel);
      setShippingInfo({
        ...shippingInfo,
        shippingPrice: data.postage_result.total_cost,
      });
      setExpressPrice(data.postage_result.total_cost);
    } else if (totalPrice > 200) {
      let parcel = getParcel(mugCount, "AUS_PARCEL_EXPRESS", weight_in_kg);
      let parcel1 = getParcel(mugCount, "AUS_PARCEL_REGULAR", weight_in_kg);
      let data = await getShippingPrice(parcel);
      setShippingInfo({
        ...shippingInfo,
        shippingPrice: data.postage_result.total_cost,
      });
      setExpressPrice(data.postage_result.total_cost);
      let data1 = await getShippingPrice(parcel1);
      setShippingInfo({
        ...shippingInfo,
        shippingPrice: data1.postage_result.total_cost,
      });
      setStandardPrice(data1.postage_result.total_cost);
    } else if (mugCount > 15) {
      let parcel = getParcel(mugCount, "AUS_PARCEL_EXPRESS", weight_in_kg);
      let parcel1 = getParcel(mugCount, "AUS_PARCEL_REGULAR", weight_in_kg);
      let data = await getShippingPrice(parcel);
      setShippingInfo({
        ...shippingInfo,
        shippingPrice: data.postage_result.total_cost,
      });
      setExpressPrice(data.postage_result.total_cost);
      let data1 = await getShippingPrice(parcel1);
      setShippingInfo({
        ...shippingInfo,
        shippingPrice: data1.postage_result.total_cost,
      });
      setStandardPrice(data1.postage_result.total_cost);
    }
    setOpenShippingModal(true);
    setshippingButtonDisable(false);
  };

  const handleCancle = (product) => {
    const filterProducts = products.filter(
      (item) => item.product.name !== product.name
    );
    setProducts(filterProducts);
  };

  const changeTotalPriceOfProducts = (e, product) => {
    const priceProducts = products.map((item) => {
      if (product.name === item.product.name) {
        //console.log(item);
        if (!e.target.value) {
          item.total = parseInt(item.qty) * parseFloat(item.price);
        } else {
          item.total = parseInt(item.qty) * parseFloat(e.target.value);
        }
        //console.log(product.name);
        setChangeTotal(e.target.value);
      }
      return <></>;
    });
  };

  return (
    <Paper sx={{ p: "2rem 1rem", flex: 1, my: 2 }}>
      <Grid container justifyContent="start" sx={{ mr: "1rem", pr: "1rem" }}>
        <Grid item md={7} xs={4}>
          <TypographyHeading>Product</TypographyHeading>
        </Grid>
        <Grid item md={1} xs={2} textAlign="center">
          <TypographySubHeading>Orignal Price</TypographySubHeading>
        </Grid>
        <Grid item md={1} xs={2} textAlign="center">
          <TypographySubHeading>Quantity</TypographySubHeading>
        </Grid>
        <Grid item md={2} xs={2} textAlign="center">
          <TypographySubHeading>Price Offered</TypographySubHeading>
        </Grid>
        <Grid item md={1} xs={2} textAlign="center">
          <TypographySubHeading> Total</TypographySubHeading>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Divider />
      {products.length > 0
        ? products.map((item) => {
            const { product, qty, attributes, total, price } = item;
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={product.id}
              >
                <Grid
                  container
                  sx={{ my: "1rem" }}
                  justifyContent="start"
                  alignItems="center"
                >
                  <Grid item md={7}>
                    <Typography
                      sx={{
                        color: "#3c434a",
                        fontSize: "13px",
                        lineHeight: "1.4em",
                        fontWeight: "bold",
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <CustomTypography>sku:</CustomTypography>
                      <CustomTypography>{product.sku}</CustomTypography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <CustomTypography>Variation ID:</CustomTypography>
                      <CustomTypography>
                        {product.variations && product.variations[0]}
                      </CustomTypography>
                    </Box>
                    {attributes && Object.keys(attributes).length > 0 ? (
                      <>
                        {Object.keys(attributes).map((atr) => {
                          return (
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              key={atr}
                            >
                              <CustomTypography>{atr}:</CustomTypography>
                              <CustomTypography>
                                {attributes[atr]}
                              </CustomTypography>
                            </Stack>
                          );
                        })}
                      </>
                    ) : null}
                  </Grid>
                  <Grid item md={1} textAlign="center">
                    <CustomTypography>${price}</CustomTypography>
                  </Grid>
                  <Grid item md={1} textAlign="center">
                    <CustomTypography>x{qty}</CustomTypography>
                  </Grid>
                  <Grid item md={2} textAlign="center">
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      onChange={(e) => changeTotalPriceOfProducts(e, product)}
                    />
                  </Grid>
                  <Grid item md={1} textAlign="center">
                    <CustomTypography>${total}</CustomTypography>
                  </Grid>
                  <Grid item md={1} textAlign="center"></Grid>
                </Grid>
                <IconButton
                  onClick={() => handleCancle(product)}
                  sx={{ ml: 1 }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            );
          })
        : null}
      {shippingInfo.shippingPrice ? (
        <>
          <Stack direction="row" alignItems="center">
            <Grid container sx={{ mt: "10px", mb: "10px" }} alignItems="center">
              <Grid item md={11} xs={10}>
                <Typography
                  sx={{
                    color: "#3c434a",
                    fontSize: "13px",
                    lineHeight: "1.4em",
                    fontWeight: "bold",
                  }}
                >
                  Shipping price
                </Typography>
              </Grid>
              <Grid item md={1} xs={2} textAlign="end">
                <CustomTypography>
                  {" "}
                  $ {shippingInfo.shippingPrice}
                </CustomTypography>
              </Grid>
            </Grid>
            <IconButton
              onClick={() =>
                setShippingInfo({ ...shippingInfo, shippingPrice: "" })
              }
              sx={{ ml: 1 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Stack spacing={2} direction="row">
            <Typography
              sx={{
                color: "#3c434a",
                fontSize: "12px",
                lineHeight: "1.4em",
                fontWeight: "bold",
              }}
            >
              Shipping Details:
            </Typography>
            <CustomTypography> {shippingInfo.shippingName}</CustomTypography>
          </Stack>
        </>
      ) : null}
      <Stack alignItems="flex-end" my={2}>
        <Stack direction="row" justifyContent="center" spacing={3}>
          <TypographySubHeading>Items Subtotal:</TypographySubHeading>
          <TypographyHeading> ${totalPrice}</TypographyHeading>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={3}>
          <TypographySubHeading>Order Total:</TypographySubHeading>
          <TypographyHeading> ${totalPrice}</TypographyHeading>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" spacing={2} mt={2}>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => setOpenModal(true)}
        >
          Add products
        </Button>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => setOpenCustomModal(true)}
        >
          Add custom products
        </Button>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={handleShipping}
          disabled={shippingButtonDisable}
          className={shippingButtonDisable ? "shippingloading" : null}
        >
          {shippingButtonDisable ? (
            <CircularProgress color="inherit" />
          ) : (
            "Add Shipping"
          )}
        </Button>
      </Stack>
      <AddProduct
        openModal={openModal}
        setOpenModal={setOpenModal}
        products={products}
        setProducts={setProducts}
      />
      <AddCustomProduct
        openModal={openCustomModal}
        setOpenModal={setOpenCustomModal}
        products={products}
        setProducts={setProducts}
      />
      <AddShipping
        openShippingModal={openShippingModal}
        setOpenShippingModal={setOpenShippingModal}
        setShippingInfo={setShippingInfo}
        shippingInfo={shippingInfo}
        standardPrice={standardPrice}
        expressPrice={expressPrice}
        // setShippingPrice={setShippingPrice} shippingMethod={shippingMethod} setShippingMethod={setShippingMethod}
      />
    </Paper>
  );
};
export default PriceListContainer;
