import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Layout from "../../components/Layout";
import Dashboard from "./Dashboard";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  orderbyheading: {
    fontSize: "18px",
    lineHeight: 1.3,
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px",
    },
  },
  printOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px",
    },
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  editIcon: {
    height: "15px !important",
    marginLeft: "7px",
    cursor: "pointer",
  },
  showgreen: {
    backgroundColor: "#a2f8a2",
    color: "#ffffff",
  },
  orderbybox: {
    backgroundColor: "#fff",
    padding: "1rem",
    marginTop: "1rem",
    borderRadius: "4px",
  },
  orderbyButton: {
    display: "inline-block",
    padding: "9px 10px !important",
    minWidth: "100px !important",
  },
  orderTabs: {
    "& .MuiTab-root": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "120px",
      },
    },
  },
  searchInputBox: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      marginTop: "8px",
    },
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
      width: "172px !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  orderTopBar: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  tabPanel: {
    "& > .MuiBox-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "16px",
      },
    },
  },
  printOrderDateBox: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  orderStatusBtn: {
    "& .MuiIconButton-root": {
      padding: "0 12px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "8px",
      },
    },
    "& .MuiButton-contained": {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "8px",
      },
    },
  },
  orderIdBox: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pagename = url.split("/")[1];
  return pagename;
}

export default function BasicTabs() {
  const classes = useStyles();
  const pagename = getTheCurrentUrl();
  const history = useHistory();
  const search = useLocation().search;
  const path = useLocation().pathname;
  const tab = new URLSearchParams(search).get("tab");
  const id = new URLSearchParams(search).get("id");
  const [value, setValue] = useState(parseInt(tab) || 0);
  const [res, setRes] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push({
      pathname: path,
      search: `?tab=${newValue}`,
    });
  };

   
  return (
    <Layout>
      <Box
        sx={{ width: "100%", backgroundColor: "#f0f0f1" }}
        minHeight={`calc(100vh - 63px)`}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#fff",
          }}
        >
          <Tabs
            className={classes.orderTabs}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="Dashboard" {...a11yProps(0)} sx={{ minWidth: "180px" }} />
            <Tab label="Products" {...a11yProps(1)} sx={{ minWidth: "180px" }} />
            <Tab label="Categories " {...a11yProps(2)} sx={{ minWidth: "180px" }} />
            <Tab label="Payment " {...a11yProps(3)} sx={{ minWidth: "180px" }} />
          </Tabs>
        </Box>
        <TabPanel className={classes.tabPanel} value={value} index={0}>
          <Dashboard />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={1}>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={2}>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={3}>
        </TabPanel>
      </Box>
    </Layout>
  );
}
