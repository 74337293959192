import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
        width: "100%",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
      width: "100% !important",
      minWidth: "210px",
    },
  },
  heading: {
    margin: "0 15px 0 0 !important",
    minWidth: "90px",
  },
  para: {
    margin: "0",
  },
}));

const ViewPopup = (props) => {
  const classes = useStyles();
  const { handleClose, open, row } = props;
  console.log(row);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TypographyHeading>View Enquiry</TypographyHeading>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Stack direction="row" alignItems="center" my={2}>
          <TypographySubHeading className={classes.heading}>
            Name
          </TypographySubHeading>
          <Typography className={classes.para}>{row.name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <TypographySubHeading className={classes.heading}>
            Email
          </TypographySubHeading>
          <Typography className={classes.para}>{row.email}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <TypographySubHeading className={classes.heading}>
            Phone
          </TypographySubHeading>
          <Typography className={classes.para}>{row.phone}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <TypographySubHeading className={classes.heading}>
            Qunatity
          </TypographySubHeading>
          <Typography className={classes.para}>{row.quantity}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <TypographySubHeading className={classes.heading}>
            Product Name
          </TypographySubHeading>
          <Typography className={classes.para}>{row.product_name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <TypographySubHeading className={classes.heading}>
            Category Name
          </TypographySubHeading>
          <Typography className={classes.para}>{row.category_name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <TypographySubHeading className={classes.heading}>
            Comment
          </TypographySubHeading>
          <Typography className={classes.para}>{row.comment}</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ViewPopup;
