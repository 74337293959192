import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  Paper,
  Typography,
  Stack,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import { addMail } from "../../../api";
import mailTemplate1 from "../../../components/mailTemplate";
import mailTemplate2 from "../../../components/mailTemplate2";
import artworkMissingTemplate from "../../../components/Artworkmissingtemplate";
import ReviewMailTemplate from "../../../components/ReviewMailTemplate";
import ReviewForMockup from "../../../components/ReviewForMockup";
import PreviewTemplate from "../../../components/utils/PreviewTemplate";
import Alert from "@mui/material/Alert";
import { useDropzone } from "react-dropzone";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TrackEvents from "../../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
  },
  dropbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fafafa",
    padding: "24px;",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderStyle: "solid",
    marginTop: "8px",
    marginBottom: "4px",
    cursor: "pointer",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  dropboxactive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px;",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#000",
    backgroundColor: "#fafafa",
    borderStyle: "solid",
    marginTop: "8px",
    marginBottom: "4px",
    cursor: "pointer",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  text: {
    fontSize: "18px",
    color: "#bdbdbd",
  },
  activityDetailBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  activityPreviewBtn: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    },
  },
}));

const Mail = (props) => {
  const classes = useStyles();
  const { order, setResEamil, resEmail } = props;
  const { billing, id } = order;
  const [files, setFiles] = useState([]);
  const [feilds, setFeilds] = useState({
    customeremail: billing?.email,
    mailsubject: "",
    mailmessage: "",
  });
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState("template1");
  const [open, setOpen] = useState(false);
  const [alertString, setAlertString] = useState("");
  const [alert, setAlert] = useState(false);
  const [responseSeverity, setResponseSeverity] = useState("");
  const userid = localStorage.getItem("auth_id");
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  // const handleFileUpload = (e) => {
  //   e.preventDefault();
  //   let allfiles = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     allfiles.push(e.target.files[i]);
  //   }
  //   if (allfiles.length > 0) {
  //     setFiles(allfiles);
  //   }
  // };

  const handleSubmit = async () => {
    let template = "";
    if (templates === "template2") {
      template = mailTemplate2(order, feilds.mailmessage);
    } else if (templates === "template3") {
      template = artworkMissingTemplate(order, feilds.mailmessage);
    } else if (templates === "template4") {
      template = ReviewMailTemplate(order, feilds.mailmessage);
    } else if (templates === "template5") {
      template = ReviewForMockup(order, feilds.mailmessage);
    } else {
      template = mailTemplate1(order, feilds.mailmessage);
    }
    const data = new FormData();
    data.append("customeremail", feilds.customeremail);
    data.append("mailsubject", feilds.mailsubject);
    data.append("mailmessage", template);
    data.append("userid", userid);
    files.forEach((item) => data.append("emailattachmenturl[]", item));
    try {
      setLoading(true);
      const res = await addMail(order.id, data);
      if (res.status === 200) {
        setAlert(true);
        setAlertString("Mail sent successfully");
        setResponseSeverity("success");
        //alert(`Mail send to ${feilds.customeremail}`);
        setLoading(false);
        setFeilds({
          customeremail: billing?.email,
          mailsubject: "",
          mailmessage: "",
          Bcc: "",
        });
        setResEamil(!resEmail);
        setFiles([]);
        // TrackEvents("Mail send In Pending Order Activity", {
        //   orderId: order.id,
        // });
        TrackEvents(`${order.id}`, {
          process: "Mail sent Under Activity",
        });
      }
    } catch (error) {
      console.log("eror from mockup", error);
      setLoading(false);
      setAlert(true);
      setAlertString("Something went wrong while sending mail to the user");
      setResponseSeverity("error");
    }
  };

  const handleChange = (e) => {
    setFeilds({ ...feilds, [e.target.name]: e.target.value });
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setFeilds({
      ...feilds,
      ["mailmessage"]: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };

  const handleSelectTemplate = (e) => {
    setTemplates(e.target.value);
  };

  const handleOrderSubject = () => {
    setFeilds({
      mailsubject: "",
      customeremail: billing?.email,
    });
  };

  const handleMailSubject = () => {
    setFeilds({
      mailsubject: "",
      customeremail: billing?.email,
    });
  };

  const handleArtworkSubject = () => {
    setFeilds({
      mailsubject: `Artwork Missing for Order #${id}`,
      customeremail: billing?.email,
    });
  };

  const handleReviewSubject = () => {
    setFeilds({
      mailsubject: `Thanks for your order #${id}! Here's a link to leave a review`,
      customeremail: billing?.email,
    });
  };

  const handleReviewMockupSubject = () => {
    setFeilds({
      mailsubject: `Thanks for your order #${id}! Here's a link to leave a review`,
      customeremail: billing?.email,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Dropzone

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((files) =>
          Object.assign(files, {
            preview: URL.createObjectURL(files),
          })
        )
      );
    },
  });

  // const images = files.map((files) => (
  //   <div key={files.name}>
  //     <div>
  //       <img src={files.preview} style={{ width: "200px" }} alt="preview" />
  //     </div>
  //   </div>
  // ));

  const handleCancelUpload = (key) => {
    const filteredFiles = files.filter((item, i) => i !== key);
    setFiles(filteredFiles);
  };

  return (
    <Paper
      sx={{ p: 3, mt: 4, width: "70vw" }}
      className={classes.activityDetailBox}
    >
      <PreviewTemplate
        open={open}
        handleClose={handleClose}
        template={templates}
        msg={feilds.mailmessage}
        order={order}
      />
      {alert ? (
        <Alert
          severity={responseSeverity}
          sx={{
            margin: "10px 0 20px",
          }}
        >
          {alertString}
        </Alert>
      ) : null}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            color: "#3c434a",
            fontSize: "15px",
            lineHeight: "1.4em",
            fontWeight: "bold",
          }}
        >
          Send mail
        </Typography>
        <Stack direction="row" alignItems="center">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Template</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={templates}
              label="Template"
              size="small"
              onChange={handleSelectTemplate}
            >
              <MenuItem value={"template1"} onClick={handleOrderSubject}>
                Order Template
              </MenuItem>
              <MenuItem value={"template2"} onClick={handleMailSubject}>
                Mail Temaplate
              </MenuItem>
              <MenuItem value={"template3"} onClick={handleArtworkSubject}>
                Missing Artwork
              </MenuItem>
              <MenuItem value={"template4"} onClick={handleReviewSubject}>
                Review Temaplate
              </MenuItem>
              <MenuItem value={"template5"} onClick={handleReviewMockupSubject}>
                Review For Mockup
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            className={classes.activityPreviewBtn}
            size="small"
            variant="text"
            color="primary"
            sx={{ ml: 2 }}
            onClick={() => setOpen(true)}
          >
            preview
          </Button>
        </Stack>
      </Stack>
      <TextField
        id="outlined-start-adornment"
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">To</InputAdornment>,
        }}
        type="email"
        size="small"
        name="customeremail"
        value={feilds.customeremail}
        margin="dense"
        onChange={handleChange}
      />
      <TextField
        id="outlined-start-adornment"
        fullWidth
        size="small"
        name="mailsubject"
        placeholder="Subject"
        margin="dense"
        value={feilds.mailsubject}
        onChange={handleChange}
      />
      <>
        <Box
          {...getRootProps()}
          className={isDragActive ? classes.dropboxactive : classes.dropbox}
        >
          <input {...getInputProps()} />
          <Typography className={classes.text}>
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </Box>
      </>
      {files[0] && (
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "4px",
            p: 1,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {files.map((file, key) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                key={key}
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  px: 1,
                  mr: 1,
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{ height: "20px" }}
                />
                <Box ml={2}>{file.name}</Box>
                <IconButton onClick={() => handleCancelUpload(key)}>
                  <ClearIcon size="small" />
                </IconButton>
              </Stack>
            );
          })}
        </Box>
      )}
      {/* <TextField
        id="filled-multiline-flexible"
        multiline
        margin="dense"
        rows={8}
        name="mailmessage"
        value={feilds.mailmessage}
        onChange={handleChange}
        fullWidth
      /> */}
      <Box
        display="flex"
        alignItems="center"
        mt={2}
        p={1}
        sx={{
          border: 1,
          borderColor: "grey.500",
          borderRadius: 1,
        }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onEditorStateChange}
          placeholder="If you want to add any other information to the mail, please add it here."
          toolbar={{
            options: ["blockType", "inline"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
            },
            blockType: {
              inDropdown: false,
              options: ["H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
            },
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
        {/* <Box mr={4}>
          <input
            multiple={true}
            type="file"
            style={{ display: "none" }}
            name="image"
            accept="image"
            onChange={handleFileUpload}
            id="contained-button-file"
          />
          <label htmlFor="contained-button-file" style={{ cursor: "pointer" }}>
            <AttachFileIcon fontSize="small" />
          </label>
        </Box> */}
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Sending" : "Send"}
        </Button>
      </Box>
    </Paper>
  );
};

export default Mail;
