import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography, Box, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    subHeading: {
        color: '#3c434a',
        fontSize: '13px',
        lineHeight: '1.4em',
    },
    heading: {
        fontSize: '13px',
        lineHeight: '1.4em',
        fontWeight: 'bold'
    },
    typography: {
        fontSize: '13px',
        lineHeight: '1.4em',
        marginBottom: "1rem",
    },
    title: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '1.5em',
    },
    titleBtn: {
        textTransform: 'none',
        marginRight: '1rem'
    },
    status: {
        fontSize: '1rem'
    },
    margin: {
        marginBottom: '10px'
    },
    image: {
        width: '250px'
    },
    modal: { 
      "& .MuiDialog-paper" : {
          [theme.breakpoints.down('xs')]: {
              width: "100%",
              margin: "16px",
          },
      },
    },
    modalContent: {    
        [theme.breakpoints.down('xs')]: {
            padding: "16px"
        },
    },
    fullWidth: {
        [theme.breakpoints.down('xs')]: {
            width: "100% !important",
            minWidth: "210px",
        },
    },
}))


const OrderInfo = (props) => {
    const { id, openModel, handleClose, artData } = props
    const classes = useStyles()
    return (
        <Dialog
            open={openModel}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.modal}
        >
            <DialogTitle id="form-dialog-title">
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box>
                        <Typography className={classes.title}>Order #{id}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers className={classes.modalContent}>
                <Box mb={2} width={400} className={classes.fullWidth}>
                    <Box display='flex' alignItems='center' my={1} justifyContent='space-between'>
                        <Typography className={classes.heading} style={{ marginRight: '10px' }}>Approve Images</Typography>
                    </Box>
                    {(artData && artData.length > 0) ?
                        artData.map((item, index) => (
                            <div key={index}>
                                {
                                    (item.approvedmockup && item?.approvedmockup.length > 0) ? (
                                        item?.approvedmockup.map((prod) => (
                                            <Box display='flex' alignItems='center' key={item.id} justifyContent='space-between'>
                                                <Typography className={classes.typography} style={{ marginRight: '10px' }}>Artwork Image:</Typography>
                                                <a href={`https://printyocrm.printyo.net.au/${prod.attachmenturl}`} target="_blank" rel="noopener noreferrer">
                                                    <Typography className={classes.typography}>View Image</Typography>
                                                </a>
                                            </Box>
                                        )
                                        )
                                    ) : null
                                }
                            </div>

                        )
                        ) : <Typography>No Images found</Typography>
                    }
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default OrderInfo
