import React, { useEffect, useState } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import { Logout, getUserUnreadNotificationCount } from "../api";
import Notifications from "./Notifications";
import PersonIcon from "@mui/icons-material/Person";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#ffffff",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginLeft: "0",
    },
  },
  appBarFull: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  notificationButton: {
    marginRight: theme.spacing(2),
    alignContent: "flex-end",
  },
  userlogin: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    alignContent: "flex-end",
    color: "#000",
    [theme.breakpoints.down('xs')]: {
      width: "70px",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  spin: {
    marginRight: theme.spacing(2),
    alignContent: "flex-end",
    color: "#DC3545",
    animation: "$ring 4s .7s ease-in-out infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes ring": {
    "0%": { transform: "rotateZ(0)" },
    "1%": { transform: "rotateZ(30deg)" },
    "3%": { transform: "rotateZ(-28deg)" },
    "5%": { transform: "rotateZ(34deg)" },
    "7%": { transform: "rotateZ(-32deg)" },
    "9%": { transform: "rotateZ(30deg)" },
    "11%": { transform: "rotateZ(-28deg)" },
    "13%": { transform: "rotateZ(26deg)" },
    "15%": { transform: "rotateZ(-24deg)" },
    "17%": { transform: "rotateZ(22deg)" },
    "19%": { transform: "rotateZ(-20deg)" },
    "21%": { transform: "rotateZ(18deg)" },
    "23%": { transform: "rotateZ(-16deg)" },
    "25%": { transform: "rotateZ(14deg)" },
    "27%": { transform: "rotateZ(-12deg)" },
    "29%": { transform: "rotateZ(10deg)" },
    "31%": { transform: "rotateZ(-8deg)" },
    "33%": { transform: "rotateZ(6deg)" },
    "35%": { transform: "rotateZ(-4deg)" },
    "37%": { transform: "rotateZ(2deg)" },
    "39%": { transform: "rotateZ(-1deg)" },
    "41%": { transform: "rotateZ(1deg)" },

    "43%": { transform: "rotateZ(0)" },
    "100%": { transform: "rotateZ(0)" },
  },
}));

function Appbar(props) {
  const { toggleSidebar, setToggleSidebar} = props;
  const classes = useStyles();
  const history = useHistory();
  const [res, setRes] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [NotificationCount, setNotificationCount] = useState(0);
  useEffect(() => {
    const fetchNotificationsCount = async () => {
      try {
        setWaiting(true);
        const data = await getUserUnreadNotificationCount();
        if (data.status === 200) {
          setNotificationCount(data.notifications);
        }
        setWaiting(false);
      } catch (error) {
        console.log("error form notifications count", error);
      }
    };
    fetchNotificationsCount();
  }, [res]);

  const handleLogout = async () => {
    try {
      const logout = await Logout();
      localStorage.clear();
      history.push("/login");
    } catch (error) {
      console.log("something went wrong", error);
    }
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const NotificationComponent = () => {
    const [openNotipop, setNotiPop] = useState(false);

    const handleCloseNotiPop = () => {
      setNotiPop(false);
    };
    return (
      <>
        <IconButton
          style={{ padding: 0 }}
          edge="end"
          className={
            NotificationCount ? classes.spin : classes.notificationButton
          }
          aria-label="menu"
          onClick={() => setNotiPop(true)}
        >
          <Badge badgeContent={NotificationCount}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {openNotipop ?
        <Notifications
          open={openNotipop}
          handleClose={handleCloseNotiPop}
          res={res}
        />
        : null
  }
      </>
    );
  };

  return (
    <>
      <AppBar position="fixed" className={toggleSidebar?
        classes.appBar
          :classes.appBarFull
        }>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={() => setToggleSidebar(!toggleSidebar)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justifyContent="flex-end">
            <IconButton
              edge="end"
              className={classes.notificationButton}
              aria-label="menu"
              onClick={handleProfile}
            >
              <Typography
                variant="body1"
                className={classes.userlogin}
                sx={{ fontWeight: "light", fontStyle: "normal" }}
              >
                Hi,{localStorage.getItem("auth_name")}
              </Typography>
              <PersonIcon />
            </IconButton>
            <NotificationComponent />
            <IconButton
              edge="end"
              className={classes.logOutButton}
              aria-label="menu"
              onClick={handleLogout}
            >
              <LogoutIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Appbar;
