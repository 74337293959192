import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getInventoryProductType, UpdateProduct } from "../../api";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
        width: "100%",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
      width: "100% !important",
      minWidth: "210px",
    },
  },
}));

const EditPopup = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    open,
    res,
    setRes,
    row,
    refreshProduct,
    setRefreshProduct,
  } = props;
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [fields, setFields] = useState({ product_type: 0 });
  const userid = localStorage.getItem("auth_id");

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const data = await getInventoryProductType();
        if (data.status === 200) {
          setProductList(data.producttype);
        }
        setLoading(false);
      } catch (error) {
        console.log("error form inventory", error);
      }
    };
    fetchProduct();
  }, [res, refreshProduct, loading]);

  const handleConfirmed = async () => {
    var data = new FormData();
    data.append("productname", row.product_name);
    data.append("productid", row.product_id);
    data.append("sku", row.sku);
    data.append("message", "product added");
    data.append("producttypes_id", fields.product_type);
    data.append("userid", userid);
    setLoading(true);
    try {
      const response = await UpdateProduct(data);
      if (response.status === 200) {
        setFields({ product_type: "" });
      }
      setRes(!res);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from update product", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TypographyHeading>View Product</TypographyHeading>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <TypographySubHeading>Name</TypographySubHeading>
        <Typography>{row.product_name}</Typography>
        <TypographySubHeading>ID</TypographySubHeading>
        <Typography>{row.product_id}</Typography>
        <TypographySubHeading>SKU</TypographySubHeading>
        <Typography>{row.sku}</Typography>
        <FormControl fullWidth size="small" sx={{ mt: 2 }}>
          <InputLabel id="product_type">Product Type</InputLabel>
          <Select
            labelId="product_type"
            id="product-type-select"
            label="Product Type"
            name="product_type"
            value={
              fields.product_type ? fields.product_type : row.product_type_id
            }
            onChange={handleChange}
          >
            {productList.map((item, index) => {
              return (
                <MenuItem value={`${item.id}`} key={item.id}>
                  {item.producttype}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          variant="contained"
          size="small"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing" : "Update"}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPopup;
