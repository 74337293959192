import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Stack,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Autocomplete,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { getProducts, AddInventoryProducts } from "../../api";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    marginTop: "1rem",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
    marginBottom: "1rem",
  },
  btn: {
    textTransform: "none",
    marginLeft: "10px",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  paper: {
    padding: "1rem",
  },
  content: {
    color: "#777",
    lineHeight: "1.6em",
    fontSize: "14px",
    marginTop: "2px",
  },
  comment: {
    backgroundColor: "#d7cad2",
    padding: "10px",
    position: "relative",
    lineHeight: "1.4",
    fontSize: "13px",
    color: "#3c434a",
  },
  commentDesign: {
    position: "absolute",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid #d7cad2",
    bottom: "-10px",
    left: "20px",
  },
}));

const AddProduct = ({ handleClose, open, id, res, setRes }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [addedProduct, setAddedProduct] = useState({});
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("product");
  const userid = localStorage.getItem("auth_id");
  const handlesearchType = (e) => {
    setSearchType(e.target.value);
  };

  const handleProducts = () => {
    if (Object.keys(addedProduct).length > 0) {
      setProducts([...products, addedProduct]);
      setAddedProduct({});
    }
  };
  const handleFilter = useCallback(
    (data) => {
      const filteredProduct = products.filter(
        (item) => item.name !== data.name
      );
      setProducts(filteredProduct);
    },
    [products]
  );

  const handleSubmit = async () => {
    var data = new FormData();
    data.append("message", msg);
    data.append("userid", userid);
    data.append("producttypes_id", id);
    products.forEach((item) => {
      data.append("productid[]", item.id);
      data.append("productname[]", item.name);
    });
    setLoading(true);
    try {
      const response = await AddInventoryProducts(data);
      console.log("response", response);
      if (response.status === 200) {
        setProducts([]);
        setMsg("");
        setAddedProduct({});
        setRes(!res);
      }
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from add producttype", error);
    }
  };

  const handleCloseModal = () => {
    setSearchType("product");
    handleClose();
  };

  useEffect(() => {
    const fetchproduct = async () => {
      var data;
      if (searchType === "sku") {
        data = await getProducts("", value, "variable");
        if (data.length == 0) {
          data = await getProducts("", value, "simple");
        }
        setOptions(data);
      } else {
        data = await getProducts(value, "", "variable");
        if (data.length == 0) {
          data = await getProducts(value, "", "simple");
        }
        setOptions(data);
      }
    };
    fetchproduct();
  }, [searchType, value]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={400}
        >
          <Box>
            <Typography className={classes.title}>
              Product type #{id}
            </Typography>
          </Box>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Search on</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchType}
            label="Template"
            size="small"
            onChange={handlesearchType}
          >
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="sku">Sku</MenuItem>
          </Select>
        </FormControl>
        <Box>
          {products.length > 0
            ? products.map((item, i) => {
                return (
                  <Paper sx={{ p: "5px", mb: "10px" }}>
                    <Stack
                      key={i}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>{item.name}</Typography>
                      <IconButton onClick={() => handleFilter(item)}>
                        <DeleteIcon fontSize="small" color="error" />
                      </IconButton>
                    </Stack>
                  </Paper>
                );
              })
            : null}
        </Box>
        <Box my={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            mb={2}
          >
            <Autocomplete
              id="asynchronous-demo"
              size="small"
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => {
                return (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                );
              }}
              filterOptions={(x) => x}
              onInputChange={(event, newInputValue) => {
                setValue(newInputValue);
              }}
              options={options}
              onChange={(e, v) => {
                setOptions(v ? [v, ...options] : options);
                setValue(v);
                setAddedProduct(v);
              }}
              // loading={loading}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
            <Button
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none" }}
              onClick={handleProducts}
            >
              Add
            </Button>
          </Stack>
          <TextField
            fullWidth
            type="text"
            label="Message"
            size="small"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          />
        </Box>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={handleSubmit}
        >
          Create
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddProduct;
