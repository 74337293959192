import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableFooter,
  TableRow,
  Paper,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Stack,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { getEnquiryList, getEnquirytNotes } from "../../api";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import ViewPopup from "./ViewPopup";
import EnquiryStatus from "../../components/utils/EnquiryStatus";
import EnquiryAssign from "../../components/EnquiryAssign";
import EnquiryNotes from "../../components/EnquiryNotes";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Enquiry ID</TableCell>
        <TableCell>Name </TableCell>
        {/* <TableCell>Email</TableCell> */}
        <TableCell>Phone</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Notes</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Assign To</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pinnedOrder: {
    color: "rgb(215 0 0)",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 850,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  popupcontainer: {
    ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
      boxShadow: "none",
    },
  },
  alreadyClicked: {
    backgroundColor: "#e8e8e8",
  },
  secBox: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  secTopBtns: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 !important",
    },
  },
  secTopBar: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  searchInputBox: {
    margin: "16px 0",
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  pinIcon: {
    cursor: "pointer",
  },
  secTopBtns: {
    marginLeft: "16px !important",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 !important",
    },
  },
}));

// View product
const ViewComponent = ({ res, setRes, row }) => {
  const [openViewPop, setViewPop] = useState(false);
  const handleCloseViewPop = () => {
    setViewPop(false);
  };
  const handleOpenViewPop = () => {
    setViewPop(true);
  };

  return (
    <>
      <Box width="60%" display="inline-block">
        <IconButton onClick={handleOpenViewPop}>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <ViewPopup
        open={openViewPop}
        handleClose={handleCloseViewPop}
        res={res}
        setRes={setRes}
        row={row}
      />
    </>
  );
};

export default function EnquiryPage() {
  var moment = require("moment-timezone");
  const currentPageId = parseInt(getTheCurrentUrl());
  const currentorderfilterstatus = localStorage.getItem("orderfilterstatus");
  const classes = useStyles();
  const history = useHistory();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enquiry, setEnquiry] = useState([]);
  const [page, setPage] = React.useState(currentPageId);
  const [count, setCount] = useState(0);
  const [changedEnquiryStatus, setChangedEnquiryStatus] = useState("none");
  const currentRowsPerPage = localStorage.getItem("enquiryRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(currentRowsPerPage) ? parseInt(currentRowsPerPage) : 10
  );
  localStorage.setItem("enquiryNewPage", currentPageId + 1);
  const [changedEnquiryAssign, setChangedEnquiryAssign] = useState("none");

  // Status
  const currentenquiryfilterstatus = localStorage.getItem(
    "enquiryfilterstatus"
  );
  const [enquiryfilterstatus, setEnquiryFilterStatus] = React.useState(
    currentenquiryfilterstatus ? currentenquiryfilterstatus : ""
  );
  const [filterpage, setFilterPage] = React.useState(0);

  const handleEnquiryStatusFilterChange = (e) => {
    setEnquiryFilterStatus(e.target.value);
    setFilterPage(0);
    if (e.target.value === "none" || e.target.value === "") {
      localStorage.removeItem("enquiryfilterstatus");
    } else {
      localStorage.setItem("enquiryfilterstatus", e.target.value);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setFilterPage(0);
    setPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("enquiryRowsPerPage");
    } else {
      localStorage.setItem("enquiryRowsPerPage", e.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/enquiry/${newPage + 1}`,
    });
  };

  // Search
  const [search, setSearch] = useState("");
  const [searchvalue, setSearchValue] = React.useState("");
  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
    setEnquiryFilterStatus("none");
    setFilterPage(0);
    localStorage.removeItem("enquiryfilterstatus");
  };

  useEffect(() => {
    const fetchEnquiry = async () => {
      try {
        setLoading(true);
        setCount(0);
        const data = await getEnquiryList(page + 1, rowsPerPage, search);
        setEnquiry(data.response.responseData.response);
        console.log(data);
        setCount(data.response.responseData.enquiry_count.count);
        console.log(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error form Enquiry", error);
      }
    };
    fetchEnquiry();
  }, [
    page,
    res,
    rowsPerPage,
    search,
    filterpage,
    enquiryfilterstatus,
    changedEnquiryStatus,
    changedEnquiryAssign,
  ]);

  const handleEnquiryDetails = useCallback(
    (e, id) => {
      const curl = window.location.pathname;
      const cpageId = curl.split("/")[2];
      const previousOrders = JSON.parse(localStorage.getItem("visitedOrders"));
      console.log(previousOrders);
      if (previousOrders) {
        // console.log(JSON.parse(previousOrders))
        previousOrders.push(id);
        localStorage.setItem("visitedOrders", JSON.stringify(previousOrders));
      } else {
        localStorage.setItem("visitedOrders", JSON.stringify([id]));
      }
      e.stopPropagation();
      history.push({
        pathname: `/enquiry/${cpageId}/details`,
        search: `?id=${id}&tab=0`,
      });
    },
    [history]
  );

  // Notes
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [id, setId] = useState(null);

  const handleNotesOpen = useCallback((e, id) => {
    e.stopPropagation();
    setId(id);
    const fetchNotes = async () => {
      if (id) {
        try {
          setNotesLoading(true);
          const data = await getEnquirytNotes(id);
          setNotes(data.enquirysnote);
          setNotesLoading(false);
          setOpenNotes(true);
        } catch (error) {
          setNotesLoading(false);
          console.log(error);
        }
      }
    };
    fetchNotes();
  }, []);

  const handleNotesClose = useCallback((e, row) => {
    setId(null);
    setOpenNotes(false);
  }, []);

  const handleAddEnquiry = () => {
    history.push("/add-enquiry");
  };

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        <Box display="flex" alignItems="center" className={classes.secTopBar}>
          <Typography className={classes.heading}>Enquiry</Typography>
          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleAddEnquiry}
          >
            Add enquiry
          </Button>
          <FormControl
            className={classes.secTopBtns}
            sx={{ minWidth: 150, backgroundColor: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "14px" }}>
              Select Status
            </InputLabel>
            <Select
              sx={{ fontSize: "14px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={enquiryfilterstatus}
              label="Select Status"
              onChange={handleEnquiryStatusFilterChange}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="hot">Hot</MenuItem>
              <MenuItem value="cold">Cold</MenuItem>
              <MenuItem value="dead">Dead</MenuItem>
              <MenuItem value="converted">Converted</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          className={classes.searchInputBox}
        >
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="outlined-start-adornment"
              className={classes.searchInput}
              sx={{
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="search"
              onChange={handleSearch}
              value={searchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
        <Paper className={classes.paper}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} />
              {enquiry && enquiry.length > 0 ? (
                <TableBody>
                  {enquiry.map((row, index) => {
                    // let date = moment(row.date)
                    // .tz("Australia/Melbourne")
                    // .format("ll");
                    console.log(row);
                    let previousOrdersClick = JSON.parse(
                      localStorage.getItem("visitedEnquiry")
                    );
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          key={row.id}
                          className={
                            previousOrdersClick
                              ? previousOrdersClick.includes(row.id)
                                ? classes.alreadyClicked
                                : null
                              : null
                          }
                        >
                          <TableCell>{moment(row.date).format("ll")}</TableCell>
                          <TableCell className={classes.orderId}>
                            <span
                              onClick={(e) => handleEnquiryDetails(e, row.id)}
                            >
                              #{row.id}
                            </span>
                            <ViewComponent
                              res={res}
                              setRes={setRes}
                              row={row}
                            />
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          {/* <TableCell>{row.email}</TableCell> */}
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>{row.category_name}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => handleNotesOpen(e, row.id)}
                            >
                              {notesLoading && id === row.id ? (
                                <CircularProgress
                                  className={classes.loading}
                                  size={20}
                                  thickness={4}
                                />
                              ) : (
                                <ChatOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <EnquiryStatus
                              row={row}
                              res={res}
                              setRes={setRes}
                              changedEnquiryStatus={changedEnquiryStatus}
                              setChangedEnquiryStatus={setChangedEnquiryStatus}
                            />
                          </TableCell>
                          <TableCell>
                            <EnquiryAssign
                              row={row}
                              res={res}
                              setRes={setRes}
                              changedEnquiryAssign={changedEnquiryAssign}
                              setChangedEnquiryAssign={setChangedEnquiryAssign}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              ) : (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography>No Enquiry(s) Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={count > 0 ? page : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <EnquiryNotes
          handleClose={handleNotesClose}
          handleOpen={handleNotesOpen}
          notes={notes}
          open={openNotes}
          setOpenNotes={setOpenNotes}
          id={id}
        />
      </Box>
    </Layout>
  );
}
