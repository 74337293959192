import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getOrderDetails, getShippment } from "../api";
import { makeStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";

const useStyles = makeStyles((theme) => ({
  dialogBoxPadding: {
    padding: "10px 25px !important",
  },
  flexcenter: {
    padding: "0.25%",
    maxWidth: "700px",
    margin: "0 auto",
    fontFamily: "Arial, Helvetica, sans-serif",
    "& header": {
      display: "flex",
      alignItems: "center",
      background: "#fad022",
      borderRadius: "3px",
      overflow: "hidden",
    },
    "& header div": {
      flex: "1",
    },
    "& header div:nth-child(2)": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& img": {
      maxWidth: "100%",
    },
    "& header div:nth-child(2) img": {
      maxWidth: "124px",
    },
  },
  mainwrap: {
    display: "flex",
    margin: "15px 0 0",
    gap: "10px",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  flexLeft: {
    width: "82%",
  },
  flexRight: {
    width: "18%",
  },
  flexleftinner: {
    border: "5px solid #e5dbcc",
  },
  toaddress: {
    fontWeight: "500",
    fontSize: "22px",
    padding: "5px",
    background: "#FFF",
    minHeight: "200px",
  },
  packaging: { display: "flex" },
  packagingdiv: {
    flex: "1",
    textAlign: "center",
    fontSize: "18px",
    letterSpacing: "0.60px",
    padding: "5px",
    borderRight: "5px solid #e5dbcc",
  },
  packaging: {
    display: "flex",
    backgroundColor: "#FFF",
    borderTop: "5px solid #e5dbcc",
    borderBottom: "5px solid #e5dbcc",
    "& div": {
      flex: "1",
      textAlign: "center",
      fontSize: "18px",
      letterSpacing: "0.60px",
      padding: "5px",
      borderRight: "5px solid #e5dbcc",
    },
    "&  div span": {
      display: "block",
      fontSize: "26px",
      fontWeight: "600",
      margin: "4px 0",
    },
    "& div:nth-child(3)": { border: "0" },
  },
  phone: {
    padding: "5px",
    borderBottom: "5px solid #e5dbcc",
    backgroundColor: "#FFF",
    fontSize: "24px",
  },
  ref: {
    padding: "5px",
    fontSize: "24px",
    backgroundColor: "#FFF",
  },
  from: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    "& > div": { flex: "1" },
    "div:nth-child(2)": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& div:first-child": {
      fontSize: "18px",
    },
  },
  fromWrap: {
    backgroundColor: "#FFF",
    border: "5px solid #e5dbcc",
    marginTop: "10px",
  },
  fromInner: {
    padding: "5px",
    fontSize: "14px",
    borderBottom: "5px solid #e5dbcc",
    margin: "0 0 4px",
  },
  signHere: {
    padding: "5px",
    "& p": {
      margin: "0 0 3px",
    },
  },
  sign: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      flex: "50%",
    },
    "& div:first-child": {
      flex: "90%",
      fontWeight: "600",
    },
    "& div:nth-child(2)": {
      display: "flex",
      justifyContent: "flex-end",
      flex: "10%",
    },
    "& div:nth-child(2) img": {
      maxWidth: "40px",
    },
    "& div:first-child span": {
      display: "inline-block",
      letterSpacing: "5px",
    },
  },
  qr1: {
    paddingLeft: "22px",
  },
  postagepaid: {
    fontSize: "17px",
    fontWeight: "600",
    letterSpacing: "1px",
    textAlign: "right",
    margin: "0 0 10px",
  },
  avaiationsecurity: {
    color: "#d81e05",
    marginBottom: "15px !important",
  },
  largefont: {
    fontSize: "14px",
  },
}));

const LoadAusPostLabel = (props) => {
  const [orderdata, setOrderData] = useState({});
  const { handleCloseLabel, openLabel, printData, orderid } = props;
  const classes = useStyles();
  const componentRef = useRef();
  let totalWeight = 0;
  useEffect(async () => {
    if (printData) {
      const getOrder = await getShippment(printData.shipments[0].shipment_id);
      if (getOrder) {
        setOrderData(getOrder);
      }
      if (orderdata.items) {
        orderdata.items.map((item) => {
          totalWeight += item.weight;
        });
      }
    }
  }, []);
  let printDatas = {
    order: {
      order_id: "AP0000002422",
      order_reference: "My order reference",
      order_creation_date: "2014-08-27T13:55:47+10:00",
      order_summary: {
        total_cost: 13.29,
        total_cost_ex_gst: 12.08,
        total_gst: 1.21,
        status: "Initiated",
        tracking_summary: {
          Sealed: 1,
        },
        number_of_shipments: 1,
        number_of_items: 1,
        dangerous_goods_included: false,
        total_weight: 5.0,
        shipping_methods: {
          "3H03": 1,
        },
      },
      shipments: [
        {
          shipment_id: "569b9a3accdc791c4ba34d6f",
          shipment_reference: "XYZ-001-01",
          shipment_creation_date: "2014-08-27T13:55:47+10:00",
          email_tracking_enabled: false,
          items: [
            {
              weight: 5.0,
              authority_to_leave: true,
              safe_drop_enabled: true,
              allow_partial_delivery: true,
              item_id: "NfMK1UnLtDcAAAFyJ1IF297V",
              item_reference: "the reference",
              tracking_details: {
                article_id: "2JD541813301000870900",
                consignment_id: "2JD5418133",
                barcode_id:
                  "0199312650999998912JD541813301000870900|4200221|8008200703142616",
              },
              product_id: "3H03",
              item_summary: {
                total_cost: 13.29,
                total_cost_ex_gst: 12.08,
                total_gst: 1.21,
                status: "Sealed",
              },
            },
          ],
          shipment_summary: {
            total_cost: 13.29,
            total_cost_ex_gst: 12.08,
            fuel_surcharge: 0.0,
            total_gst: 1.21,
            status: "Sealed",
            tracking_summary: {
              Sealed: 1,
            },
            number_of_items: 1,
          },
          movement_type: "DESPATCH",
          charge_to_account: "0000000000",
          shipment_modified_date: "2014-08-27T13:55:47+10:00",
        },
      ],
      payment_method: "CHARGE_TO_ACCOUNT",
    },
  };
  return (
    <>
      <Dialog
        open={openLabel}
        onClose={handleCloseLabel}
        aria-labelledby="form-dialog-title"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={handleCloseLabel}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <div className={classes.flexcenter} ref={componentRef}>
          <header>
            <div>
              {" "}
              <img src="/express-post.jpg" alt="" />
            </div>
            <div>
              {" "}
              <img src="/mypost.jpg" alt="" />
            </div>
          </header>
          <div className={classes.mainwrap}>
            <div className={classes.flexLeft}>
              <div className={classes.flexleftinner}>
                <div className="to-address">
                  To: <br />
                  {orderdata.labelstatus
                    ? orderdata.labelstatus.shipments[0].to.name
                    : ""}
                  <br />
                  {orderdata.labelstatus
                    ? orderdata.labelstatus.shipments[0].to.line[0]
                    : ""}
                  <br />
                  {orderdata.labelstatus
                    ? orderdata.labelstatus.shipments[0].to.suburb
                    : ""}{" "}
                  {orderdata.labelstatus
                    ? orderdata.labelstatus.shipments[0].to.state
                    : ""}{" "}
                  {orderdata.labelstatus
                    ? orderdata.labelstatus.shipments[0].to.postcode
                    : ""}
                </div>
                <div className={classes.packaging}>
                  <div>
                    <p>Packaging</p>
                    <span>OWN</span>
                  </div>
                  <div>
                    <p>Physical weight</p>
                    <span>{totalWeight}g</span>
                    <p>Cubic weight</p>
                    <span>N/A</span>
                  </div>
                  <div>
                    <p>Delivery features</p>
                  </div>
                </div>
                <div className={classes.phone}>Ph: 0403255621</div>
                <div className={classes.ref}>Ref: Printyo - 34558</div>
              </div>
              <div className={classes.fromWrap}>
                <div className={classes.fromInner}>
                  <div className={classes.from}>
                    <div>From:</div>
                    <div>61719473</div>
                  </div>
                  Printyo <br />
                  Suny Khurana <br />
                  THOMASTOWN VIC 3074
                </div>
                <div className={classes.signHere}>
                  <p className={classes.avaiationsecurity} sx={{ mb: 15 }}>
                    Aviation Security and Dangerous Goods Declaration*
                  </p>
                  <div className={classes.sign}>
                    <div>
                      Sign here:{" "}
                      <span>---------------------------------------</span>
                    </div>
                    <div>
                      <img src="/handwrite.jpg" alt="" />
                    </div>
                  </div>
                  <p className={classes.largefont}>
                    *I, the sender, acknowledge that this article may be carried
                    by air and will be subject to aviation security and clearing
                    procedures, and I declare that it does not contain any
                    dangerous or prohibited goods, explosive or incendiary
                    devices. A false declaration is a criminal offence.
                  </p>
                  <p className={classes.largefont}>
                    <strong>
                      If this declaration is not signed this article cannot be
                      carried by air.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.flexRight}>
              <p className={classes.postagepaid}>Postage Paid</p>
              <div className={classes.qr1}>
                <img src="/qr-code1.jpg" alt="" />
              </div>
              <div>
                {orderdata.shipments ? (
                  <Barcode
                    value={
                      orderdata.shipments.items[0].tracking_details.barcode_id
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 5, mb: 5 }}
        >
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" size="small" color="primary">
                Print this label!
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default LoadAusPostLabel;
