import React, { useState, useCallback } from "react";
import { Button, Box, makeStyles, } from "@material-ui/core";
import ConfirmedStatusPopup from "../ConfirmedStatusPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  popupcontainer: {
    ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
      boxShadow: "none",
    },
  },
}));

const OrderStatusForFullfilled = ({ row }) => {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConfirmedModal, setOpenConfiremdModal] = useState(false);
  const [status, setStatus] = useState("");

  // const handleOpenMenu = useCallback(
  //   (event) => {
  //     setAnchorEl(anchorEl ? null : event.currentTarget);
  //   },
  //   [anchorEl]
  // );

  // const handleCloseMenu = useCallback(() => {
  //   setAnchorEl(null);
  // }, []);

  const handleCloseConfirmedModal = useCallback((e, id) => {
    setOpenConfiremdModal(false);
  }, []);

  // const handleStatusChange = useCallback(
  //   (status) => {
  //     console.log(status, row.id);
  //     setStatus(status);
  //     setOpenConfiremdModal(true);
  //     setAnchorEl(null);
  //   },
  //   [row.id]
  // );

  return (
    <>
      <ConfirmedStatusPopup
        handleClose={handleCloseConfirmedModal}
        open={openConfirmedModal}
        id={row.id}
        status={status}
        setStatus={setStatus}
      />
      <Box
        display="flex"
        alignItems="center"
        className={classes.popupcontainer}
      >
        <Button
          variant="contained"
          size="small"
          className={
            row.status === "processing"
              ? classes.processing
              : row.status === "completed"
              ? classes.completed
              : row.status === "on-hold"
              ? classes.hold
              : row.status === "cancelled" || row.status === "pending"
              ? classes.cancelled
              : classes.cancelled
          }
        >
          {row.status === "fullfilled" ? "Send To Print" : row.status}
        </Button>
      </Box>
    </>
  );
};

export default OrderStatusForFullfilled;
