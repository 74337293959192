import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import mailTemplate1 from "../mailTemplate";
import mailTemplate2 from "../mailTemplate2";
import artworkMissingTemplate from "../Artworkmissingtemplate";
import orderMockupTemplate from "../orderMockupTemplate";
import ReviewMailTemplate from "../ReviewMailTemplate";
import ReviewForMockup from "../ReviewForMockup";
import orderMockupBlank from "../orderMockupBlank";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    marginTop: "1rem",
  },
  heading: {
    fontSize: "14px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
    marginBottom: "1rem",
  },
  btn: {
    textTransform: "none",
    marginLeft: "10px",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  paper: {
    padding: "1rem",
  },
  content: {
    color: "#777",
    lineHeight: "1.6em",
    fontSize: "14px",
    marginTop: "2px",
  },
  comment: {
    backgroundColor: "#d7cad2",
    padding: "10px",
    position: "relative",
    lineHeight: "1.4",
    fontSize: "13px",
    color: "#3c434a",
  },
  commentDesign: {
    position: "absolute",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid #d7cad2",
    bottom: "-10px",
    left: "20px",
  },
  field: {
    marginTop: "10px",
  },
  textField: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: "#2c3338",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  menu: {
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "14px",
  },
}));
const PreviewTemplate = ({ handleClose, open, template, order, msg }) => {
  const classes = useStyles();

  function createMarkup(msg, order, template) {
    let data = "";
    if (template === "template1") {
      data = mailTemplate1(order, msg);
    } else if (template === "template2") {
      data = mailTemplate2(order, msg);
    } else if (template === "template3") {
      data = artworkMissingTemplate(order, msg);
    } else if (template === "template4") {
      data = ReviewMailTemplate(order, msg);
    } else if (template === "template5") {
      data = ReviewForMockup(order, msg);
    } else if (template === "orderMockupTemplate") {
      data = orderMockupTemplate(order, msg);
    } else if (template === "orderMockupBlankTemplate") {
      data = orderMockupBlank(order, msg);
    }
    return { __html: data };
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Preview Template</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 400 }}>
        <Box>
          <div
            dangerouslySetInnerHTML={createMarkup(msg, order, template)}
          ></div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewTemplate;
