import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@material-ui/core";
import { getShippingPriceForOrders } from "../../api";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "../../components/Layout";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell>Order ID</TableCell>
        <TableCell>Shipping Price</TableCell>
        <TableCell>Label Price</TableCell>
        <TableCell>Tracking Id/Article Id</TableCell>
        <TableCell>Created At</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 850,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  secBox: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  shippingcharged: {
    color: "green",
    marginBottom: "10px",
    fontWeight: "600",
  },
  shippingpaid: {
    color: "red",
    marginBottom: "10px",
    fontWeight: "600",
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [shippinglists, setShippingLists] = useState([]);
  const [shippinglistcount, setShippingistCount] = useState(0);
  const [rowData, setRowData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(20);

  var totalshippingcharged = 0;
  var totalshippingpaid = 0;

  useEffect(() => {
    const getShippingLists = async () => {
      try {
        setLoading(true);
        const data = await getShippingPriceForOrders(page + 1, rowsPerPage);
        setShippingLists(data.shippinglists);
        setShippingistCount(data.totalcount);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getShippingLists();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        {shippinglists.map((row, index) => {
          const price = JSON.parse(row.shippment_summary);
          totalshippingpaid += price.total_cost;
          if (!row.website_shipping_price) {
            row.website_shipping_price = 0;
          }
          totalshippingcharged += parseInt(row.website_shipping_price);
        })}
        <Box display="flex" alignItems="center" mb={3}>
          <Typography className={classes.heading}>Shipping Price</Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            flexDirection: "column",
          }}
          alignItems="center"
          mb={3}
        >
          <Typography
            xs={12}
            variant="h6"
            component="h6"
            className={classes.shippingcharged}
          >
            Total Shipping Charged: ${totalshippingcharged}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            className={classes.shippingpaid}
          >
            Total Shipping Paid: ${totalshippingpaid}
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                rowCount={shippinglists.length}
              />
              <TableBody>
                {shippinglists.map((row, index) => {
                  const price = JSON.parse(row.shippment_summary);
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell>#{row.order_id}</TableCell>
                      <TableCell>
                        {row.website_shipping_price
                          ? `$ ${row.website_shipping_price}`
                          : "N/A"}
                      </TableCell>
                      <TableCell>${price.total_cost}</TableCell>
                      <TableCell>
                        <a
                          href={`https://auspost.com.au/mypost/beta/track/details/${row.article_id}`}
                          target="_blank"
                        >
                          {row.article_id}
                        </a>
                      </TableCell>
                      <TableCell>{`${moment(row.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}.`}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 35, 50, 100]}
            component="div"
            count={shippinglistcount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Layout>
  );
}
