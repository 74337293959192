import { CssBaseline } from "@material-ui/core";
import Routes from "./Routes";
import Offline from "./components/Offline";
import * as amplitude from "@amplitude/analytics-browser";

function App() {
  const auth_email = localStorage.getItem("auth_email");
  amplitude.init("6682390c75ac451d8200ffd355d68917", auth_email, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });

  const networkstatus = navigator.onLine ? <Routes /> : <Offline />;
  return (
    <>
      <CssBaseline />
      {networkstatus}
    </>
  );
}

export default App;
/* <Router>
  <CssBaseline />
  <Box display='flex'>
    <SideBar />
    <Box display='flex' flexDirection='column' mt="63px" flexGrow={1}>
      <Appbar />
      <Routes />
    </Box>
  </Box>
</Router> */
