import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  Paper,
  Stack,
  Container, Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
  marginBottom: "1rem",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "400",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    // margin: "0",
    // fontWeight: "400",
    // letterSpacing: "0.00938em",
    // fontSize: "13px",
    // lineHeight: "1.4em",
  },
  title: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  container: {
    paddingRight: "0 !important",
    paddingLeft: "0 !important",    
  },
  orderDetailTitle: {
    [theme.breakpoints.down("xs")]: {
      flex: "unset !important",
    },
  },
  orderDetailsNotes: {
    "& .MuiContainer-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiButton-root": {
      display: "none",
    },
    "& .MuiTypography-root": {
      margin: "0",
    },
    "& .MuiPaper-root": {
      width: "100%",
      marginTop: "24px",
    },
  },
}));

const Dashboard = () => {  
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  // Chart

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [35, 39, 50, 51, 56, 55, 40],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [65, 59, 90, 81, 56, 55, 40],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};
  

  return (
    <>
      {loading && <LinearProgress />}
      <Container className={classes.container}>
        <Paper sx={{ padding: "1rem" }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              marginBottom: "1rem",
            }}
          >
            Dashboard
          </Typography>
          <Divider />
          <Line options={options} data={data} />
        </Paper>
      </Container>
    </>
  );
};

export default Dashboard;
