import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { changeFullfilledDate } from "../../api";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider, makeStyles } from "@material-ui/core";
import TrackEvents from "../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "210px",
      padding: "16px !important",
    },
  },
}));

const ConfirmationPopup = (props) => {
  const classes = useStyles();
  const { id, handleClose, open, res, setRes, dispatchdate } = props;
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [date, setDate] = useState(dispatchdate);
  const [error, setError] = useState("");
  const userid = localStorage.getItem("auth_id");
  const handleConfirmed = async () => {
    setError("");
    if (!id || !msg) {
      setError("Please type message while submitting the form");
      return;
    }
    const datevalue = moment(date).format("YYYY-MM-DD");
    const data = new FormData();
    data.append("orderstatus", "fullfilled");
    data.append("customdate", datevalue);
    data.append("message", msg);
    data.append("userid", userid);
    try {
      setLoading(true);
      let result = await changeFullfilledDate(id, data);
      if (result.status === 200) {
        setRes(!res);
        setMsg("");
        TrackEvents(`${id}`, {
          process: "Changed FullFilled Date",
        });
      } else {
        alert("oops something went wrong");
      }
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleClose();
    }
  };

  const handleChange = (e) => {
    setMsg(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TypographyHeading>Dispatch Date</TypographyHeading>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <TypographySubHeading sx={{ color: "red" }}>
          {error}
        </TypographySubHeading>
        <TypographySubHeading>
          Do you want to change the Sent To Print date of order id #{id}{" "}
        </TypographySubHeading>
        <Box my={2}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="Date&Time picker"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </LocalizationProvider>
        </Box>
        <TextField
          type="text"
          value={msg}
          onChange={handleChange}
          rows={2}
          multiline
          size="small"
          sx={{ mt: 2 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          variant="contained"
          size="small"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing" : "Confirmed"}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
