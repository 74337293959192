import React, { useCallback, useState } from "react";
import {
  DialogTitle,
  Dialog,
  IconButton,
  Stack,
  DialogContent,
  TextField,
  Box,
  Button,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
        width: "100%",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "unset !important",
    },
  },
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));

const AddCustomProduct = (props) => {
  const classes = useStyles();
  const { openModal, setOpenModal, products, setProducts } = props;
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    id: 1,
    name: "",
    price: "",
    quantity: 0,
    description: "",
    categories: ["custom"],
  });

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleAddCustomProducts = () => {    
    let total = 0;
    const qty = fields.quantity;
    let detail = {
      "product-name": fields.name,
    };
    const attributes = fields.description.split("|");
    attributes.forEach((attribute, i) => {
      const breakvalues = attribute.split(":");
      detail[breakvalues[0]] = breakvalues[1];
    });
    detail["custom-product-order"] = "yes";
    if (qty > 0) {
      setLoading(true);
      total = total + fields.price * qty;
      setProducts([
        ...products,
        {
          product: fields,
          qty,
          attributes: detail,
          id: fields.id,
          total,
          price: fields.price,
        },
      ]);      
      setLoading(false);
      setOpenModal(false);
      setFields({});
    }
  };

  return (
    <Dialog onClose={handleClose} open={openModal} className={classes.modal}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Add custom product</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Box minWidth={400} className={classes.fullWidth}>
          <Box>
            <TextField
              size="small"
              label="Product Name"
              placeholder="Product Name"
              name="name"
              type="text"
              fullWidth
              onChange={handleChange}
              value={fields.name}
              sx={{ mb: "16px" }}
            />
            <TextField
              size="small"
              label="Price per product"
              placeholder="Price per product"
              name="price"
              type="number"
              fullWidth
              onChange={handleChange}
              value={fields.price}
              sx={{ mb: "16px" }}
            />
            <TextField
              size="small"
              label="Qunatity"
              placeholder="Qunatity"
              name="quantity"
              type="number"
              fullWidth
              onChange={handleChange}
              value={fields.quantity}
              sx={{ mb: "16px" }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Attributes"
              multiline
              minRows={4}
              fullWidth
              name="description"
              onChange={handleChange}
              value={fields.description}
              sx={{ mb: "16px" }}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleAddCustomProducts}
              disabled={loading}
              >
                {loading ? "Adding" : "Add"}              
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomProduct;
