import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { Stack, TextField, InputAdornment } from "@mui/material";
import { getAllCustomers } from "../../api";
import LinearProgress from "@material-ui/core/LinearProgress";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Layout from "../../components/Layout";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell>User Id</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Phone no.</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customercount, setCustomerCount] = useState(0);
  const [searchvalue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const getCustomers = async () => {
      try {
        setLoading(true);
        const data = await getAllCustomers(page + 1, 20, search);
        setCustomers(data.response);
        setLoading(false);
        setCustomerCount(data.count);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getCustomers();
  }, [page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
    setPage(0);
  };

  //Handle Search
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={`secBox`}
      >
        <Box display="flex" alignItems="center" mb={3}>
          <Typography className="heading">Customers</Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="outlined-start-adornment"
              className={`searchinput`}
              sx={{
                m: 1,
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="Search Customer"
              onChange={handleSearch}
              value={searchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
        <Paper className={`paper`}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table
              className={`table custom-table`}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                rowCount={customercount && customercount.length}
              />
              <TableBody>
                {customers ? (
                  customers.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell>{row.user_id}</TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          className={`orderId`}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>{row.user_email}</TableCell>
                        <TableCell>{row.billing_phone}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow hover tabIndex={-1} key={0}>
                    <TableCell colSpan={4} align="center">
                      No Customer(s) Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            component="div"
            count={customercount}
            labelRowsPerPage=""
            rowsPerPage={20}
            page={page}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </Layout>
  );
}
