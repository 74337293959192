import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TrackEvents from "../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  modalContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "0 0 16px !important",
    },
  },
}));

const OrderInfo = (props) => {
  const {
    id,
    openModel,
    handleClose,
    payment_method_title,
    billing,
    shipping,
    shipping_lines,
    line_items,
    currency_symbol,
    total,
    shipping_total,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Order #{id}</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Box>
          <Box display="flex" flexWrap="wrap">
            <Box className={classes.fullWidth}>
              <Typography className={classes.heading}>
                Billing details
              </Typography>
              <Typography className={classes.typography}>
                {billing
                  ? `${billing.first_name} ${billing.last_name} ${billing.address_1} ${billing.address_2} ${billing.city} ${billing.state} ${billing.country} ${billing.postcode}`
                  : ""}
              </Typography>
              <Typography className={classes.heading}>Email</Typography>
              <Typography className={classes.typography}>
                {billing ? billing.email : ""}
              </Typography>
              <Typography className={classes.heading}>Phone</Typography>
              <Typography className={classes.typography}>
                {billing ? billing.phone : ""}
              </Typography>
              <Typography className={classes.heading}>Payment via</Typography>
              <Typography className={classes.typography}>
                {payment_method_title ? payment_method_title : ""}
              </Typography>
            </Box>
            <Box ml={2} className={classes.fullWidth}>
              <Typography className={classes.heading}>
                Shipping details
              </Typography>
              <Typography className={classes.typography}>
                {shipping
                  ? `${shipping.first_name} ${shipping.last_name} ${shipping.address_1} ${shipping.address_2} ${shipping.city} ${shipping.state} ${shipping.country} ${shipping.postcode}`
                  : ""}
              </Typography>
              <Typography className={classes.heading}>
                Shipping method
              </Typography>
              <Typography className={classes.typography}>
                {shipping_lines ? shipping_lines[0].method_title : ""}
              </Typography>
            </Box>
          </Box>
          <Grid container className={classes.margin}>
            <Grid item sm={8} xs={6}>
              <Typography className={classes.heading}>Product</Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
              <Typography
                className={classes.heading}
                style={{ textAlign: "center" }}
              >
                Quantity
              </Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
              <Typography
                className={classes.heading}
                style={{ textAlign: "center" }}
              >
                Total
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.margin} />
          {line_items
            ? line_items.map((item) => {
                return (
                  <Grid container key={item.id}>
                    <Grid item sm={8} xs={6}>
                      <Typography className={classes.typography}>
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} xs={3}>
                      <Typography
                        className={classes.typography}
                        style={{ textAlign: "center" }}
                      >
                        {item.quantity}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} xs={3}>
                      <Typography
                        className={classes.typography}
                        style={{ textAlign: "center" }}
                      >
                        {currency_symbol} {item.total}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null}
          <Grid container>
            <Grid item sm={10} xs={9}>
              <Typography className={classes.typography}>
                Shipping price
              </Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
              <Typography
                className={classes.typography}
                style={{ textAlign: "center" }}
              >
                {" "}
                {currency_symbol} {shipping_total}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={10} xs={9}>
              <Typography className={classes.heading}>Total price</Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
              <Typography
                className={classes.heading}
                style={{ textAlign: "center" }}
              >
                {" "}
                {currency_symbol} {total}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderInfo;
