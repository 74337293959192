import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Container,
  TablePagination,
  LinearProgress,
  Box,
} from "@material-ui/core";
import {
  Typography,
  Button,
  Stack,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { getInventoryProductType, AddInventoryProductType } from "../../api";
import moment from "moment";
import AddproductType from "./AddproductType";
import Layout from "../../components/Layout";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeletePopup from "./DeletePop";
import Editproduct from "./EditproductType";
import SearchIcon from "@mui/icons-material/Search";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell> Product type</TableCell>
        <TableCell>Qty</TableCell>
        <TableCell>Updated at</TableCell>
        <TableCell>Added by</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Edit</TableCell>
        <TableCell>Log</TableCell>
        <TableCell>Delete</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: "1rem",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    color: "#c6e1c6",
  },
  searchInputBox: {
    marginLeft: "auto !important",
  },
  productsizes: {
    marginRight: "5px",
    fontSize: "11px",
    fontStyle: "italic",
    color: "rgb(0 0 0 / 60%)",
    "&:last-child .comma": {
      display: "none",
    },
  },
  anchorlink: {
    color: "#2271b1",
    cursor: "pointer",
  },
}));
// Edit product

const EditComponent = ({
  loading,
  productInfo,
  handleChange,
  res,
  setRes,
  id,
}) => {
  const [openEditPop, setEditPop] = useState(false);

  const handleCloseEditPop = () => {
    setEditPop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton onClick={() => setEditPop(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
      {openEditPop ? (
        <Editproduct
          openModel={openEditPop}
          handleClose={handleCloseEditPop}
          id={id}
          res={res}
          setRes={setRes}
          handleChange={handleChange}
          productInfo={productInfo}
          loading={loading}
        />
      ) : null}
    </>
  );
};

// Delete product
const DeleteComponent = ({ id, res, setRes, ProductTypeName }) => {
  const [openDeletepop, setDeletePop] = useState(false);

  const handleCloseDeletePop = () => {
    setDeletePop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton onClick={() => setDeletePop(true)}>
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <DeletePopup
        open={openDeletepop}
        handleClose={handleCloseDeletePop}
        res={res}
        setRes={setRes}
        id={id}
        ProductTypeName={ProductTypeName}
      />
    </>
  );
};

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

const Inventory = () => {
  const classes = useStyles();
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [selectCheckbox, setSelectCheckbox] = useState();
  const [productInfo, setProductInfo] = useState({
    message: "",
    quantity: 0,
    producttype: "",
  });

  const [inputList, setInputList] = useState([{ name: "", quantity: "" }]);
  const currentPageId = parseInt(getTheCurrentUrl());
  const [page, setPage] = React.useState(currentPageId);
  const [count, setCount] = useState(0);

  const currentRowsPerPage = localStorage.getItem("inventoryRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(currentRowsPerPage) ? parseInt(currentRowsPerPage) : 10
  );
  localStorage.setItem("inventoryNewPage", currentPageId + 1);

  // Search
  const [search, setSearch] = useState("");
  const [searchvalue, setSearchValue] = React.useState("");
  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setPage(0);
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("inventoryNewPage");
    } else {
      localStorage.setItem("inventoryNewPage", e.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/inventory/${newPage + 1}`,
    });
  };

  //
  const handleOpen = () => {
    setOpenModel(true);
    setSelectCheckbox();
  };

  const handleClose = () => {
    setOpenModel(false);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", quantity: "" }]);
  };

  const userid = localStorage.getItem("auth_id");
  const handleNavigation = useCallback(
    (id) => {
      history.push(`/inventory/${id}/details/1`);
    },
    [history]
  );

  const handleUpdateProductNavigation = useCallback(
    (id) => {
      history.push(`/inventory/${id}/update-producttype`);
    },
    [history]
  );

  const handleChange = (e) => {
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
  };

  const handleAddProduct = async () => {
    var data = new FormData();
    data.append("message", productInfo.message);
    data.append("quantity", productInfo.quantity);
    data.append("producttype", productInfo.producttype);
    data.append("userid", userid);
    data.append("issubsizecategory", selectCheckbox ? "yes" : "no");
    inputList.forEach((item, i) => {
      data.append(`subsizecategory[${i}][name]`, item.name);
      data.append(`subsizecategory[${i}][quantity]`, item.quantity);
    });
    setLoading(true);
    //console.log(Object.fromEntries(data));
    try {
      const response = await AddInventoryProductType(data);
      if (response.status === 200) {
        setProductInfo({ message: "", quantity: 0, producttype: "" });
        setInputList([{ name: "", quantity: 0 }]);
      }
      setRes(!res);
      setLoading(false);
      handleClose();
      setSelectCheckbox();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from add producttype", error);
      setSelectCheckbox();
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setWaiting(true);
        const data = await getInventoryProductType(
          page + 1,
          rowsPerPage,
          search
        );
        if (data.status === 200) {
          setProductList(data.producttype);
          console.log(data);
          setCount(data.countproducttype);
        }
        setWaiting(false);
      } catch (error) {
        console.log("error form inventory", error);
      }
    };
    fetchProduct();
  }, [page, res, rowsPerPage, search]);

  return (
    <Layout>
      <Container>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontSize: "23px",
              fontWeight: "400",
              lineHeight: "1.5em",
              my: 2,
            }}
          >
            Inventory
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: "none" }}
            onClick={handleOpen}
          >
            Add product type
          </Button>
          <Stack
            direction="row"
            justifyContent="flex-end"
            className={classes.searchInputBox}
          >
            <form onSubmit={handleSearchSubmit}>
              <TextField
                id="outlined-start-adornment"
                className={classes.searchInput}
                sx={{
                  width: "30ch",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                }}
                size="small"
                placeholder="search"
                onChange={handleSearch}
                value={searchvalue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon onClick={handleSearchSubmit} />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Stack>
        </Stack>
        <AddproductType
          openModel={openModel}
          handleClose={handleClose}
          handleChange={handleChange}
          productInfo={productInfo}
          handleAddProduct={handleAddProduct}
          loading={loading}
          selectCheckbox={selectCheckbox}
          setSelectCheckbox={setSelectCheckbox}
          handleInputChange={handleInputChange}
          inputList={inputList}
          handleAddClick={handleAddClick}
          handleRemoveClick={handleRemoveClick}
        />
        <Paper className={classes.paper}>
          {waiting && <LinearProgress />}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              <TableBody>
                {productList?.map((product, index) => {
                  let date = moment(product.updated_at).format("ll");

                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={product.id}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            value={product.id}
                            // inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell> */}
                        <TableCell>{product.id}</TableCell>
                        <TableCell style={{ maxWidth: "200px" }}>
                          <a
                            className={classes.anchorlink}
                            onClick={() =>
                              handleUpdateProductNavigation(product.id)
                            }
                          >
                            {product.producttype}
                            <br />
                            {product.sizecategories?.map((item, index) => {
                              return (
                                <>
                                  <span className={classes.productsizes}>
                                    <span>
                                      {item.producttypesizecatname} -{" "}
                                    </span>
                                    <span>
                                      {item.quantity}{" "}
                                      <span className="comma">,</span>
                                    </span>
                                  </span>
                                </>
                              );
                            })}
                          </a>
                        </TableCell>
                        <TableCell>{product.quantity}</TableCell>
                        <TableCell>{date}</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleNavigation(product.id)}
                          >
                            <VisibilityOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <EditComponent
                            id={product.id}
                            res={res}
                            setRes={setRes}
                            handleChange={handleChange}
                            productInfo={productInfo}
                            loading={loading}
                          />
                        </TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <DeleteComponent
                            res={res}
                            setRes={setRes}
                            id={product.id}
                            ProductTypeName={product.producttype}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {waiting && <LinearProgress />}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={count > 0 ? page : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </Layout>
  );
};

export default Inventory;
