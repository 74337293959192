import React, { useState } from "react";
import Layout from '../../components/Layout';
import { Container, Typography, Box, Button, TextField, Paper } from "@mui/material";
import { makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    profileSec: {
        width: '100%',
        height: 'calc(100vh - 65px)',
    },
    profileBox: {
        padding: "24px",
        width: "100%",
        maxWidth: "500px",
    },
    profileEmailBox: {
        padding: "8.5px 14px",
        marginBottom: "20px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        cursor: "not-allowed",
    }
}))

const Profile = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [profileInfo, setProfileInfo] = useState({
        name: "test",
        phone: "9876543210",
    });

    const [nameValError, setNameValError] = useState({
        helperText: "",
        error: false,
    });

    const [phoneValError, setPhoneValError] = useState({
        helperText: "",
        error: false,
    });
    
    const handleChange = (e) => {
        setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
    };     
      
    const handleAddProfile = async () => {
        setLoading(true);
        setNameValError({ helperText: "", error: false });
        if (profileInfo.name.length < 2) {
            setNameValError({ helperText: "Please enter name", error: true });
            return;
        }

        setPhoneValError({ helperText: "", error: false });
        if (profileInfo.phone.length < 8) {
            setPhoneValError({ helperText: "Please enter valid phone number", error: true });
            return;
        }
        setLoading(false);
    };

    return (
        <Layout>
            <Container>
            <Box className={classes.profileSec} display='flex' alignItems='center' justifyContent='center'>
                <Paper className={classes.profileBox} >
                    <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "1.5em",
                        mb: 2,
                    }}
                    >
                    Profile
                    </Typography>
                    <TextField
                    required  
                    helperText={nameValError.helperText}
                    error={nameValError.error}                      
                    size="small"
                    label="Name"
                    name="name"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    value={profileInfo.name} style={{ marginBottom: "20px" }}
                />
                <TextField
                    required  
                    helperText={phoneValError.helperText}
                    error={phoneValError.error}                      
                    size="small"
                    label="Phone"
                    name="phone"
                    type="number"
                    fullWidth
                    onChange={handleChange}
                    value={profileInfo.phone} style={{ marginBottom: "20px" }}
                />
                <TextField                                           
                    size="small"
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    disabled={true}
                    value={"test@gmail.com"} style={{ marginBottom: "20px" }}
                />
                <Button onClick={handleAddProfile} disabled={loading} variant="contained" size="small">Update</Button>
                </Paper>
            </Box>
            </Container>
        </Layout>
    )
}

export default Profile
