import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  Divider,
  Button,
  Link,
} from "@mui/material";
import {
  TypographyHeading,
  TypographySubHeading,
} from "../../../styles";
import { getPrintTabNotes } from "../../../api";
import moment from "moment";
import Note from "./Note";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
    },
  },
  printFileLink: {
    wordBreak: "break-word",
  }
}));

const PrintNotes = ({ id }) => {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState(false);

  const handleNote = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      const data = await getPrintTabNotes(id);
      setNotes(data.ordersnote);
    };
    if (id) {
      fetchNotes();
    }
  }, [id, res]);
  return (
    <Container className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TypographyHeading>Print Notes</TypographyHeading>
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={handleNote}
        >
          Add Print Files
        </Button>
      </Box>
      {open && <Note setOpen={setOpen} id={id} setRes={setRes} />}
      <Paper sx={{ p: 3, mt: 4, width: "70vw" }} className={classes.fullWidth}>
        <Typography>Notes</Typography>
        <Divider />
        {notes && notes.length > 0 ? (
          notes.map((item) => {
            return (
              <Box key={item.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  <Box display="flex" alignItems="center">
                    <Link
                      href={item.printfile.printfiles}
                      target="_blank"
                      color="#3c434a"
                      className={classes.printFileLink}
                    >
                      {item.printfile.printfiles}
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {moment(item.printfile.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                  <span>
                    {" "}
                    By {item.printfileuser ? item.printfileuser.name : ""}
                  </span>
                </Box>
              </Box>
            );
          })
        ) : (
          <TypographySubHeading>No Note found</TypographySubHeading>
        )}
      </Paper>
    </Container>
  );
};

export default PrintNotes;
