import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UpdateUsersRole, getUsersRoleById } from "../../api";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  formGroup: {
    flexDirection: "row !important",
    "& .MuiFormControlLabel-root": {
      width: "46%",
    },
  },
}));

const EditUserRole = (props) => {
  const { openModel, handleClose, res, setRes, id } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState({});
  const [userCheckbox, setUserCheckbox] = useState([]);

  const [nameValError, setNameValError] = useState({
    helperText: "",
    error: false,
  });

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setUserCheckbox([...userCheckbox, e.target.value]);
    } else {
      setUserCheckbox(userCheckbox.filter((tool) => tool != e.target.value));
    }
  };

  useEffect(() => {
    const fetchRole = async () => {
      let rolePermissions = [];
      try {
        const userRoleData = await getUsersRoleById(id);
        console.log(userRoleData);
        if (userRoleData.status === 200) {
          setUserInfo(userRoleData.roles);
          userRoleData.capabilities.map((capbility) => {
            rolePermissions.push(capbility.role_capabilities);
          });
          setUserCheckbox(rolePermissions);
        }        
      } catch (error) {
        console.log("error form User Role ", error);
      }
    };
    fetchRole();
  }, [openModel, id]);


  const handleUpdateRole = async () => {
    setLoading(true);
    setNameValError({ helperText: "", error: false });
    if (userInfo.role_name.length < 2) {
      setNameValError({ helperText: "Please enter user role", error: true });
      return;
    }
    var data = new FormData();
    data.append("userrole", userInfo.role_name);
    data.append("userroleid", id);
    userCheckbox.forEach((item) => {
      data.append("usercapbility[]", item);
    });
    console.log([...data]);
    try {
      const response = await UpdateUsersRole(data);
      if (response.status === 200) {
      }
      setRes(!res);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from edit user role", error);
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>
              Edit User Role {id}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <TextField
          required
          helperText={nameValError.helperText}
          error={nameValError.error}
          size="small"
          label="User Role"
          name="role_name"
          type="text"
          fullWidth
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          value={userInfo.role_name}
          style={{ marginBottom: "20px" }}
        />
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="pending-order"
                checked={userCheckbox.includes("pending-order")}
              />
            }
            label="Pending Order"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="orders"
                checked={userCheckbox.includes("orders")}
              />
            }
            label="Orders"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="leads"
                checked={userCheckbox.includes("leads")}
              />
            }
            label="Leads"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="enquiry"
                checked={userCheckbox.includes("enquiry")}
              />
            }
            label="Enquiry"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="product"
                checked={userCheckbox.includes("product")}
              />
            }
            label="Product"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="inventory"
                checked={userCheckbox.includes("inventory")}
              />
            }
            label="Inventory"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="print"
                checked={userCheckbox.includes("print")}
              />
            }
            label="Print Jobs"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="fulfilled"
                checked={userCheckbox.includes("fulfilled")}
              />
            }
            label="Fulfilled/Dispatch"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="settings"
                checked={userCheckbox.includes("settings")}
              />
            }
            label="Setting"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="users"
                checked={userCheckbox.includes("users")}
              />
            }
            label="Users"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="user-roles"
                checked={userCheckbox.includes("user-roles")}
              />
            }
            label="User Roles"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="role_capabilities[]"
                value="advanced-settings"
                checked={userCheckbox.includes("advanced-settings")}
              />
            }
            label="Advanced Settings"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <Button
          onClick={handleUpdateRole}
          disabled={loading}
          variant="contained"
          size="small"
        >
          {loading ? "Updating..." : "Update User Role"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserRole;
