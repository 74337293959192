import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
  Paper,
  Checkbox,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import {
  Stack,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import EditIcon from "@mui/icons-material/Edit";
import LinearProgress from "@material-ui/core/LinearProgress";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  getApprovedMockup,
  getFullfilledOrder,
  getCustomerTabExtraNotes,
  getOrderByStatus,
  getImportantOrders,
  addFullFillmentCenter,
} from "../../api";
import OrderInfo from "../../components/OrderInfo";
import ArtworkDetail from "./ArtWorkPopup";
import OrderPrintNotes from "../../components/OrderPrintNotes";
import Layout from "../../components/Layout";
import ConfirmationPopup from "./ConfirmationPop";
import OrderStatusForPrintJobs from "../../components/utils/OrderStatusForPrintJobs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import InputLabel from "@mui/material/InputLabel";
import PinPopup from "../order/PinPop";
import PinPopupMessage from "../order/PinPopupMessage";
import PushPin from "@mui/icons-material/PushPin";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu } from "@mui/material";
import TrackEvents from "../../api/amplitudeEvents";
import OrderAssign from "../../components/OrderAssign";

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

function EnhancedTableHead(props) {
  // const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
          // value="all"
          // indeterminate={numSelected > 0 && numSelected < rowCount}
          // checked={rowCount > 0 && numSelected === rowCount}
          // onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell> Order </TableCell>
        <TableCell>Order details</TableCell>
        <TableCell>Approved mockup</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>FullFillment Center</TableCell>
        <TableCell>Notes</TableCell>
        {/* <TableCell>View</TableCell> */}
        <TableCell>Dispatch date</TableCell>
        <TableCell>Important</TableCell>
        <TableCell>Pod User</TableCell>
        <TableCell>Assign To</TableCell>
      </TableRow>
    </TableHead>
  );
}
const PinComponent = ({ row, res, setRes }) => {
  const classes = useStyles();
  const [openPinpop, setPinPop] = useState(false);

  const handleClosePinPop = () => {
    setPinPop(false);
  };

  return (
    <>
      <Box
        // width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          fontSize="small"
          className={classes.pinIcon}
          onClick={() => setPinPop(true)}
        >
          <PushPin
            className={row.pinnedorder === "yes" ? classes.pinnedOrder : null}
            sx={{ transform: "rotate(45deg)" }}
            fontSize="small"
          />
        </IconButton>
      </Box>
      <PinPopup
        open={openPinpop}
        handleClose={handleClosePinPop}
        id={row.id}
        res={res}
        setRes={setRes}
      />
    </>
  );
};

const PinComponentMessage = ({ row, res, setRes }) => {
  const classes = useStyles();
  const [openPinMessagepop, setPinMessagePop] = useState(false);

  const handleClosePinMessagePop = () => {
    setPinMessagePop(false);
  };

  return (
    <>
      <Box
        // width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          fontSize="small"
          className={classes.pinIcon}
          onClick={() => setPinMessagePop(true)}
        >
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      {openPinMessagepop ? (
        <PinPopupMessage
          open={openPinMessagepop}
          handleClose={handleClosePinMessagePop}
          id={row.id}
          res={res}
          setRes={setRes}
        />
      ) : null}
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pinnedOrder: {
    color: "rgb(215 0 0)",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 850,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  orderbyheading: {
    fontSize: "18px",
    lineHeight: 1.3,
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 0 !important",
    },
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  editIcon: {
    height: "15px !important",
    marginLeft: "7px",
    cursor: "pointer",
  },
  showgreen: {
    backgroundColor: "#a2f8a2",
    color: "#ffffff",
  },
  orderbybox: {
    backgroundColor: "#fff",
    padding: "1rem",
    borderRadius: "4px",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  orderbyButton: {
    display: "inline-block",
    padding: "9px 10px",
    minWidth: "100px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "75px",
    },
  },
  alreadyClicked: {
    backgroundColor: "#e8e8e8",
  },
  secBox: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  secTopBtns: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 0 !important",
    },
  },
  secTopBar: {
    flexWrap: "wrap",
  },
  searchInputBox: {
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  printOrdersBtn: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
    "& .MuiButton-root": {
      [theme.breakpoints.down("xs")]: {
        margin: "0 !important",
      },
    },
  },
  widthbtn: {
    maxWidth: "120px",
  },
}));

const DateComponent = ({ row, date, res, setRes }) => {
  const classes = useStyles();
  const [openDatepop, setDatePop] = useState(false);

  const handleCloseDatePop = () => {
    setDatePop(false);
  };
  return (
    <>
      <Box
        // width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontSize={"14px"}
      >
        <div>{date}</div>
        <EditIcon
          fontSize="small"
          className={classes.editIcon}
          onClick={() => setDatePop(true)}
        />
      </Box>
      <ConfirmationPopup
        open={openDatepop}
        handleClose={handleCloseDatePop}
        id={row.id}
        dispatchdate={date}
        res={res}
        setRes={setRes}
      />
    </>
  );
};

export default function EnhancedTable() {
  const currentPageId = parseInt(getTheCurrentUrl());
  const classes = useStyles();
  const history = useHistory();
  const orderfilterstatusprint = localStorage.getItem("orderfilterstatusprint");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(currentPageId);
  const [openModel, setOpenModel] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [rowData, setRowData] = useState({});
  const [artData, setArtData] = useState([]);
  const [artLoading, setArtLoading] = useState(false);
  const [id, setId] = useState(null);
  const [artDetailModal, setArtDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [productsearch, setProductSearch] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [changedprintorderstatus, setChangedPrintOrderStatus] =
    useState("none");
  const [res, setRes] = useState(false);
  localStorage.setItem("PrintNewPage", currentPageId + 1);
  const [selectOrderError, setSelectOrderError] = useState();
  const currentRowsPerPage = localStorage.getItem("printRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    currentRowsPerPage ? currentRowsPerPage : 10
  );
  const [searchvalue, setSearchValue] = React.useState("");
  const [productsearchvalue, setProductSearchValue] = React.useState("");
  const [orderfilterstatus, setOrderFilterStatus] = React.useState(
    orderfilterstatusprint ? orderfilterstatusprint : ""
  );
  const [orderfullfillmentcenter, setOrderFullFillmentCenter] =
    React.useState("none");
  const [filterpage, setFilterPage] = React.useState(0);
  const [orderbyformat, setOrderByFormat] = useState("DESC");
  const [importantorder, setImportantOrder] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openlocationmodal, setOpenLocationModal] = useState(false);

  const [locationname, setLocationName] = useState("Printyo Thomastown");
  const handleCloseModel = () => {
    setOpenModel(false);
    setRowData({});
  };

  const [changedOrderAssign, setChangedOrderAssign] = useState("none");

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = orders.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleSelectAllClick = (event) => {
  // const value = event.target.value;
  //   if (value[value.length - 1] === "all") {
  //     setSelected(selected.length === orders.length ? [] : orders);
  //     return;
  //   }
  //   setSelected(value);
  // };

  // const handleSelectAllClick = (event) => {
  //   const value = event.target.value;
  //   console.log(value);
  //   if (value === "all") {
  //     setSelected(selected.length === orders.length ? [] : orders);
  //     return;
  //   }
  //   // added below code to update selected options
  //   const list = [...selected];
  //   const index = list.indexOf(value);
  //   index === -1 ? list.push(value) : list.splice(index, 1);
  //   setSelected(list);
  // };

  const handleSelectAllClick = (event) => {
    const value = event.target.value;
    if (selected.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selected.filter((s) => s !== value);
      setSelected(newSelected);
    } else {
      // if value not present
      setSelected([...selected, value]);
    }
  };

  //
  const handleSelectedOrders = (e) => {
    e.preventDefault();
    if (selected.length < 1) {
      setSelectOrderError("Please select order(s)");
      return;
    }
    const win = window.open(
      `https://printyocrm.printyo.net.au/order/printdate/byids?ids=${selected}`,
      "_blank"
    );
    win.focus();
    setSelectOrderError(!selectOrderError);
  };

  const handleClick = useCallback((event, name) => {
    console.log("table clicked");
  }, []);

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    // history.push({
    //   pathname: `/print/${newPage + 1}`,
    // });
    // console.log(newPage + "page");

    if (orderfilterstatus !== "none" && orderfilterstatus !== "") {
      setFilterPage(newPage);
    } else {
      setPage(newPage);
    }
    history.push({
      pathname: `/print/${newPage + 1}`,
    });
  };

  // const handleOpenMenuForLocation = useCallback((status) => {
  //   setLocationName(status);
  //   setOpenLocationModal(true);
  //   setAnchorEl(null);
  // }, []);

  const handleOpenMenuForLocation = useCallback(
    (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  const handleFullFillmentCenter = async (event, id) => {
    const approve = window.confirm(
      "Do you really want to change Fullfillment center ?"
    );
    if (approve) {
      const data = {
        meta_data: [
          {
            key: "pcrm_fullfilling_location",
            value: event.target.value,
          },
        ],
      };
      const updatelocation = await addFullFillmentCenter(data, id);
      setLocationName(event.target.value);
      TrackEvents(`${id}`, {
        process: "Changed FullFilment center to " + event.target.value,
      });
    }
  };

  const handleOrderStatusFilterChange = (e) => {
    // if (e.target.value != "none" && e.target.value != "")
    setOrderFilterStatus(e.target.value);

    if (e.target.value === "none" || e.target.value === "") {
      localStorage.removeItem("orderfilterstatusprint");
    } else {
      localStorage.setItem("orderfilterstatusprint", e.target.value);
    }
  };
  const handleShippmentOrderChange = (e) => {
    setOrderFullFillmentCenter(e.target.value);
    if (e.target.value === "none" || e.target.value === "") {
      localStorage.removeItem("orderfilterlocation");
    } else {
      localStorage.setItem("orderfilterlocation", e.target.value);
    }
  };

  const handleOrderDetails = useCallback(
    (e, id) => {
      const curl = window.location.pathname;
      const cpageId = curl.split("/")[2];
      const previousOrders = JSON.parse(
        localStorage.getItem("visitedPrintOrders")
      );
      console.log(previousOrders);
      if (previousOrders) {
        // console.log(JSON.parse(previousOrders))
        previousOrders.push(id);
        localStorage.setItem(
          "visitedPrintOrders",
          JSON.stringify(previousOrders)
        );
      } else {
        localStorage.setItem("visitedPrintOrders", JSON.stringify([id]));
      }
      e.stopPropagation();
      window.open(`/print/${cpageId}/details/print?id=${id}&tab=0`, "_blank");
      // history.push({
      //   pathname: `/print/${cpageId}/details/print`,
      //   search: `?id=${id}&tab=0`,
      // });
    },
    [history]
  );

  const handleModelOpen = useCallback((e, row) => {
    e.stopPropagation();
    setRowData(row);
    setOpenModel(true);
  }, []);

  const handleArtWork = useCallback((e, row) => {
    e.stopPropagation();
    setId(row.id);
    const getArtworkdetails = async () => {
      if (row.id) {
        try {
          setArtLoading(true);
          const data = await getApprovedMockup(row.id);
          console.log("data", data);
          if (data.status === 200) {
            setArtData(data.approvedmockups);
          }
          setArtLoading(false);
          setArtDetailModal(true);
        } catch (error) {
          setArtLoading(false);
          console.log(error);
        }
      }
    };
    getArtworkdetails();
  }, []);

  const handleCloseArtModel = useCallback(() => {
    setArtDetailModal(false);
    setArtData([]);
    setId(null);
  }, []);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setSearchValue(e.target.value);
  };

  const handleProductSearch = (e) => {
    if (e.target.value === "") {
      setProductSearch("");
    }
    setProductSearchValue(e.target.value);
  };

  const handleNotesOpen = useCallback((e, id) => {
    e.stopPropagation();
    setId(id);
    const fetchNotes = async () => {
      if (id) {
        try {
          // setNotesLoading(true);
          // const data = await getCustomerTabNotes(id);
          // setNotes(data.per_product_information);
          const data = await getCustomerTabExtraNotes(id);
          if (data.error === "none") {
            setNotes(data.ordersnote);
          }
          setNotesLoading(false);
          setOpenNotes(true);
        } catch (error) {
          setNotesLoading(false);
          console.log(error);
        }
      }
    };
    fetchNotes();
  }, []);

  const handleNotesClose = useCallback((e, row) => {
    setId(null);
    setOpenNotes(false);
  }, []);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("printRowsPerPage");
    } else {
      localStorage.setItem("printRowsPerPage", e.target.value);
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setOrders([]);
        setCount(0);
        setLoading(true);
        if (orderfilterstatus !== "none" && orderfilterstatus !== "") {
          console.log("filter");
          setOrders([]);
          setCount(0);
          var data = await getOrderByStatus(
            filterpage + 1,
            rowsPerPage,
            search,
            orderfilterstatus,
            orderfullfillmentcenter
          );
        } else if (importantorder) {
          setOrders([]);
          setCount(0);
          var data = await getImportantOrders(page + 1, rowsPerPage, search);
        } else {
          var data = await getFullfilledOrder(
            page + 1,
            rowsPerPage,
            search,
            orderbyformat,
            orderfullfillmentcenter,
            productsearch
          );
        }
        setOrders(data.responseData.response);
        setCount(data.responseData.order_count.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchOrder();
  }, [
    page,
    search,
    res,
    rowsPerPage,
    orderfilterstatus,
    changedprintorderstatus,
    filterpage,
    orderbyformat,
    importantorder,
    locationname,
    orderfullfillmentcenter,
    productsearch,
    changedOrderAssign,
  ]);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setOrderFilterStatus("none");
    setFilterPage(0);
    setSearch(searchvalue);
  };

  const handleProductSearchSubmit = (event) => {
    event.preventDefault();
    setOrderFilterStatus("none");
    setFilterPage(0);
    setProductSearch(productsearchvalue);
  };

  const handleAscDesc = () => {
    setOrderFilterStatus("none");
    if (orderbyformat === "DESC") {
      setOrderByFormat("ASC");
    } else {
      setOrderByFormat("DESC");
    }
  };

  const handleCloseLocationMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // order By Date

  const [printOrderByDate, setPrintOrderByDate] = useState(false);
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 2);
  const today = new Date();
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState(prevDate);
  const [toDate, setToDate] = useState(currentDate);
  const [fromError, setFromError] = useState();
  const [toError, setToError] = useState();

  moment(fromDate).format("l");
  moment(toDate).format("l");

  const handleOrderByConfirmed = (e) => {
    e.preventDefault();
    if (toDate <= fromDate) {
      setFromError("From date should be less than To date");
      return;
    }
    if (toDate > today) {
      setToError("To date shouldn't be bigger than current date");
      return;
    }
    const formattedfromdate = moment(fromDate).format("YYYY-MM-DD");
    const formattedtodate = moment(toDate).format("YYYY-MM-DD");
    const win = window.open(
      `https://printyocrm.printyo.net.au/order/printdate/bydate?from=${formattedfromdate}&to=${formattedtodate}`,
      "_blank"
    );
    // win.focus();
    setFromError(!fromError);
    setToError(!toError);
  };

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        <Box display="flex" alignItems="center" className={classes.secTopBar}>
          <Typography className={classes.heading} sx={{ mb: 2 }}>
            Print Jobs
          </Typography>
          <FormControl
            className={classes.secTopBtns}
            sx={{ mr: 2, mb: 1, minWidth: 150, backgroundColor: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "14px" }}>
              Select Status
            </InputLabel>
            <Select
              sx={{ fontSize: "14px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderfilterstatus}
              label="Select Status"
              onChange={handleOrderStatusFilterChange}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="job-printed">Job Printed</MenuItem>
              <MenuItem value="onhold-checknotes">OnHold CheckNotes</MenuItem>
              <MenuItem value="sendtoprint">Send To Print</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            className={classes.secTopBtns}
            sx={{ mr: 2, mb: 1, minWidth: 150, backgroundColor: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "14px" }}>
              Select Shippment Location
            </InputLabel>
            <Select
              sx={{ fontSize: "14px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderfullfillmentcenter}
              label="Select Status"
              onChange={handleShippmentOrderChange}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="Printyo Thomastown">Printyo Thomastown</MenuItem>
              <MenuItem value="Printyo Altona">Printyo Altona</MenuItem>
              <MenuItem value="Printyo Sydney">Printyo Sydney</MenuItem>
              <MenuItem value="Printyo Heidelberg">Printyo Heidelberg</MenuItem>
            </Select>
          </FormControl>
          <Button
            className={classes.addOrderbtn}
            style={{ marginRight: "16px", marginBottom: "8px" }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleAscDesc}
          >
            {orderbyformat === "DESC"
              ? "Show in DESC Order"
              : "Show in ASC Order"}
          </Button>
          <Button
            className={classes.addOrderbtn}
            style={{ marginRight: "16px", marginBottom: "8px" }}
            variant="outlined"
            color="primary"
            size="small"
            href="https://printyocrm.printyo.net.au/order/printdate/today"
            target="_blank"
          >
            Print Today Order(s)
          </Button>
          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginRight: "16px", marginBottom: "8px" }}
            onClick={() => setPrintOrderByDate(!printOrderByDate)}
          >
            Print Order(s) by date
          </Button>
          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginBottom: "8px" }}
            onClick={() => setImportantOrder(!importantorder)}
          >
            {importantorder ? "Show all orders" : "Important Orders"}
          </Button>
        </Box>
        {printOrderByDate ? (
          <Box className={classes.orderbybox}>
            <Typography className={classes.orderbyheading}>
              Print Order(s) by date
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              my={2}
            >
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="From"
                  required
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  label="To"
                  required
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
              <Button
                className={classes.orderbyButton}
                onClick={handleOrderByConfirmed}
                variant="contained"
                size="small"
                color="primary"
                disabled={loading}
              >
                Submit
              </Button>
            </Stack>
            <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>
              {fromError}
            </Typography>
            <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>
              {toError}
            </Typography>
          </Box>
        ) : null}
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          className={classes.searchInputBox}
        >
          <FormControl
            className={classes.printOrdersBtn}
            variant="outlined"
            sx={{
              my: 1,
              minWidth: 80,
              flex: "1",
            }}
          >
            {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={date}
              onChange={handleDateChange}
              size="small"
              sx={{ backgroundColor: "#fff", fontSize: "14px" }}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem value={10} sx={{ fontSize: "14px" }}>
                All Dates
              </MenuItem>
              <MenuItem value={20} sx={{ fontSize: "14px" }}>
                September 2021
              </MenuItem>
              <MenuItem value={30} sx={{ fontSize: "14px" }}>
                Augest 2021
              </MenuItem>
            </Select> */}
            <Button
              className={`${classes.addOrderbtn} ${classes.widthbtn}`}
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleSelectedOrders}
            >
              Print Order(s)
            </Button>
            <Typography
              style={{ color: "#d32f2f", fontSize: "12px", marginTop: "3px" }}
            >
              {selectOrderError}
            </Typography>
          </FormControl>
          <form onSubmit={handleProductSearchSubmit}>
            <TextField
              className={classes.searchInput}
              sx={{
                m: 1,
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="Search Product"
              onChange={handleProductSearch}
              value={productsearchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleProductSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="outlined-start-adornment"
              className={classes.searchInput}
              sx={{
                m: 1,
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="search"
              onChange={handleSearch}
              value={searchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
        <Paper className={classes.paper}>
          <TableContainer>
            {loading && <LinearProgress />}
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              sx={{ tableLayout: "auto" }}
            >
              <EnhancedTableHead
                classes={classes}
                // numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                // rowCount={orders.length}
              />
              {orders && orders.length > 0 ? (
                <TableBody>
                  {orders.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced - table - checkbox - ${index} `;
                    let date =
                      row.dispatchdate === "N/A"
                        ? "N/A"
                        : moment(row.dispatchdate).format("ll");
                    let previousOrdersClick = JSON.parse(
                      localStorage.getItem("visitedPrintOrders")
                    );
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        key={row.id}
                        // className={
                        //   row.status === "dispatch" ? classes.showgreen : ""
                        // }
                        className={
                          previousOrdersClick
                            ? previousOrdersClick.includes(row.id)
                              ? classes.alreadyClicked
                              : null
                            : null
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            value={row.id}
                            onChange={handleSelectAllClick}
                            // checked={selected.includes(row.id)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className={classes.orderId}
                          onClick={(e) => handleOrderDetails(e, row.id)}
                        >
                          {`#${row.id} ${row.billing.first_name} ${row.billing.last_name} `}
                        </TableCell>
                        <TableCell colSpan={"1"}>
                          <IconButton onClick={(e) => handleModelOpen(e, row)}>
                            <VisibilityOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={(e) => handleArtWork(e, row)}>
                            {artLoading && id === row.id ? (
                              <CircularProgress
                                className={classes.loading}
                                size={20}
                                thickness={4}
                              />
                            ) : (
                              <FaceOutlinedIcon fontSize="small" />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <OrderStatusForPrintJobs
                            row={row}
                            changedprintorderstatus={changedprintorderstatus}
                            setChangedPrintOrderStatus={
                              setChangedPrintOrderStatus
                            }
                          />
                        </TableCell>
                        {/* Fullfillment center */}
                        <TableCell>
                          {/* {row.fullfilledlocation
                            ? row.fullfilledlocation
                            : "Printyo Thomastown"} */}
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={row.fullfilledlocation}
                            label="Shipping Method"
                            onChange={(event) => {
                              handleFullFillmentCenter(event, row.id);
                            }}
                            sx={{ fontSize: "12px", Padding: "5px" }}
                          >
                            <MenuItem>Select FullFillment Location</MenuItem>
                            <MenuItem value="Printyo Thomastown">
                              Printyo Thomastown
                            </MenuItem>
                            <MenuItem value="Printyo Altona">
                              Printyo Altona
                            </MenuItem>
                            <MenuItem value="Printyo Sydney">
                              Printyo Sydney
                            </MenuItem>
                            <MenuItem value="Printyo Heidelberg">
                              Printyo Heidelberg
                            </MenuItem>
                          </Select>
                        </TableCell>
                        {/* Change Fullfillment Center */}
                        <TableCell>
                          <IconButton
                            onClick={(e) => handleNotesOpen(e, row.id)}
                          >
                            {notesLoading && id === row.id ? (
                              <CircularProgress
                                className={classes.loading}
                                size={20}
                                thickness={4}
                              />
                            ) : (
                              <ChatOutlinedIcon fontSize="small" />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <DateComponent
                            row={row}
                            date={date}
                            res={res}
                            setRes={setRes}
                          />
                        </TableCell>
                        <TableCell>
                          <Stack display="flex" direction="row">
                            <PinComponent row={row} res={res} setRes={setRes} />
                            <PinComponentMessage
                              row={row}
                              res={res}
                              setRes={setRes}
                            />
                          </Stack>
                        </TableCell>

                        <TableCell>
                          {row.pods_customer ? (
                            <span className="highlight-yes">
                              {row.pods_customer.toUpperCase()}
                            </span>
                          ) : (
                            "No"
                          )}
                        </TableCell>
                        <TableCell>
                          <OrderAssign
                            row={row}
                            res={res}
                            setRes={setRes}
                            changedOrderAssign={changedOrderAssign}
                            setChangedOrderAssign={setChangedOrderAssign}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography>No Order(s) Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={
              orderfilterstatus !== "none" && orderfilterstatus !== ""
                ? filterpage
                : page
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <OrderInfo
          handleClose={handleCloseModel}
          openModel={openModel}
          setOpenModel={setOpenModel}
          {...rowData}
        />
        <ArtworkDetail
          id={id}
          handleClose={handleCloseArtModel}
          openModel={artDetailModal}
          setOpenModel={setOpenModel}
          artData={artData}
        />

        <OrderPrintNotes
          handleClose={handleNotesClose}
          handleOpen={handleNotesOpen}
          notes={notes}
          open={openNotes}
          id={id}
        />
      </Box>
    </Layout>
  );
}
