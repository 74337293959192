import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  Container,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Mail from "./Mail";
import Sms from "./Sms";
import {
  getEnquiryEmail,
  getEnquirySms,
} from "../../../api";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";

const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  activityBtnAll: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 0 !important",
    },
  },
  activityDetailBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  activityEmailBox: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      overflowX: "auto",
    },
    "& #wrapper": {
      [theme.breakpoints.down("xs")]: {
        overflowX: "auto",
      },
    },
    "& .gmail_quote": {
      paddingLeft: "0 !important",
      margin: "16px 0px 0px 0 !important",
    },
    "& .gmail_quote >div>div": {
      [theme.breakpoints.down("xs")]: {
        overflowX: "auto",
      },
    },
    "& .gmail_signature *": {
      width: "auto !important",
    },
    "& table": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "500px !important",
      },
    },
  },
}));

const Activities = ({ enquiry, id }) => {
  const classes = useStyles();
  const [category, setCategory] = useState(10);
  const [open, setOpen] = useState("");
  const [res, setRes] = useState(null);
  const [emails, setEmails] = useState([]);
  const [resEmail, setResEamil] = useState(false);
  const [sms, setSms] = useState([]);

  const handleChange = useCallback((e) => {
    setCategory(e.target.value);
  }, []);

  const handleActivity = (value) => {
    if (value === open) {
      setOpen("");
    } else {
      setOpen(value);
    }
  };

  function createMarkup(data) {
    return { __html: data };
  }

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const data = await getEnquiryEmail(id);
        setEmails(data.enquiry);
      } catch (error) {
        console.log("eror from email activity", error);
      }
    };
    if (id) {
      fetchEmail();
    }
  }, [id, resEmail]);

  useEffect(() => {
    const fetchSms = async () => {
      const data = await getEnquirySms(id);
      setSms(data.enquirysms);
    };
    if (id) {
      fetchSms();
    }
  }, [id]);


  return (
    <Container className={classes.container}>
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={3}
        flex={1}
        className={classes.activityBtnBox}
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<EmailIcon fontSize="small" />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            letterSpacing: "1px",
          }}
          value="email"
          onClick={() => handleActivity("email")}
        >
          Mail
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<SmsIcon fontSize="small" />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            letterSpacing: "1px",
          }}
          value="sms"
          onClick={() => handleActivity("sms")}
        >
          Sms
        </Button>
        <FormControl
          variant="outlined"
          className={classes.activityBtnAll}
          sx={{
            m: 1,
            minWidth: 80,
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            onChange={handleChange}
            size="small"
            sx={{ backgroundColor: "#fff" }}
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem value={10}>All activities</MenuItem>
            <MenuItem value={30}>Mails</MenuItem>
            <MenuItem value={40}>Sms</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {open === "email" ? (
        <Mail enquiry={enquiry} id={id} setResEamil={setResEamil} resEmail={resEmail} />
      ) : open === "sms" ? (
        <Sms enquiry={enquiry} id={id} />
      ) : null}
      {(category === 10 || category === 30) && (
        <Paper
          sx={{ p: 3, mt: 4, width: "70vw" }}
          className={classes.activityDetailBox}
        >
          <Typography>Emails</Typography>
          <Divider />
          {(emails || []).map((item) => {
            return (
              <Box key={item.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  <Box display="flex" alignItems="center">
                    <CustomTypography sx={{ mr: 2 }}>
                      Customer email
                    </CustomTypography>
                    <TypographySubHeading>
                      {item.emailactivity.customeremail}
                    </TypographySubHeading>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CustomTypography sx={{ mr: 2 }}>Subject</CustomTypography>
                    <TypographySubHeading>
                      {item.emailactivity.emailsubject}
                    </TypographySubHeading>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div
                      className={classes.activityEmailBox}
                      style={{ display: "inline-block" }}
                      dangerouslySetInnerHTML={createMarkup(
                        item.emailactivity.emailmessage
                      )}
                    ></div>
                  </Box>
                  <Box>
                    {item.emailactivityattachments.map((img) => {
                      return (
                        <Box key={img.id} display="flex" alignItems="center">
                          <CustomTypography sx={{ mr: 2 }}>
                            Image url:{" "}
                          </CustomTypography>
                          <a
                            href={`https://printyocrm.printyo.net.au/${img.attachmenturl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CustomTypography sx={{ mr: 2 }}>
                              view img{" "}
                            </CustomTypography>
                          </a>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {moment(item.emailactivity.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                  <span>
                    By{" "}
                    {item.emailactivityuser
                      ? item.emailactivityuser.name
                      : "Customer"}
                  </span>
                </Box>
              </Box>
            );
          })}
        </Paper>
      )}
      {(category === 10 || category === 40) && (
        <Paper
          sx={{ p: 3, mt: 4, width: "70vw" }}
          className={classes.activityDetailBox}
        >
          <Typography>SMS</Typography>
          <Divider />
          {(sms || []).map((item) => {
            return (
              <Box key={item.enquirysms.id}>
                <Box
                  sx={{
                    backgroundColor: "#d7cad2",
                    padding: "10px",
                    position: "relative",
                    lineHeight: "1.4",
                    fontSize: "13px",
                    color: "#3c434a",
                  }}
                  my={2}
                >
                  <div>{item.enquirysms.smsmessage}</div>
                  <Box
                    sx={{
                      position: "absolute",
                      borderTop: "10px solid transparent",
                      borderBottom: "10px solid transparent",
                      borderLeft: "10px solid #d7cad2",
                      bottom: "-10px",
                      left: "20px",
                    }}
                  />
                </Box>
                <Box color="#999" fontSize="11px" lineHeight="1.5">
                  {moment(item.enquirysms.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                  <span> By {item.enquirysmsuser.name}</span>
                </Box>
              </Box>
            );
          })}
        </Paper>
      )}
    </Container>
  );
};

export default Activities;
