import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableFooter,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import {
  Stack,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import OrderInfo from "./OrderInfo";
import {
  getOrderImg,
  getCustomerNotes,
  getOtherOrders,
  getOrderByStatus,
  getImportantOrders,
} from "../../api";
// import moment from "moment";
import ArtworkDetail from "../../components/ArtworkDetails";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import OrderNotes from "../../components/OrderNotes";
import OrderStatus from "../../components/utils/OrderStatus";
import Layout from "../../components/Layout";
import InputLabel from "@mui/material/InputLabel";
import PinPopup from "./PinPop";
import PinPopupMessage from "./PinPopupMessage";
import PushPin from "@mui/icons-material/PushPin";
import TrackEvents from "../../api/amplitudeEvents";
import OrderAssign from "../../components/OrderAssign";

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> 
        </TableCell>*/}
        <TableCell>Date</TableCell>
        <TableCell> Order </TableCell>
        <TableCell>Order details</TableCell>
        <TableCell>Artwork details</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Notes</TableCell>
        <TableCell>Assign To</TableCell>
        <TableCell>Important</TableCell>
        <TableCell>Pod User</TableCell>
      </TableRow>
    </TableHead>
  );
}

const PinComponent = ({ row, res, setRes }) => {
  const classes = useStyles();
  const [openPinpop, setPinPop] = useState(false);

  const handleClosePinPop = () => {
    setPinPop(false);
  };

  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          fontSize="small"
          className={classes.pinIcon}
          onClick={() => setPinPop(true)}
        >
          <PushPin
            className={row.pinnedorder === "yes" ? classes.pinnedOrder : null}
            sx={{ transform: "rotate(45deg)" }}
            fontSize="small"
          />
        </IconButton>
      </Box>
      <PinPopup
        open={openPinpop}
        handleClose={handleClosePinPop}
        id={row.id}
        res={res}
        setRes={setRes}
      />
    </>
  );
};

const PinComponentMessage = ({ row, res, setRes }) => {
  const classes = useStyles();
  const [openPinMessagepop, setPinMessagePop] = useState(false);

  const handleOpenPinMessagePop = () => {
    setPinMessagePop(true);
  };

  const handleClosePinMessagePop = () => {
    setPinMessagePop(false);
  };

  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          fontSize="small"
          className={classes.pinIcon}
          onClick={handleOpenPinMessagePop}
        >
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      {openPinMessagepop ? (
        <PinPopupMessage
          open={openPinMessagepop}
          handleClose={handleClosePinMessagePop}
          id={row.id}
          res={res}
          setRes={setRes}
        />
      ) : null}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pinnedOrder: {
    color: "rgb(215 0 0)",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 850,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  popupcontainer: {
    ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
      boxShadow: "none",
    },
  },
  alreadyClicked: {
    backgroundColor: "#e8e8e8",
  },
  secBox: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  secTopBtns: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 !important",
    },
  },
  secTopBar: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  searchInputBox: {
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
    },
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  pinIcon: {
    cursor: "pointer",
  },
}));

export default function EnhancedTable() {
  var moment = require("moment-timezone");
  const currentPageId = parseInt(getTheCurrentUrl());
  const currentorderfilterstatus = localStorage.getItem("orderfilterstatus");
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(currentPageId);
  const [searchpage, setSearchPage] = React.useState(0);
  const [filterpage, setFilterPage] = React.useState(0);
  const [openModel, setOpenModel] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [rowData, setRowData] = useState({});
  const [artData, setArtData] = useState([]);
  const [artLoading, setArtLoading] = useState(false);
  const [id, setId] = useState(null);
  const [artDetailModal, setArtDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [changedorderstatus, setChangedOrderStatus] = useState("none");
  const [orderfilterstatus, setOrderFilterStatus] = React.useState(
    currentorderfilterstatus ? currentorderfilterstatus : ""
  );
  const [res, setRes] = useState(false);
  const [searchvalue, setSearchValue] = React.useState("");
  const currentRowsPerPage = localStorage.getItem("orderRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    currentRowsPerPage ? currentRowsPerPage : 10
  );
  localStorage.setItem("OrderNewPage", currentPageId + 1);

  const [importantorder, setImportantOrder] = useState(false);

  const [changedOrderAssign, setChangedOrderAssign] = useState("none");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setRowData({});
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = orders.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleModelOpen = useCallback((e, row) => {
    e.stopPropagation();
    setRowData(row);
    setOpenModel(true);
    TrackEvents(`${row.id}`, {
      process: "Open Order Details Modal",
    });
  }, []);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    setFilterPage(0);
    setSearchPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("orderRowsPerPage");
    } else {
      localStorage.setItem("orderRowsPerPage", e.target.value);
    }
  };

  const handleArtWork = useCallback((e, row) => {
    e.stopPropagation();
    setId(row.id);
    const getArtworkdetails = async () => {
      if (row.id) {
        try {
          TrackEvents(`${row.id}`, {
            process: "Open Artwork Modal In All Order",
          });
          setArtLoading(true);
          const data = await getOrderImg(row.id);
          setArtData(data);
          setArtLoading(false);
          setArtDetailModal(true);
        } catch (error) {
          setArtLoading(false);
          console.log(error);
        }
      }
    };
    getArtworkdetails();
  }, []);

  const handleCloseArtModel = useCallback(() => {
    setArtDetailModal(false);
    setArtData([]);
    setId(null);
  }, []);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setSearchValue(e.target.value);
  };

  const handleOrderStatusFilterChange = (e) => {
    // if (e.target.value != "none" && e.target.value != "")
    setOrderFilterStatus(e.target.value);
    setFilterPage(0);
    if (e.target.value === "none" || e.target.value === "") {
      localStorage.removeItem("orderfilterstatus");
    } else {
      localStorage.setItem("orderfilterstatus", e.target.value);
    }
  };

  const handleNotesOpen = useCallback((e, id) => {
    e.stopPropagation();
    setId(id);
    const fetchNotes = async () => {
      if (id) {
        try {
          TrackEvents(`${id}`, {
            process: "Open Notes Modal",
          });
          setNotesLoading(true);
          const data = await getCustomerNotes(id);
          setNotes(data);
          setNotesLoading(false);
          setOpenNotes(true);
        } catch (error) {
          setNotesLoading(false);
          console.log(error);
        }
      }
    };
    fetchNotes();
  }, []);

  const handleNotesClose = useCallback((e, row) => {
    setId(null);
    setOpenNotes(false);
  }, []);

  const handleAddOrder = () => {
    history.push("/add-order");
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
    setOrderFilterStatus("none");
    setFilterPage(0);
    localStorage.removeItem("orderfilterstatus");
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setOrders([]);
      setCount(0);
      try {
        setLoading(true);
        if (orderfilterstatus !== "none" && orderfilterstatus !== "") {
          setOrders([]);
          setCount(0);
          var data = await getOrderByStatus(
            filterpage + 1,
            rowsPerPage,
            search,
            orderfilterstatus
          );
        } else if (importantorder) {
          setOrders([]);
          setCount(0);
          var data = await getImportantOrders(page + 1, rowsPerPage, search);
        } else {
          if (search) {
            var data = await getOtherOrders(
              searchpage + 1,
              rowsPerPage,
              search
            );
          } else {
            var data = await getOtherOrders(page + 1, rowsPerPage, search);
            setSearchPage(0);
          }
          setFilterPage(0);
        }
        //const data = await getOtherOrders(page + 1, 10, search);
        setOrders(data.responseData.response);
        setCount(data.responseData.order_count.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchOrder();
  }, [
    page,
    search,
    res,
    searchpage,
    filterpage,
    changedorderstatus,
    orderfilterstatus,
    rowsPerPage,
    importantorder,
    changedOrderAssign,
  ]);

  const handleChangePage = (event, newPage) => {
    if (orderfilterstatus !== "none" && orderfilterstatus !== "") {
      setFilterPage(newPage);
    } else {
      if (search) {
        setSearchPage(newPage);
      } else {
        setPage(newPage);
      }
    }
    if (!search) {
      history.push({
        pathname: `/orders/${newPage + 1}`,
      });
      console.log(newPage + "page");
    }
  };

  const handleOrderDetails = useCallback(
    (e, id) => {
      const curl = window.location.pathname;
      const cpageId = curl.split("/")[2];
      const previousOrders = JSON.parse(localStorage.getItem("visitedOrders"));
      console.log(previousOrders);
      if (previousOrders) {
        // console.log(JSON.parse(previousOrders))
        previousOrders.push(id);
        localStorage.setItem("visitedOrders", JSON.stringify(previousOrders));
      } else {
        localStorage.setItem("visitedOrders", JSON.stringify([id]));
      }
      e.stopPropagation();
      TrackEvents(`${id}`, {
        process: "Open Order Details",
      });
      window.open(`/orders/${cpageId}/details?id=${id}&tab=0`, "_blank");
      // history.push({
      //   pathname: `/orders/${cpageId}/details`,
      //   search: `?id=${id}&tab=0`,
      // });
    },
    [history]
  );

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        <Box display="flex" alignItems="center" className={classes.secTopBar}>
          <Typography className={classes.heading}>Orders</Typography>
          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleAddOrder}
          >
            Add order
          </Button>
          <FormControl
            className={classes.secTopBtns}
            sx={{ mx: 2, minWidth: 150, backgroundColor: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "14px" }}>
              Select Status
            </InputLabel>
            <Select
              sx={{ fontSize: "14px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderfilterstatus}
              label="Select Status"
              onChange={handleOrderStatusFilterChange}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="payment-pending">Payment Pending</MenuItem>
              <MenuItem value="changes-required">Changes Required</MenuItem>
              <MenuItem value="on-hold">On-hold</MenuItem>
              <MenuItem value="mockup-sent">Mockup Sent</MenuItem>
              <MenuItem value="artwrok-missing">Artwork Missing</MenuItem>
              <MenuItem value="mockup-approved">Mockup Approved</MenuItem>
              <MenuItem value="sendtoprint">Send to Print</MenuItem>
              <MenuItem value="refund">Refunded</MenuItem>
            </Select>
          </FormControl>
          <Button
            className={classes.addOrderbtn}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setImportantOrder(!importantorder)}
          >
            {importantorder ? "Show all orders" : "Important Orders"}
          </Button>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          mt={2}
          className={classes.searchInputBox}
        >
          <form onSubmit={handleSearchSubmit}>
            <TextField
              id="outlined-start-adornment"
              className={classes.searchInput}
              sx={{
                m: 1,
                width: "30ch",
                backgroundColor: "#fff",
                fontSize: "14px",
              }}
              size="small"
              placeholder="search"
              onChange={handleSearch}
              value={searchvalue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon onClick={handleSearchSubmit} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
        <Paper className={classes.paper}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {orders && orders.length > 0 ? (
                <TableBody>
                  {orders.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // let date = moment(row.date_created.date).format("ll");
                    let date = moment(row.date_created.date)
                      .tz("Australia/Melbourne")
                      .format("ll");
                    let previousOrdersClick = JSON.parse(
                      localStorage.getItem("visitedOrders")
                    );
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          key={row.id}
                          className={
                            previousOrdersClick
                              ? previousOrdersClick.includes(row.id)
                                ? classes.alreadyClicked
                                : null
                              : null
                          }
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell> */}
                          <TableCell>{date}</TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className={classes.orderId}
                            onClick={(e) => handleOrderDetails(e, row.id)}
                          >
                            {`#${row.id} ${row.billing.first_name} ${row.billing.last_name}`}
                            {/* <OrderUnreadNotification orderid={row.id} /> */}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => handleModelOpen(e, row)}
                            >
                              <VisibilityOutlinedIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={(e) => handleArtWork(e, row)}>
                              {artLoading && id === row.id ? (
                                <CircularProgress
                                  className={classes.loading}
                                  size={20}
                                  thickness={4}
                                />
                              ) : (
                                <FaceOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {/* <OrderStatus row={row} /> */}
                            <OrderStatus
                              row={row}
                              changedorderstatus={changedorderstatus}
                              setChangedOrderStatus={setChangedOrderStatus}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => handleNotesOpen(e, row.id)}
                            >
                              {notesLoading && id === row.id ? (
                                <CircularProgress
                                  className={classes.loading}
                                  size={20}
                                  thickness={4}
                                />
                              ) : (
                                <ChatOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <OrderAssign
                              row={row}
                              res={res}
                              setRes={setRes}
                              changedOrderAssign={changedOrderAssign}
                              setChangedOrderAssign={setChangedOrderAssign}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack display="flex" direction="row">
                              <PinComponent
                                row={row}
                                res={res}
                                setRes={setRes}
                              />
                              <PinComponentMessage
                                row={row}
                                res={res}
                                setRes={setRes}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {row.pods_customer ? (
                              <span className="highlight-yes">
                                {row.pods_customer.toUpperCase()}
                              </span>
                            ) : (
                              "No"
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              ) : (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography>No Order(s) Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={
              orderfilterstatus !== "none" && orderfilterstatus !== ""
                ? filterpage
                : search
                ? searchpage
                : page
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <OrderInfo
          handleClose={handleCloseModel}
          openModel={openModel}
          setOpenModel={setOpenModel}
          {...rowData}
        />
        <ArtworkDetail
          id={id}
          handleClose={handleCloseArtModel}
          openModel={artDetailModal}
          setOpenModel={setOpenModel}
          artData={artData}
        />
        <OrderNotes
          handleClose={handleNotesClose}
          handleOpen={handleNotesOpen}
          notes={notes}
          open={openNotes}
          id={id}
        />
      </Box>
    </Layout>
  );
}
