import React from 'react'
import { Box, Typography, makeStyles, Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core'
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    subHeading: {
        color: '#3c434a',
        fontSize: '13px',
        lineHeight: '1.4em',
        fontWeight: 'bold',
        marginTop: '1rem',

    },
    heading: {
        fontSize: '14px',
        lineHeight: '1.4em',
        fontWeight: 'bold',
        marginBottom: '1rem'

    },
    typography: {
        fontSize: '13px',
        lineHeight: '1.4em',
    },
    title: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '1.5em',
        marginBottom: '1rem'
    },
    btn: {
        textTransform: 'none',
        marginLeft: '10px'
    },
    status: {
        fontSize: '1rem'
    },
    margin: {
        marginBottom: '10px'
    },
    image: {
        width: '250px'
    },
    paper: {
        padding: '1rem'
    },
    content: {
        color: '#777',
        lineHeight: '1.6em',
        fontSize: '14px',
        marginTop: '2px'
    },
    comment: {
        backgroundColor: '#d7cad2',
        padding: '10px',
        position: 'relative',
        lineHeight: '1.4',
        fontSize: '13px',
        color: '#3c434a',
    },
    commentDesign: {
        position: 'absolute',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #d7cad2',
        bottom: '-10px',
        left: '20px',
    },
    field: {
        marginTop: '10px'
    },
    textField: {
        '& .MuiInputBase-input': {
            fontSize: '14px',
            color: '#2c3338',
            paddingTop: '5px',
            paddingBottom: '5px',
        },
    },
    menu: {
        paddingTop: '2px',
        paddingBottom: '2px',
        fontSize: '14px',
    },
    modal: { 
        "& .MuiDialog-paper" : {
            [theme.breakpoints.down('xs')]: {
                margin: "16px",
                width: "100%",
            },
        },
    },
    modalContent: {    
        [theme.breakpoints.down('xs')]: {
            padding: "16px"
        },
    },
    fullWidth: {
        [theme.breakpoints.down('xs')]: {
            width: "100% !important",
            minWidth: "210px",
        },
    },
}))

const OrderNotes = ({ handleClose, open, notes, id }) => {
    const classes = useStyles();

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className={classes.modal}
            >
                <DialogTitle id="form-dialog-title">
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box>
                            <Typography className={classes.title}>Order #{id}</Typography>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers className={classes.modalContent}>
                    <Typography className={classes.heading}>Order notes</Typography>
                    <Box my={2} width={300} className={classes.fullWidth}>
                        {
                            (notes && notes.length > 0) ? (
                                notes.map((item) => {
                                    return (
                                        <Box key={item.id}>
                                            <Box className={classes.comment} my={2} >
                                                {item.note || item.additional_information}
                                                <span className={classes.commentDesign} />
                                            </Box>
                                            {
                                                item.date_created && (
                                                    <Box color='#999' fontSize="11px" lineHeight='1.5'>
                                                        {moment(item.date_created).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    )
                                })
                            ) : <Typography className={classes.subHeading}>No Note found</Typography>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default OrderNotes
