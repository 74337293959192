import React, { useState, useCallback } from "react";
import {
  Button,
  Box,
  TextField,
  Typography,
  Paper,
  Stack,
  IconButton,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import { addNewNotes } from "../../../api";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core";
import TrackEvents from "../../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  activityDetailBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

const Note = ({ id, setRes, setOpen }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [alertString, setAlertString] = useState("");
  const [alert, setAlert] = useState(false);
  const [responseSeverity, setResponseSeverity] = useState("");
  const userid = localStorage.getItem("auth_id");
  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleAddNote = async () => {
    if (!value) {
      return;
    }
    const data = new FormData();
    data.append("notesendtocustomer", id);
    data.append("noteattachmentavailable", files.length);
    data.append("userid", userid);
    data.append("notetext", value);
    files.forEach((item) => data.append("noteattachmenturl[]", item));
    try {
      setLoading(true);
      let result = await addNewNotes(id, data);
      console.log("result from note", result);
      setRes(result.success);
      setLoading(false);
      setValue("");
      setAlert(true);
      setAlertString("Mail sent successfully");
      setResponseSeverity("success");
      setOpen(false);
      // TrackEvents("Note send In Pending Order Activity", {
      //   orderId: id,
      // });
      TrackEvents(`${id}`, {
        process: "Note sent Under Activity",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoading(false);
      setAlert(true);
      setAlertString("Something went wrong while sending message to the user");
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFiles(allfiles);
    }
  };

  const handleCancelUpload = (key) => {
    const filteredFiles = files.filter((item, i) => i !== key);
    setFiles(filteredFiles);
  };
  return (
    <Paper
      sx={{ p: 2, mt: 4, width: "70vw" }}
      className={classes.activityDetailBox}
    >
      {alert ? (
        <Alert
          severity={responseSeverity}
          sx={{
            margin: "10px 0 20px",
          }}
        >
          {alertString}
        </Alert>
      ) : null}
      <Typography
        sx={{
          mb: 2,
          color: "#3c434a",
          fontSize: "15px",
          lineHeight: "1.4em",
          fontWeight: "bold",
        }}
      >
        Add Note
      </Typography>
      {files[0] && (
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "4px",
            p: 1,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {files.map((file, key) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                key={key}
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  px: 1,
                  mr: 1,
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{ height: "20px" }}
                />
                <Box ml={2}>{file.name}</Box>
                <IconButton onClick={() => handleCancelUpload(key)}>
                  <ClearIcon size="small" />
                </IconButton>
              </Stack>
            );
          })}
        </Box>
      )}
      <TextField
        id="filled-multiline-flexible"
        multiline
        margin="dense"
        rows={4}
        value={value}
        onChange={handleChange}
        fullWidth
      />
      <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
        <Box mr={4}>
          <input
            multiple={true}
            type="file"
            style={{ display: "none" }}
            name="image"
            accept="image"
            onChange={handleFileUpload}
            id="contained-button-file"
          />
          <label htmlFor="contained-button-file" style={{ cursor: "pointer" }}>
            <AttachFileIcon fontSize="small" />
          </label>
        </Box>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          disabled={loading}
          onClick={handleAddNote}
        >
          Add
        </Button>
      </Box>
    </Paper>
  );
};

export default Note;
