import React, { useState, useCallback } from "react";
import { Typography, makeStyles, Dialog, DialogTitle, DialogContent, IconButton, Divider } from '@material-ui/core';
import { Button, Paper, Box } from "@mui/material";
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import { CustomTypography } from "../styles";
import SendIcon from "@mui/icons-material/Send";
import { addEnquirytNotes } from "../api";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
    subHeading: {
        color: '#3c434a',
        fontSize: '13px',
        lineHeight: '1.4em',
        fontWeight: 'bold',
        marginTop: '1rem',

    },
    heading: {
        fontSize: '14px',
        lineHeight: '1.4em',
        fontWeight: 'bold',
        marginBottom: '1rem'

    },
    typography: {
        fontSize: '13px',
        lineHeight: '1.4em',
    },
    title: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '1.5em',
        marginBottom: '1rem'
    },
    btn: {
        textTransform: 'none',
        marginLeft: '10px'
    },
    status: {
        fontSize: '1rem'
    },
    margin: {
        marginBottom: '10px'
    },
    image: {
        width: '250px'
    },
    paper: {
        padding: '1rem'
    },
    content: {
        color: '#777',
        lineHeight: '1.6em',
        fontSize: '14px',
        marginTop: '2px'
    },
    comment: {
        backgroundColor: '#d7cad2',
        padding: '10px',
        position: 'relative',
        lineHeight: '1.4',
        fontSize: '13px',
        color: '#3c434a',
    },
    commentDesign: {
        position: 'absolute',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #d7cad2',
        bottom: '-10px',
        left: '20px',
    },
    field: {
        marginTop: '10px'
    },
    textField: {
        '& .MuiInputBase-input': {
            fontSize: '14px',
            color: '#2c3338',
            paddingTop: '5px',
            paddingBottom: '5px',
        },
    },
    menu: {
        paddingTop: '2px',
        paddingBottom: '2px',
        fontSize: '14px',
    },
    modal: { 
        "& .MuiDialog-paper" : {
            [theme.breakpoints.down('xs')]: {
                margin: "16px",
                width: "100%",
            },
        },
    },
    modalContent: {    
        [theme.breakpoints.down('xs')]: {
            padding: "16px"
        },
    },
    fullWidth: {
        [theme.breakpoints.down('xs')]: {
            width: "100% !important",
            minWidth: "210px",
        },
    },
    notesListBox: {
        "& *": {
          marginTop: "0",
        },
        "& >*:last-child": {
          marginBottom: "0",
        },
    },
    editorclass: {
        padding: "0 8px 8px",
        minHeight: "100px",
    },
}))

const EnquiryNotes = ({ handleClose, open, setOpenNotes, notes, id }) => {
    const classes = useStyles();
    function createMarkup(data) {
        return { __html: data };
      }
      
    const [value, setValue] = useState({
        notemessage: "",
      });
      const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
      );
      const [loading, setLoading] = useState(false);
      const userid = localStorage.getItem("auth_id");
      const handleChange = useCallback((e) => {
        setValue(e.target.value);
      }, []);
        
      const handleAddNote = async () => {
        if (!value) {
          return;
        }
        const data = new FormData();
        data.append("enquirysendtocustomer", "0");
        data.append("userid", userid);
        data.append("notetext", value.notemessage);
        try {
          setLoading(true);
          let result = await addEnquirytNotes(id, data);
          console.log("result from note", result);
          setLoading(false);
          setValue({
            notemessage: "",
          });
          setOpenNotes(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
    
    const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setValue({
        ...value,
        ["notemessage"]: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
        ),
    });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className={classes.modal}
            >
                <DialogTitle id="form-dialog-title">
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box>
                            <Typography className={classes.title}>Enquiry #{id}</Typography>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers className={classes.modalContent}>
                    <Typography className={classes.heading}>Enquiry notes</Typography>
                    <Box my={2} width={300} className={classes.fullWidth}>
                    
                        {
                            notes && notes.length > 0 ? (
                                notes.map((item) => {
                                    return (
                                        <Box key={item.id}>
                                            <Box className={classes.comment} my={2} >
                                            <CustomTypography>
                                                <div
                                                    className={classes.notesListBox}
                                                    style={{ display: "inline-block" }}
                                                    dangerouslySetInnerHTML={createMarkup(
                                                        item.enquirynotetext[0].text
                                                    )}
                                                    ></div>
                                                </CustomTypography>
                                                <span className={classes.commentDesign} />
                                            </Box>
                                            <Box color="#999" fontSize="11px" lineHeight="1.5">
                                            {moment(item.enquirynote.created_at).format("MMMM Do YYYY, h:mm:ss a")}{" "}
                                            <span> By </span>
                                        </Box>
                                        </Box>
                                    )
                                })
                            ) : <Typography className={classes.subHeading}>No Note found</Typography>
                        }
                    </Box>
                    <Divider />
                    <Paper sx={{ mt: 2, boxShadow: "none", width: "300px" }} className={classes.fullWidth}>
                        <Typography
                            sx={{
                            mb: 2,
                            color: "#3c434a",
                            fontSize: "15px",
                            lineHeight: "1.4em",
                            fontWeight: "bold",
                            }}
                            className={classes.heading}
                        >
                            Add Note
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            mt={2}
                            p={0}
                            sx={{
                            border: 1,
                            borderColor: "grey.500",
                            borderRadius: 1,
                            }}
                        >
                            <Editor
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName={classes.editorclass}
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={onEditorStateChange}
                            placeholder="If you want to add any note, please add it here."
                            toolbar={{
                                options: ["blockType", "inline"],
                                inline: {
                                inDropdown: false,
                                options: ["bold", "italic", "underline", "strikethrough"],
                                },
                                blockType: {
                                inDropdown: false,
                                options: ["H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
                                },
                            }}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
                            <Button
                            variant="contained"
                            endIcon={<SendIcon />}
                            disabled={loading}
                            onClick={handleAddNote}
                            >
                            Add
                            </Button>
                        </Box>
                    </Paper>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EnquiryNotes
