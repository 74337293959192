import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
  formGroup: {
    flexDirection: "row !important",
    "& .MuiFormControlLabel-root": {
      width: "46%",
    },
  },
}));

const AddUserRole = (props) => {
  const {
    openModel,
    handleClose,
    handleAddUser,
    loading,
    userInfo,
    handleChange,
    handleChangeCheckbox,
    nameValError,
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Add User Role</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <TextField
          required
          helperText={nameValError.helperText}
          error={nameValError.error}
          size="small"
          label="User Role"
          name="rolename"
          type="text"
          fullWidth
          onChange={handleChange}
          value={userInfo.rolename}
          style={{ marginBottom: "20px" }}
        />
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="pending-order"
              />
            }
            label="Pending Orders"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="orders"
              />
            }
            label="Orders"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="leads"
              />
            }
            label="Leads"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="enquiry"
              />
            }
            label="Enquiry"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="product"
              />
            }
            label="Product"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="inventory"
              />
            }
            label="Inventory"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="print"
              />
            }
            label="Print Jobs"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="fulfilled"
              />
            }
            label="Fulfilled/Dispatch"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="settings"
              />
            }
            label="Setting"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="users"
              />
            }
            label="Users"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="user-roles"
              />
            }
            label="User Roles"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                name="rolepermissions[]"
                value="advanced-settings"
              />
            }
            label="Advanced Settings"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <Button
          onClick={handleAddUser}
          disabled={loading}
          variant="contained"
          size="small"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserRole;
