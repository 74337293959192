import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  Autocomplete,
  Stack,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  FormControl,
} from "@mui/material";
import Layout from "../../components/Layout";
import Alert from "@mui/material/Alert";
import { addEnquiry, getProducts, fetchAllCategoryForTool } from "../../api";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "14px !important",
    lineHeight: "1.4em",
    fontWeight: "bold !important",
    marginBottom: "16px !important",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    padding: "30px",
    "& .MuiFormControl-root": {
      marginBottom: "16px",
    },
  },
}));

const AddEnquiry = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [alertString, setAlertString] = useState("");
  const [alert, setAlert] = useState(false);
  const [responseSeverity, setResponseSeverity] = useState("info");
  const [fields, setFields] = useState({
    name: "",
    email: "",
    phone: "",
    product: "",
    quantity: "",
    message: "",
  });

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  // Product listing
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [addedProduct, setAddedProduct] = useState({});
  const [addedCategory, setAddedCategory] = useState(null);
  const [category, setCategory] = useState(null);

  const handleProducts = () => {
    if (Object.keys(addedProduct).length > 0) {
      setProducts([...products, addedProduct]);
      setAddedProduct({});
    }
  };

  useEffect(() => {
    const fetchproduct = async () => {
      var data;
      data = await getProducts(value, "", "variable");
      if (data.length == 0) {
        data = await getProducts(value, "", "simple");
      }
      setOptions(data);
    };
    fetchproduct();

    const fetchCategory = async () => {
      const cats = await fetchAllCategoryForTool();
      setCategory(cats);
    };
    fetchCategory();
  }, [value]);

  const handleCategory = (e) => {
    setAddedCategory(e.target.value);
  };

  const emailRegex = "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$";
  const [nameValError, setNameValError] = useState({
    helperText: "",
    error: false,
  });
  const [emailValError, setEmailValError] = useState({
    helperText: "",
    error: false,
  });
  const [phoneValError, setPhoneValError] = useState({
    helperText: "",
    error: false,
  });
  const [productValError, setProductValError] = useState({
    helperText: "",
    error: false,
  });
  const [quantityValError, setQuantityValError] = useState({
    helperText: "",
    error: false,
  });
  const [msgValError, setMsgValError] = useState({
    helperText: "",
    error: false,
  });

  const handleAddEnquiry = async () => {
    setNameValError({ helperText: "", error: false });
    if (fields.name.length < 2) {
      setNameValError({ helperText: "Please enter name", error: true });
      return;
    }
    if (fields.email.match(emailRegex)) {
      setEmailValError({ helperText: "", error: false });
    } else {
      setEmailValError({
        helperText: "Please enter a valid email address",
        error: true,
      });
      return;
    }
    // setPhoneValError({ helperText: "", error: false });
    // if (fields.phone.length < 8) {
    //   setPhoneValError({
    //     helperText: "Please enter valid phone number",
    //     error: true,
    //   });
    //   return;
    // }
    setProductValError({ helperText: "", error: false });
    if (addedProduct.length < 2) {
      setProductValError({ helperText: "Please enter product", error: true });
      return;
    }
    setQuantityValError({ helperText: "", error: false });
    if (fields.quantity.length < 1) {
      setQuantityValError({ helperText: "Please enter quantity", error: true });
      return;
    }
    setMsgValError({ helperText: "", error: false });
    if (fields.message.length < 2) {
      setMsgValError({ helperText: "Please enter message", error: true });
      return;
    }
    const userid = localStorage.getItem("auth_id");
    const username = localStorage.getItem("auth_name");
    var data = new FormData();
    data.append("comment", fields.message);
    data.append("ename", fields.name);
    data.append("eemail", fields.email);
    data.append("ephone", fields.phone);
    data.append("eproduct", addedProduct.id);
    data.append("equantity", fields.quantity);
    data.append("categoryid", addedCategory);
    data.append("assignuserid", userid);
    data.append("assignusername", username);
    try {
      setLoading(true);
      const res = await addEnquiry(data);
      if (res.status === 201) {
        setAlert(true);
        setAlertString("Enquiry added successfully");
        setResponseSeverity("success");
        setLoading(false);
        setFields({
          name: "",
          email: "",
          phone: "",
          quantity: "",
          message: "",
        });
        setAddedProduct({});
      }
    } catch (error) {
      setLoading(false);
      setAlert(true);
      setAlertString("Something went wrong while adding enquiry");
      setResponseSeverity("error");
      console.log("error from add enquiry", error);
    }
  };

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        <Box display="flex" alignItems="center" className={classes.secTopBar}>
          <Typography className={classes.heading}>Enquiry</Typography>
        </Box>
        <Paper className={classes.paper}>
          {alert ? (
            <Alert
              severity={responseSeverity}
              sx={{
                margin: "10px 0 20px",
              }}
            >
              {alertString}
            </Alert>
          ) : null}
          <TextField
            size="small"
            label="Name"
            placeholder="Name"
            name="name"
            type="text"
            fullWidth
            onChange={handleChange}
            value={fields.name}
            required
            helperText={nameValError.helperText}
            error={nameValError.error}
          />
          <TextField
            size="small"
            label="Email"
            placeholder="Email"
            name="email"
            type="email"
            fullWidth
            onChange={handleChange}
            value={fields.email}
            required
            helperText={emailValError.helperText}
            error={emailValError.error}
          />
          <TextField
            size="small"
            label="Phone"
            placeholder="Phone"
            name="phone"
            type="number"
            fullWidth
            onChange={handleChange}
            value={fields.phone}
            required
            // helperText={phoneValError.helperText}
            // error={phoneValError.error}
          />
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="update-producttype-category-label">
                Product Type Category
              </InputLabel>
              <Select
                id="update-producttype-category"
                value={addedCategory}
                onChange={handleCategory}
                name="update-producttype-category"
              >
                <MenuItem>Select Product Type Category</MenuItem>
                {category &&
                  category.map((item) => {
                    return (
                      <MenuItem key={item.term_id} value={item.term_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Autocomplete
              id="asynchronous-demo"
              size="small"
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => {
                return (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                );
              }}
              filterOptions={(x) => x}
              onInputChange={(event, newInputValue) => {
                setValue(newInputValue);
              }}
              options={options}
              onChange={(e, v) => {
                setOptions(v ? [v, ...options] : options);
                setValue(v);
                setAddedProduct(v);
              }}
              // loading={loading}
              sx={{ width: "100%", margin: "0" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product"
                  required
                  helperText={productValError.helperText}
                  error={productValError.error}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Stack>
          <TextField
            size="small"
            label="Quantity"
            placeholder="Quantity"
            name="quantity"
            type="number"
            fullWidth
            onChange={handleChange}
            value={fields.quantity}
            required
            helperText={quantityValError.helperText}
            error={quantityValError.error}
          />
          <TextField
            id="filled-multiline-flexible"
            multiline
            margin="dense"
            label="Message"
            placeholder="Message"
            name="message"
            rows={4}
            value={fields.message}
            onChange={handleChange}
            fullWidth
            required
            helperText={msgValError.helperText}
            error={msgValError.error}
          />
          <Button
            onClick={handleAddEnquiry}
            disabled={loading}
            variant="contained"
            size="small"
          >
            {loading ? "Adding" : "Add"}
          </Button>
        </Paper>
      </Box>
    </Layout>
  );
};

export default AddEnquiry;
