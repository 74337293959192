import React, { useState, useCallback } from "react";
import { Button, Box, TextField, Typography, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { addPrintJobNotes } from "../../../api";
import { makeStyles } from "@material-ui/core";
import TrackEvents from "../../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

const Note = ({ id, setRes, setOpen }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const userid = localStorage.getItem("auth_id");

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleAddNote = async () => {
    if (!value) {
      return;
    }
    const data = new FormData();
    data.append("userid", userid);
    data.append("printfiles", value);
    try {
      setLoading(true);
      let result = await addPrintJobNotes(id, data);
      console.log("result print from note", result);
      setRes(result.success);
      setLoading(false);
      setValue("");
      setOpen(false);
      TrackEvents(`${id}`, {
        process: "Print File Added In Pending Order",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 2, mt: 4, width: "70vw" }} className={classes.fullWidth}>
      <Typography
        sx={{
          mb: 2,
          color: "#3c434a",
          fontSize: "15px",
          lineHeight: "1.4em",
          fontWeight: "bold",
        }}
      >
        Add Note
      </Typography>
      <TextField
        id="filled-multiline-flexible"
        multiline
        margin="dense"
        rows={4}
        value={value}
        onChange={handleChange}
        fullWidth
      />
      <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          disabled={loading}
          onClick={handleAddNote}
        >
          Add
        </Button>
      </Box>
    </Paper>
  );
};

export default Note;
