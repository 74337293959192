const username = localStorage.getItem("auth_name");
const orderMockupBlank = (order = {}, msg = "") => {
  const template = `<!DOCTYPE html>
    <html lang="en">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <title>PrintYo</title>
        </head>
        <body style='height: 100%; margin: 0; -webkit-text-size-adjust: none; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; background-color: #f2f4f6; color: #51545e; width: 100%;'>
            <table class="email-wrapper" width="100%" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%; margin: 0; padding: 0; -premailer-width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; background-color: #f2f4f6;">
                <tr>
                    <td align="center" style='word-break: break-word; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; font-size: 16px;'>
                        <table class="email-content" width="100%" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%; margin: 0; padding: 0; -premailer-width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0;">
                            <tr>
                                <td class="email-masthead" style='word-break: break-word; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; font-size: 16px; padding: 25px 0; text-align: center;'>
                                    <a href="https://printyo.net.au/" class="f-fallback email-masthead_name" target="_blank"  style="font-size: 26px; font-weight: bold; color: #a8aaaf; text-decoration: none;">
                                        <img src="https://eadn-wc03-4707687.nxedge.io/cdn/wp-content/themes/printyo-new/images/logo.png" alt="Logo" />
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="email-body" width="570" cellpadding="0" cellspacing="0" style='word-break: break-word; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; font-size: 16px; width: 100%; margin: 0; padding: 0; -premailer-width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0;'>
                                    <table class="email-body_inner" align="center" width="570" cellpadding="0" cellspacing="0" role="presentation" style="width: 570px; margin: 0 auto; padding: 0; -premailer-width: 570px; -premailer-cellpadding: 0; -premailer-cellspacing: 0; background-color: #ffffff;">
                                        <tr>
                                            <td class="content-cell" style='color: #636363; word-break: break-word; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; font-size: 16px; padding: 45px;'>
                                                <div class="f-fallback">
                                                    <p style="margin: 0 0 16px;">Dear <b>${
                                                      order.billing?.first_name
                                                    } ${
    order.billing?.last_name || ""
  }</b>,</p>
                                                    <p style="margin: 0 0 16px;">${msg}</p>
                                                    <p style="margin: 0;">Best Regards</p>
                                                    <p style="margin: 0 0 15px">${username}</p>
                                                    <p style="margin: 0 0 5px;">w: <a href="https://printyo.net.au/" style='color: #636363;'>www.printyo.net.au </a>
                                                    </p>
                                                    <p style="margin: 0 0 5px;">t: <a href="tel:03 8657 9428" style='color: #636363;'>03 8657 9428 </a>
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style='word-break: break-word; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; font-size: 16px;'>
                                    <table class="email-footer" align="center" width="570" cellpadding="0" cellspacing="0" role="presentation" style="width: 570px; margin: 0 auto; padding: 0; -premailer-width: 570px; -premailer-cellpadding: 0; -premailer-cellspacing: 0; text-align: center; color: #a8aaaf;">
                                        <tr>
                                            <td class="content-cell" align="center" style='word-break: break-word; font-family: "Nunito Sans", Helvetica, Arial, sans-serif; font-size: 16px; padding: 45px;'>
                                                <p class="f-fallback sub align-center" style="margin: .4em 0 1.1875em; line-height: 1.625; text-align: center; font-size: 13px; color: #a8aaaf;">© 2021 Printyo. All rights reserved.</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </body>
    </html>`;
  return template;
};

export default orderMockupBlank;
