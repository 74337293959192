import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Appbar from "./Appbar";
import { Box, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Collapse from "@mui/material/Collapse";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mainWrapperInFull: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "57px",
    },
  },
  mainWrapperIn: {
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "57px",
    },
  },
  sidebarBox: {
    minWidth: "240px !important",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset !important",
    },
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const token = localStorage.getItem("auth_token");
  const authid = localStorage.getItem("auth_id");
  const history = useHistory();
  if (!(authid && token)) {
    localStorage.clear();
    history.push("/login");
  }

  const [toggleSidebar, setToggleSidebar] = useState(false)
  
  useEffect(() => {
    const media = window.matchMedia("(max-width: 960px)");
    if (media.matches) {
      setToggleSidebar(false);
    } else {
      setToggleSidebar(true);
    }

  }, []);   

  return (
    <Box display="flex" className={classes.mainWrapper}>
      <Collapse orientation="horizontal" in={toggleSidebar} className={toggleSidebar?
          classes.sidebarBox
            :null}>
        <SideBar setToggleSidebar={setToggleSidebar} />
      </Collapse>

      <Box
        display="flex"
        flexDirection="column"
        mt="63px"
        flexGrow={1}
        className={ toggleSidebar? 
          classes.mainWrapperIn
          : classes.mainWrapperInFull
          }>
        <Appbar
          setToggleSidebar={setToggleSidebar}
          toggleSidebar={toggleSidebar}
        />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
