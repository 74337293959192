import React, { useEffect } from "react";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  IconButton,
  Box,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import PrintIcon from "@material-ui/icons/Print";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import QuizIcon from "@mui/icons-material/Quiz";
import PendingIcon from "@mui/icons-material/Pending";
import { NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from "@mui/icons-material/Category";
import BarChartIcon from "@mui/icons-material/BarChart";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PersonIcon from "@mui/icons-material/Person";
// import PushPin from '@mui/icons-material/PushPin';
import ColorizeIcon from "@mui/icons-material/Colorize";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  activeStyle: {
    fontWeight: "bold",
    "& .MuiListItem-root": {
      color: "#f50057",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "& svg": {
      color: "#f50057",
    },
  },
  logo: {
    height: "55px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  closeButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      marginLeft: 16,
    },
  },
}));

const ListData = [
  {
    icon: <DashboardIcon />,
    label: "Dashboard",
    link: "",
  },
  {
    icon: <PendingIcon />,
    label: "Pending Orders",
    link: "pending-order/1",
  },
  // {
  //   icon: <PushPin sx={{transform: "rotate(45deg)"}}/>,
  //   label: "Important Orders",
  //   link: "important-order/1",
  // },
  {
    icon: <AssessmentIcon />,
    label: "Orders",
    link: "orders/1",
  },
  {
    icon: <InboxIcon />,
    label: "Leads",
    link: "leads",
  },
  {
    icon: <QuizIcon />,
    label: "Enquiry",
    link: "enquiry/1",
  },
  {
    icon: <CategoryIcon />,
    label: "Product",
    link: "product/1",
  },
  {
    icon: <ShoppingBasketIcon />,
    label: "Inventory",
    link: "inventory/1",
  },

  {
    icon: <PrintIcon />,
    label: "Print Jobs",
    link: "print/1",
  },
  {
    icon: <LocalShippingIcon />,
    label: "Fulfilled/Dispatch",
    link: "fulfilled/1",
  },
  {
    icon: <CreditCardIcon />,
    label: "Shipping Pricing",
    link: "shipping-price/1",
  },
  {
    icon: <PersonIcon />,
    label: "Customers",
    link: "customers",
  },
  {
    icon: <ColorizeIcon />,
    label: "Print On Demand",
    link: "pods",
  },
  {
    icon: <BarChartIcon />,
    label: "Analytics",
    link: "analytics",
  },
  {
    icon: <SettingsIcon />,
    label: "Settings",
    link: "Settings",
  },
  {
    icon: <PersonAddIcon />,
    label: "Users",
    link: "users",
  },
  {
    icon: <AccountBoxRoundedIcon />,
    label: "User Roles",
    link: "user-roles",
  },
  {
    icon: <SettingsApplicationsIcon />,
    label: "Advanced settings",
    link: "advanced-settings",
  },
  {
    icon: <LocalShippingIcon />,
    label: "Thomastown Pickup (AC:0007708009)",
    link: "auspost",
  },
  {
    icon: <LocalShippingIcon />,
    label: "Keon Park Drop Off (AC:0007654171)",
    link: "auspost-label",
  },
  {
    icon: <PictureAsPdfIcon />,
    label: "AusPost Manifests",
    link: "generatedorder-auspost",
  },
  {
    icon: <RequestQuoteIcon />,
    label: "Monthly Manifest Cost",
    link: "monthly-manifest",
  },
  {
    icon: <SettingsApplicationsIcon />,
    label: "Logs",
    link: "logs",
  },
];

const SideBar = (props) => {
  const { setToggleSidebar } = props;
  const classes = useStyles();

  useEffect(() => {
    function setNavigation() {
      let current_location = window.location.pathname.split("/")[1];
      if (current_location === "") return;
      let nav = window.document.getElementById("nav");
      let menu_items = nav.getElementsByTagName("a");
      for (var i = 0, len = menu_items.length; i < len; i++) {
        if (
          menu_items[i].getAttribute("href").indexOf(current_location) !== -1
        ) {
          menu_items[i].className = `${classes.activeStyle}`;
        }
      }
    }
    setNavigation();
  });

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <Box display="flex" alignItems="center" ml={2}>
            <img
              src="https://www.printyo.net.au/wp-content/themes/printyo-new/images/logo.png"
              alt="Printyo Australia"
              title="Printyo Australia"
              className={classes.logo}
            />
            <IconButton
              edge="end"
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setToggleSidebar(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
        <Divider />
        <List id="nav">
          {ListData.map((item, index) => (
            <NavLink
              to={`/${item.link}`}
              key={item.label}
              exact={true}
              strict
              style={{ textDecoration: "none", color: "#6B7280" }}
              onClick={() => {
                window.location.href = `/${item.link}`;
              }}
            >
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
