import React, { useState } from "react";
import { Box, Paper, Grid, TextField, Button, Typography } from "@mui/material";
import loginimg from "./login.jpg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { getLoginDetails } from "../../api";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },

  errorIcon: {
    color: "#fff",
    width: "26.67px",
    height: "26.67px",
  },
  heading: {
    marginBottom: 6,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    color: "#11142D",
  },
  paper: {
    margin: theme.spacing(8, 4),
    justifyContent: "center",
    maxWidth: "583px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    height: "48px",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgb(5 124 255)",
    },
  },
  InputLabel: {
    marginTop: ".7rem",
    marginBottom: ".2rem",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "120%",
  },
  InputText: {
    marginTop: "0",
    boxSizing: "border-box",
    "& .MuiOutlinedInput-root": {
      "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        WebkitBoxShadow: "0 0 0px 1000px white inset",
      },
      "& input": {
        fontWeight: "normal",
        fontSize: "16px",
        letterSpacing: "0.005em",
        lineHeight: "120%",
        color: "#11142D",
      },
    },
  },
  errorText: {
    fontSize: "12px",
    color: "#d32f2f",
    margin: "-5px 0 0",
  },
  errorText2: {
    fontSize: "12px",
    color: "#d32f2f",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgb(0, 171, 85)",
  color: "#fff",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "initial",
  boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
  marginTop: "10px",
  borderRadius: "8px",
  padding: "10px 30px",
  "&:hover": {
    backgroundColor: "rgb(0, 123, 85)",
  },
}));

const LoginPage = () => {
  let rolepermissions = [];
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginCredError, setLoginCredError] = useState(false);
  const initialValues = { email: "", password: "" };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    var data = new FormData();
    data.append("email", values.email);
    data.append("password", values.password);
    try {
      axios
        .get("https://printyocrm.printyo.net.au/sanctum/csrf-cookie")
        .then(async (response) => {
          try {
            setLoading(true);
            const responses = await getLoginDetails(data);
            responses.usercapbility &&
              responses.usercapbility.map((capibility) => {
                rolepermissions = [
                  ...rolepermissions,
                  capibility.role_capabilities,
                ];
              });
            localStorage.setItem("auth_token", responses.token);
            localStorage.setItem("auth_id", responses.user.id);
            localStorage.setItem("auth_name", responses.user.name);
            localStorage.setItem("auth_role", responses.userrole);
            localStorage.setItem("auth_email", responses.user.email);
            localStorage.setItem(
              "auth_permission",
              JSON.stringify(rolepermissions)
            );
            console.log(rolepermissions);
            setSubmitting(false);
            history.push("/");
          } catch (error) {
            setLoginCredError("Invalid login credentials");
            console.log("something went wrong", error);
            setSubmitting(false);
            setLoading(false);
          }
        });
    } catch (error) {
      setLoginCredError("Invalid login credentials");
      console.log("something went wrong", error);
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100%"
      minHeight="100vh"
      backgroundColor="#fff"
    >
      <Box width="100%">
        <Paper sx={{ padding: "1rem", boxShadow: "none" }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={4}>
              <Box pr={2}>
                <img
                  src={loginimg}
                  alt="Login Printyo Australia"
                  title="Login"
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box textAlign="left" maxWidth="500px" ml="auto" mr="auto">
                <Box textAlign="center" mb={2}>
                  <img
                    src="https://eadn-wc03-4707687.nxedge.io/cdn/wp-content/themes/printyo-new/images/logo.png"
                    alt="Printyo Australia"
                    title="Printyo Australia"
                    style={{ height: "55px" }}
                  />
                </Box>
                <Typography component="p" className={classes.errorText2}>
                  {loginCredError}
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    isSubmitting,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                  }) => (
                    <Form className={classes.form}>
                      <Field
                        name="email"
                        autoComplete="email"
                        label="Email"
                        style={{ marginBottom: "12px" }}
                        as={TextField}
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        className={classes.InputText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className={classes.errorText}
                      />
                      <Field
                        variant="outlined"
                        style={{ marginBottom: "12px" }}
                        autoComplete="current-password"
                        as={TextField}
                        margin="normal"
                        fullWidth
                        label="Password"
                        name="password"
                        type="password"
                        className={classes.InputText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        className={classes.errorText}
                      />
                      <ColorButton
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {loading ? (
                          <CircularProgress
                            style={{
                              color: "#fff",
                              width: "25px",
                              height: "25px",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {loading ? "Processing" : "Login"}
                      </ColorButton>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default LoginPage;
