import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Container,
  TablePagination,
} from "@material-ui/core";
import {
  Typography,
  Button,
  Stack,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  getInventoryitem,
  deleteProductFromProductType,
  getInventoryProductTypeById,
} from "../../api";
import moment from "moment";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider } from "@material-ui/core";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import CloseIcon from "@material-ui/icons/Close";
import AddProducts from "./AddProducts";
import Layout from "../../components/Layout";
import SearchIcon from "@mui/icons-material/Search";

function EnhancedTableHead(props) {
  const handleAllChecked = (event) => {};
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            // inputProps={{ 'aria-label': 'select all desserts' }}
            onClick={handleAllChecked}
            value="checkedall"
          />
        </TableCell>
        <TableCell>ID</TableCell>
        <TableCell> Product</TableCell>
        <TableCell>SKU</TableCell>
        <TableCell>Created at</TableCell>
        <TableCell>Added by</TableCell>
        <TableCell>Message</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    marginTop: "1rem",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
    marginBottom: "1rem",
  },
  btn: {
    textTransform: "none",
    marginLeft: "10px",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  paper: {
    margin: "0 0 1rem",
  },
  content: {
    color: "#777",
    lineHeight: "1.6em",
    fontSize: "14px",
    marginTop: "2px",
  },
  comment: {
    backgroundColor: "#d7cad2",
    padding: "10px",
    position: "relative",
    lineHeight: "1.4",
    fontSize: "13px",
    color: "#3c434a",
  },
  commentDesign: {
    position: "absolute",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid #d7cad2",
    bottom: "-10px",
    left: "20px",
    modal: {
      "& .MuiDialog-paper": {
        [theme.breakpoints.down("xs")]: {
          margin: "16px",
          width: "100%",
        },
      },
    },
    fullWidth: {
      [theme.breakpoints.down("xs")]: {
        padding: "16px !important",
        width: "100% !important",
        minWidth: "210px",
      },
    },
  },
  searchInputBox: {
    marginLeft: "auto !important",
  },
}));

const MessageBox = ({ handleClose, msgPopup, message, id, created_at }) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={msgPopup}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography className={classes.title}>Product #{id} </Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.heading}>Message</Typography>
          <Box my={2} width={300}>
            <Box>
              <Box className={classes.comment} my={2}>
                {message}
                <span className={classes.commentDesign} />
              </Box>
              <Box color="#999" fontSize="11px" lineHeight="1.5">
                {moment(created_at).format("MMMM Do YYYY, h:mm:ss a")}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[4];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

const ProductCategory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { producttypeId } = useParams();
  const [openModel, setOpenModel] = useState(false);
  const [products, setProducts] = useState([]);
  const [msgPopup, setMsgPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delproduct, setDelProduct] = useState([]);
  const [productInfo, setProductInfo] = useState({});

  const handleMsgPopup = useCallback((product) => {
    setSelectedProduct(product);
    setMsgPopup(true);
  }, []);

  const handleClose = () => {
    setMsgPopup(false);
  };
  const handleModalClose = () => {
    setOpenModel(false);
  };

  // Delete Popup
  const [openDeletepop, setDeletePop] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);

  const handleOpenDeletePop = () => {
    if (delproduct && delproduct.length > 0) {
      setDeletePop(true);
      setErrorDelete(false);
    } else setErrorDelete("Please select product(s) first");
  };

  const handleCloseDeletePop = () => {
    setDeletePop(false);
  };

  const currentPageId = parseInt(getTheCurrentUrl());
  const [page, setPage] = React.useState(currentPageId);
  const [count, setCount] = useState(0);

  const currentRowsPerPage = localStorage.getItem("inventoryCatRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(currentRowsPerPage) ? parseInt(currentRowsPerPage) : 10
  );
  localStorage.setItem("inventoryCatNewPage", currentPageId + 1);

  // Search
  const [search, setSearch] = useState("");
  const [searchvalue, setSearchValue] = React.useState("");
  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setPage(0);
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("inventoryCatNewPage");
    } else {
      localStorage.setItem("inventoryCatNewPage", e.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/inventory/${producttypeId}/details/${newPage + 1}`,
    });
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const res = await getInventoryitem(
          producttypeId,
          page + 1,
          rowsPerPage,
          search
        );
        if (res.status === 200) {
          setProducts(res.product_of_producttype);
          setCount(res.count);
          console.log(res);
        }
        setLoading(false);
      } catch (error) {
        console.log("error from producttype item", error);
      }
    };
    if (producttypeId) {
      fetchProduct();
    }
  }, [producttypeId, page, res, rowsPerPage, search]);

  const deleteProductsByIds = async () => {
    const data = new FormData();
    delproduct.forEach((item) => data.append("id[]", item));
    data.append("_method", "delete");
    setLoading(true);
    let result = await deleteProductFromProductType(data);
    console.log("result from post", result);
    setLoading(false);
    setRes(result.success);
    setDelProduct([]);
    setDeletePop(false);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const inventorydata = await getInventoryProductTypeById(producttypeId);
        if (inventorydata.status === 200) {
          setProductInfo(inventorydata.producttype);
        }
      } catch (error) {
        console.log("error form inventory", error);
      }
    };
    fetchProductData();
  }, [producttypeId, loading]);

  return (
    <>
      <Layout>
        <Container>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "1.5em",
                my: 2,
              }}
            >
              Product type Id # {producttypeId} ({productInfo.producttype})
            </Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={() => setOpenModel(true)}
            >
              Add product
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={handleOpenDeletePop}
            >
              Delete Products
            </Button>
            <Stack
              direction="row"
              justifyContent="flex-end"
              className={classes.searchInputBox}
            >
              <form onSubmit={handleSearchSubmit}>
                <TextField
                  id="outlined-start-adornment"
                  className={classes.searchInput}
                  sx={{
                    width: "30ch",
                    backgroundColor: "#fff",
                    fontSize: "14px",
                  }}
                  size="small"
                  placeholder="search"
                  onChange={handleSearch}
                  value={searchvalue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon onClick={handleSearchSubmit} />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Stack>
          </Stack>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "red",
              lineHeight: "1.5em",
              mb: 2,
            }}
          >
            {errorDelete}
          </Typography>
          <Paper className={classes.paper}>
            {loading && <LinearProgress />}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead />
                <TableBody>
                  {products?.map((product, index) => {
                    let date = moment(product.created_at).format("ll");
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={product.id}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              value={product.id}
                              onChange={(e) => {
                                let value = e.target.value;
                                console.log(value);
                                if (e.target.checked) {
                                  setDelProduct([...delproduct, value]);
                                } else {
                                  setDelProduct(
                                    delproduct.filter((item) => item !== value)
                                  );
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>{product.id}</TableCell>
                          <TableCell>{product.productname}</TableCell>
                          <TableCell>{product.sku}</TableCell>
                          <TableCell>{date}</TableCell>
                          <TableCell>Admin</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleMsgPopup(product)}>
                              <ChatOutlinedIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              {loading && <LinearProgress />}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={count > 0 ? page : 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <MessageBox
              msgPopup={msgPopup}
              handleClose={handleClose}
              {...selectedProduct}
            />
            <AddProducts
              open={openModel}
              handleClose={handleModalClose}
              res={res}
              setRes={setRes}
              id={producttypeId}
            />
          </Paper>
        </Container>
      </Layout>
      <Dialog
        open={openDeletepop}
        onClose={handleCloseDeletePop}
        aria-labelledby="form-dialog-title"
        className={classes.modal}
      >
        <DialogContent
          dividers
          sx={{ width: 500 }}
          className={classes.fullWidth}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TypographyHeading>Delete Products</TypographyHeading>
            <IconButton onClick={handleCloseDeletePop}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <TypographySubHeading>
            Do you really wnat to delete these products?
          </TypographySubHeading>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => deleteProductsByIds()}
            variant="contained"
            size="small"
            color="primary"
            disabled={loading}
          >
            {loading ? "Processing" : "Confirmed"}
          </Button>
          <Button
            onClick={handleCloseDeletePop}
            variant="outlined"
            size="small"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductCategory;
