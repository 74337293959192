import React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material';
import { TextField, makeStyles } from "@material-ui/core";
import { Formik, Form, Field } from 'formik';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: '100%',
            "& label": {
                fontSize: '14px'
            },
            "& input": {
                fontSize: '14px'
            }
        }
    },
}))

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            fontWeight: 500,
            fontSize: '14px',
        },
        invalid: {
            // iconColor: '#ffc7ee',
            // color: '#ffc7ee'
        }
    }
}


const PaymentForm = ({ order }) => {
    const stripe = useStripe()
    const elements = useElements()
    const classes = useStyles()

    const handleSubmit = async () => {
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        })
        console.log('result', result)
        if (!result.error) {
            try {
                const { id } = result.paymentMethod
                console.log('id', id)
                // const res = await axios.post()
            } catch (error) {

            }
        }
    }

    const initialState = {
        email: order.billing.email || '', address_1: order.billing.address_1 || '', address_2: order.billing.address_2 || '', city: order.billing.city || "", company: order.billing.company || "", country: order.billing.country || "", first_name: order.billing.first_name || "", last_name: order.billing.last_name || "", phone: order.billing.phone || "", postcode: order.billing.postcode || "", state: order.billing.state || ''
    }


    return (
        <div>
            <Formik
                initialValues={initialState}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className={classes.root}>
                        <Stack spacing={2}>
                            <Typography sx={{
                                color: '#3c434a', fontSize: '14px', fontWeight: 'bold', my: 1
                            }}>Billing details </Typography>
                            <Field name="first_name" size="small" as={TextField} variant="outlined" placeholder="First Name" label='First Name' />
                            <Field name="Last_name" size="small" as={TextField} variant="outlined" placeholder="Last Name" label='Last Name' />
                            <Field name="email" size="small" as={TextField} variant="outlined" placeholder="Email" label='Email' />
                            <Field name="phone" size="small" as={TextField} variant="outlined" placeholder="Phone" label='Phone' />
                            <Field name="company" size="small" as={TextField} variant="outlined" placeholder="Company" label='Company' />
                            <Field name="address_1" size="small" as={TextField} variant="outlined"
                                placeholder="Address1" label='Address1' />
                            <Field name="address_2" size="small" as={TextField} variant="outlined"
                                placeholder="Address2" label='Address2' />
                            <Stack direction='row' justifyContent='space-between' spacing={1} alignItems='center'>
                                <Field name="city" size="small" as={TextField} variant="outlined" placeholder="City" label='City' />
                                <Field name="state" size="small" as={TextField} variant="outlined" placeholder="State" label='State' />
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' spacing={1} alignItems='center'>
                                <Field name="country" size="small" as={TextField} variant="outlined" placeholder="Country" label='Country' />
                                <Field name="postcode" size="small" as={TextField} variant="outlined" placeholder="Postcode" label='Postcode' />
                            </Stack>
                            <fieldset className='FormGroup' style={{ border: '1px solid rgba(0, 0, 0, 0.35)', borderRadius: '4px', marginTop: '10px' }}>
                                <Box className='FormRow' >
                                    <CardElement options={CARD_OPTIONS} />
                                </Box>
                            </fieldset>
                            <Button type="submit" disabled={!stripe || !elements} variant='contained' sx={{ mt: 2 }}>
                                Pay ${order.total}
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default PaymentForm
