import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Container,
  Typography,
  Box,
  ImageList,
  ImageListItem,
  Button,
  ImageListItemBar,
  Paper,
  Divider,
  CircularProgress,
} from "@mui/material";
import Mail from "./Mail";
import { getAllMockup, getMockupMail, getApprovedMockup } from "./../../../api";
import moment from "moment";
import { styled } from "@mui/material/styles";
import ConfirmedApprovePopup from "./ApproveMockup";
import UnapprovePopup from "./UnapproveMockup";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core";
import TrackEvents from "../../../api/amplitudeEvents";

const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  activityBtnAll: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      margin: "16px 0 0 !important",
    },
  },
  activityDetailBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  activityEmailBox: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      overflowX: "auto",
    },
    "& #wrapper": {
      [theme.breakpoints.down("xs")]: {
        overflowX: "auto",
      },
    },
    "& .gmail_quote": {
      paddingLeft: "0 !important",
      margin: "16px 0px 0px 0 !important",
    },
    "& .gmail_quote >div>div": {
      [theme.breakpoints.down("xs")]: {
        overflowX: "auto",
      },
    },
    "& .gmail_signature *": {
      width: "auto !important",
    },
    "& table": {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "500px !important",
      },
    },
  },
  mockupImgList: {
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr) !important",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr) !important",
    },
    "& .MuiImageListItem-root": {
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
  },
}));

const Mockup = ({ order, id, print = "" }) => {
  const classes = useStyles();
  const [openMail, setOpenMail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [mockupList, setMockupList] = useState([]);
  const [res, setRes] = useState(false);
  const [approveId, setApproveId] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { fulfilled } = useParams();
  const [mockuploading, setMockupLoading] = useState(false);
  const [unapproveId, setUnapproveId] = useState(null);
  const [openUnapprovePopup, setOpenUnapprovePopup] = useState(false);

  const handleApproveMockup = async (ID = "") => {
    if (ID) {
      setApproveId(ID);
      setOpenPopup(true);
    }
  };

  const handleConfirmedClose = () => {
    setOpenPopup(false);
  };

  const handleUnapproveMockup = async (ID = "") => {
    if (ID) {
      setUnapproveId(ID);
      setOpenUnapprovePopup(true);
    }
  };

  const handleUnapproveClose = () => {
    setOpenUnapprovePopup(false);
  };

  useEffect(() => {
    const fetchMockupImgs = async () => {
      try {
        setMockupLoading(true);
        setLoading(true);
        const data = await getAllMockup(id);
        if (data.status === 200) {
          setMockupList(data.approvedmockup);
        }
        setLoading(false);
        setMockupLoading(false);
      } catch (error) {
        console.log("error from mockup", error);
        setLoading(false);
        setMockupLoading(false);
      }
    };
    const fetchEmail = async () => {
      try {
        const data = await getMockupMail(id);
        if (data.status === 200) {
          setEmails(data.ordersnote);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (id && !print) {
      fetchMockupImgs();
    }
    if (id) {
      fetchEmail();
    }
  }, [id, res, print]);

  useEffect(() => {
    const fetchMockupImgs = async () => {
      try {
        setLoading(true);
        setMockupLoading(true);
        const data = await getApprovedMockup(id);
        if (data.status === 200) {
          setMockupList(data.approvedmockups);
        }
        setLoading(false);
        setMockupLoading(false);
      } catch (error) {
        console.log("error from mockup", error);
        setLoading(false);
        setMockupLoading(false);
      }
    };
    if (id && print) {
      fetchMockupImgs();
    }
  }, [id, print]);

  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <Container className={classes.container}>
      <ConfirmedApprovePopup
        open={openPopup}
        handleClose={handleConfirmedClose}
        id={approveId}
        orderId={id}
        setApproveId={setApproveId}
        res={res}
        setRes={setRes}
      />
      <UnapprovePopup
        open={openUnapprovePopup}
        handleClose={handleUnapproveClose}
        id={unapproveId}
        orderId={id}
        setUnapproveId={setUnapproveId}
        res={res}
        setRes={setRes}
      />
      <Stack alignItems="center">
        {mockuploading ? <CircularProgress color="inherit" /> : null}
      </Stack>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "1.5em",
            mb: 2,
          }}
        >
          Mockup Images
        </Typography>
        {!print && !fulfilled && (
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => setOpenMail(!openMail)}
          >
            Add Mockup
          </Button>
        )}
      </Box>
      {openMail && <Mail order={order} setRes={setRes} res={res} />}
      <Box display="flex" justifyContent="space-around" mt={2}>
        {mockupList && mockupList.length > 0 && !print ? (
          <ImageList
            cols={3}
            sx={{ width: "100%" }}
            className={classes.mockupImgList}
          >
            {mockupList.map((item) => (
              <ImageListItem
                key={item.emailmockupsattachments?.attachmenturl}
                sx={{ width: 300 }}
              >
                <img
                  src={`https://printyocrm.printyo.net.au/${item.emailmockupsattachments?.attachmenturl}?w=248&fit=crop&auto=format`}
                  srcSet={`https://printyocrm.printyo.net.au/${item.emailmockupsattachments?.attachmenturl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt="mockupImage"
                  loading="lazy"
                  style={{
                    backgroundColor: "#fff",
                    objectFit: "scale-down",
                  }}
                />
                <ImageListItemBar
                  title={item.node}
                  subtitle={
                    <span>
                      {item.approved === "Not Approved" ? (
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            color: "primary",
                            "&.Mui-disabled": {
                              color: "#1976d2 !important",
                              border: "1px solid #1976d2",
                            },
                          }}
                          size="small"
                          onClick={() =>
                            handleApproveMockup(
                              item.emailmockupsattachments?.id
                            )
                          }
                          // disabled={
                          //   loading || !(item.approved === "Not Approved")
                          // }
                        >
                          Approve Mockup
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            color: "primary",
                            "&.Mui-disabled": {
                              color: "#1976d2 !important",
                              border: "1px solid #1976d2",
                            },
                          }}
                          size="small"
                          onClick={() =>
                            handleUnapproveMockup(
                              item.emailmockupsattachments?.id
                            )
                          }
                        >
                          Disapprove
                        </Button>
                      )}
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        href={`https://printyocrm.printyo.net.au/${item.emailmockupsattachments?.attachmenturl}?w=248&fit=crop&auto=format`}
                        target="_blank"
                        sx={{
                          textTransform: "none",
                          marginLeft: "16px",
                          color: "#fff !important",
                          textDecoration: "none !important",
                        }}
                      >
                        View Full Image
                      </Button>
                    </span>
                  }
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : mockupList && mockupList.length > 0 && print ? (
          <>
            <ImageList
              cols={3}
              sx={{ width: "100%" }}
              className={classes.mockupImgList}
            >
              {mockupList.map((item) => (
                <>
                  {item?.approvedmockup.map((prod) => (
                    <>
                      <ImageListItem
                        key={prod?.attachmenturl}
                        sx={{ width: 300 }}
                      >
                        <img
                          src={`https://printyocrm.printyo.net.au/${prod?.attachmenturl}?w=248&fit=crop&auto=format`}
                          srcSet={`https://printyocrm.printyo.net.au/${prod?.attachmenturl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt="mockupImage"
                          loading="lazy"
                        />
                        <ImageListItemBar
                          title={item.node}
                          subtitle={
                            <span>
                              <Button
                                variant="contained"
                                sx={{
                                  textTransform: "none",
                                  color: "primary",
                                  "&.Mui-disabled": {
                                    color: "#1976d2 !important",
                                    border: "1px solid #1976d2",
                                  },
                                }}
                                size="small"
                                onClick={() => handleUnapproveMockup(prod?.id)}
                              >
                                Disapprove
                              </Button>
                              <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                href={`https://printyocrm.printyo.net.au/${prod.attachmenturl}?w=248&fit=crop&auto=format`}
                                target="_blank"
                                sx={{
                                  textTransform: "none",
                                  color: "#fff !important",
                                  marginLeft: "16px",
                                  textDecoration: "none !important",
                                }}
                              >
                                View Full Image
                              </Button>
                            </span>
                          }
                          position="below"
                        />
                      </ImageListItem>
                    </>
                  ))}
                </>
              ))}
            </ImageList>
          </>
        ) : (
          <Typography>No mockup</Typography>
        )}
      </Box>
      <Paper
        sx={{ p: 3, mt: 4, width: "70vw" }}
        className={classes.activityDetailBox}
      >
        <Typography>Emails</Typography>
        <Divider />
        {emails
          ? emails.map((item) => {
              return (
                <Box key={item.id}>
                  <Box
                    sx={{
                      backgroundColor: "#d7cad2",
                      padding: "10px",
                      position: "relative",
                      lineHeight: "1.4",
                      fontSize: "13px",
                      color: "#3c434a",
                    }}
                    my={2}
                  >
                    <Box display="flex" alignItems="center">
                      <CustomTypography sx={{ mr: 2 }}>
                        Customer email
                      </CustomTypography>
                      <TypographySubHeading>
                        {item.emailmockups?.customeremail}
                      </TypographySubHeading>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <CustomTypography sx={{ mr: 2 }}>
                        Subject
                      </CustomTypography>
                      <TypographySubHeading>
                        {item.emailmockups?.emailsubject}
                      </TypographySubHeading>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <div
                        className={classes.activityEmailBox}
                        dangerouslySetInnerHTML={createMarkup(
                          item.emailmockups?.emailmessage
                        )}
                      ></div>
                    </Box>
                    <Box>
                      {item.emailmockupsattachments
                        ? item.emailmockupsattachments.map((img) => {
                            return (
                              <Box
                                key={img.id}
                                display="flex"
                                alignItems="center"
                              >
                                <CustomTypography sx={{ mr: 2 }}>
                                  Image url:{" "}
                                </CustomTypography>
                                <a
                                  href={`https://printyocrm.printyo.net.au/${img.attachmenturl}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <CustomTypography sx={{ mr: 2 }}>
                                    view img{" "}
                                  </CustomTypography>
                                </a>
                              </Box>
                            );
                          })
                        : null}
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        borderTop: "10px solid transparent",
                        borderBottom: "10px solid transparent",
                        borderLeft: "10px solid #d7cad2",
                        bottom: "-10px",
                        left: "20px",
                      }}
                    />
                  </Box>
                  <Box color="#999" fontSize="11px" lineHeight="1.5">
                    {moment(item.emailmockups?.created_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}{" "}
                    By{" "}
                    {item.emailmockupuser
                      ? item.emailmockupuser.name
                      : "Customer"}
                  </Box>
                </Box>
              );
            })
          : null}
      </Paper>
    </Container>
  );
};

export default Mockup;
