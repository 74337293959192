import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
  Paper,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import OrderInfo from "../order/OrderInfo";
import {
  getOrderImg,
  getCustomerNotes,
  getImportantOrders,
  getOrderByStatus,
} from "../../api";
// import moment from "moment";
import ArtworkDetail from "../../components/ArtworkDetails";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import OrderNotes from "../../components/OrderNotes";
import OrderStatus from "../../components/utils/OrderStatus";
import Layout from "../../components/Layout";
import UnpinPopup from "./UnpinPop";
import PushPin from '@mui/icons-material/PushPin';

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}
function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell> Order </TableCell>
        <TableCell>Order details</TableCell>
        <TableCell>Artwork details</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Notes</TableCell>
        <TableCell>Important</TableCell>
      </TableRow>
    </TableHead>
  );
}

const UnpinComponent = ({ row, res, setRes }) => {
  const classes = useStyles();
  const [openPinpop, setPinPop] = useState(false);

  const handleClosePinPop = () => {
    setPinPop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          fontSize="small"
          className={classes.pinIcon}
          onClick={() => setPinPop(true)}
        >
          <PushPin className={classes.pinnedOrder} sx={{transform: "rotate(45deg)"}} fontSize="small" />
        </IconButton>
      </Box>
      <UnpinPopup
        open={openPinpop}
        handleClose={handleClosePinPop}
        id={row.id}
        res={res}
        setRes={setRes}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pinnedOrder: {
    color: "rgb(215 0 0)",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 850,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      margin: "16px 0 0 !important",
    },
  },
  addOrderbtn2: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  popupcontainer: {
    ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
      boxShadow: "none",
    },
  },
  alreadyClicked: {
    backgroundColor: "#e8e8e8",
  },
  orderbybox: {
    backgroundColor: "#fff",
    padding: "1rem",
    marginTop: "1rem",
    borderRadius: "4px",
  },
  orderbyButton: {
    display: "inline-block",
    padding: "9px 10px",
    minWidth: "100px",
    [theme.breakpoints.down('xs')]: {
      minWidth: "75px",
    },
  },  
  secTopBtns: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      margin: "16px 0 0 !important",
    },
  },
  secBox: {
    [theme.breakpoints.down('xs')]: {
      padding: "16px !important",
    },
  },
  secTopBar: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap",
    },
  },
  searchInputBox: {    
    [theme.breakpoints.down('xs')]: {
      display: "block !important",
    },
  },
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down('xs')]: {
      width: "100% !important",
    },
  },
}));

export default function EnhancedTable() {
  var moment = require("moment-timezone");
  const currentPageId = parseInt(getTheCurrentUrl());
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const currentorderfilterstatus = localStorage.getItem("orderfilterstatuspending");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(currentPageId);
  const [searchpage, setSearchPage] = React.useState(0);
  const [openModel, setOpenModel] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [rowData, setRowData] = useState({});
  const [artData, setArtData] = useState([]);
  const [artLoading, setArtLoading] = useState(false);
  const [id, setId] = useState(null);
  const [artDetailModal, setArtDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [changedorderstatus, setChangedOrderStatus] = useState("none");
  // order By Date
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 2);
  const [res, setRes] = useState(false);

  const [orderfilterstatus, setOrderFilterStatus] = React.useState(
    currentorderfilterstatus ? currentorderfilterstatus : ""
  );
  const [filterpage, setFilterPage] = React.useState(0);
  const currentRowsPerPage = localStorage.getItem("importantRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(currentRowsPerPage ? currentRowsPerPage : 10);

  localStorage.setItem("ImportantNewPage", currentPageId + 1);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setRowData({});
  };

  const handleModelOpen = useCallback((e, row) => {
    e.stopPropagation();
    setRowData(row);
    setOpenModel(true);
  }, []);

  const handleArtWork = useCallback((e, row) => {
    e.stopPropagation();
    setId(row.id);
    const getArtworkdetails = async () => {
      if (row.id) {
        try {
          setArtLoading(true);
          const data = await getOrderImg(row.id);
          setArtData(data);
          setArtLoading(false);
          setArtDetailModal(true);
        } catch (error) {
          setArtLoading(false);
          console.log(error);
        }
      }
    };
    getArtworkdetails();
  }, []);

  const handleCloseArtModel = useCallback(() => {
    setArtDetailModal(false);
    setArtData([]);
    setId(null);
  }, []);

  const handleNotesOpen = useCallback((e, id) => {
    e.stopPropagation();
    setId(id);
    const fetchNotes = async () => {
      if (id) {
        try {
          setNotesLoading(true);
          const data = await getCustomerNotes(id);
          setNotes(data);
          setNotesLoading(false);
          setOpenNotes(true);
        } catch (error) {
          setNotesLoading(false);
          console.log(error);
        }
      }
    };
    fetchNotes();
  }, []);

  const handleNotesClose = useCallback((e, row) => {
    setId(null);
    setOpenNotes(false);
  }, []);


  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    setFilterPage(0);
    setSearchPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("importantRowsPerPage");
    } else {
      localStorage.setItem("importantRowsPerPage", e.target.value);
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setOrders([]);
      setCount(0);
      try {
        setLoading(true);
        if (orderfilterstatus !== "none" && orderfilterstatus !== "") {
          setOrders([]);
          setCount(0);
          var data = await getOrderByStatus(
            filterpage + 1,
            rowsPerPage,
            search,
            orderfilterstatus
          );
        } else {
          if (search) {
            var data = await getImportantOrders(
              searchpage + 1,
              rowsPerPage,
              search
            );
          } else {
            var data = await getImportantOrders(page + 1, rowsPerPage, search);
            setSearchPage(0);
          }
          setFilterPage(0);
        }
        //const data = await getImportantOrders(page + 1, 10, search);
        setOrders(data.responseData.response);
        setCount(data.responseData.order_count.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchOrder();
  }, [
    page,
    search,
    res,
    searchpage,
    filterpage,
    changedorderstatus,
    orderfilterstatus,
    rowsPerPage,
  ]);

  const handleChangePage = (event, newPage) => {
    if (orderfilterstatus !== "none" && orderfilterstatus !== "") {
      setFilterPage(newPage);
    } else {
      if (search) {
        setSearchPage(newPage);
      } else {
        setPage(newPage);
      }
    }
    if (!search) {
      history.push({
        pathname: `/important-order/${newPage + 1}`,
      });
      console.log(newPage + "page");
    }
  };

  const handleOrderDetails = useCallback(
    (e, id) => {
      const curl = window.location.pathname;
      const cpageId = curl.split("/")[2];
      const previousOrders = JSON.parse(
        localStorage.getItem("visitedImportantOrders")
      );
      if (previousOrders) {
        // console.log(JSON.parse(previousOrders))
        previousOrders.push(id);
        localStorage.setItem(
          "visitedImportantOrders",
          JSON.stringify(previousOrders)
        );
      } else {
        localStorage.setItem("visitedImportantOrders", JSON.stringify([id]));
      }
      e.stopPropagation();
      history.push({
        pathname: `/important-order/${cpageId}/details`,
        search: `?id=${id}&tab=0`,
      });
    },
    [history]
  );

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Layout>
      <Box bgcolor="#f0f0f1" minHeight={`calc(100vh - 67px)`} px={3} py={3} className={classes.secBox}>
        <Box display="flex" alignItems="center" mb={2} className={classes.secTopBar}>
          <Typography className={classes.heading}>Important Orders</Typography>
        </Box>
        <Paper className={classes.paper}>
          <TableContainer>
            {loading && <LinearProgress />}
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                key={2}
              />
              {orders && orders.length > 0 ? (
                <TableBody>
                  {orders.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let date = moment(row.date_created.date)
                      .tz("Australia/Melbourne")
                      .format("ll");
                    //console.log(date);
                    let previousOrdersClick = JSON.parse(
                      localStorage.getItem("visitedImportantOrders")
                    );
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          key={row.id}
                          className={
                            previousOrdersClick
                              ? previousOrdersClick.includes(row.id)
                                ? classes.alreadyClicked
                                : null
                              : null
                          }
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell> */}
                          <TableCell>{date}</TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className={classes.orderId}
                            onClick={(e) => handleOrderDetails(e, row.id)}
                          >
                            {`#${row.id} ${row.billing.first_name} ${row.billing.last_name}`}
                            {/* <OrderUnreadNotification orderdid={row.id} /> */}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => handleModelOpen(e, row)}
                            >
                              <VisibilityOutlinedIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={(e) => handleArtWork(e, row)}>
                              {artLoading && id === row.id ? (
                                <CircularProgress
                                  className={classes.loading}
                                  size={20}
                                  thickness={4}
                                />
                              ) : (
                                <FaceOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {/* <OrderStatus row={row} /> */}
                            <OrderStatus
                              row={row}
                              changedorderstatus={changedorderstatus}
                              setChangedOrderStatus={setChangedOrderStatus}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => handleNotesOpen(e, row.id)}
                            >
                              {notesLoading && id === row.id ? (
                                <CircularProgress
                                  className={classes.loading}
                                  size={20}
                                  thickness={4}
                                />
                              ) : (
                                <ChatOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <UnpinComponent
                              row={row}
                              res={res}
                              setRes={setRes}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              ) : (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography>No Order(s) Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
            {loading && <LinearProgress />}
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={
              orderfilterstatus !== "none" && orderfilterstatus !== ""
                ? filterpage
                : search
                ? searchpage
                : page
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Paper>
        <OrderInfo
          handleClose={handleCloseModel}
          openModel={openModel}
          setOpenModel={setOpenModel}
          {...rowData}
        />
        <ArtworkDetail
          id={id}
          handleClose={handleCloseArtModel}
          openModel={artDetailModal}
          setOpenModel={setOpenModel}
          artData={artData}
        />
        <OrderNotes
          handleClose={handleNotesClose}
          handleOpen={handleNotesOpen}
          notes={notes}
          open={openNotes}
          id={id}
        />
      </Box>
    </Layout>
  );
}
