import React, { useState, useCallback } from "react";
import {
  Container,
  Box,
  Paper,
  Stack,
  Typography,
  Grid,
  Button,
  TextField,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import PaymentForm from "./PaymentForm";
import PaymentDetails from "./PaymentDetails";
import { makeStyles } from "@material-ui/core";

const pKey =
  "pk_test_51JdB2hSIhPZY1ESZZxmO2tKFzdkVBsRo9aMtxEZ1x6W9WQaXepw3iR43QeQZPxuUKqldxEFnzqjKzrQC3PbE7X8200LRIhhTDz";
const sKey =
  "sk_test_51JdB2hSIhPZY1ESZjW9hFd6mBfX8qyZpAyfVPJaiXg8d5g3Kkf2P1Q06bAZ41TtdiOspYRScdvdu5H9Wr1rNNJzT00C54dPw55";

const stripePromise = loadStripe(pKey);

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({  
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  fullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
      flex: "unset !important",
      margin: "0 0 16px !important",
    },
},
}));

const Payment = ({ enquiry, loading, id }) => {
  const classes = useStyles();
  const [payment, setPayment] = useState(false);
  const [paymentLink, setPaymentLink] = useState(false);
  const [copy, setCopy] = useState(false);
  const [amountBox, setAmountBox] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);

  const handleChange = useCallback((e) => {
    setAmount(e.target.value);
  }, []);

  const handleManualPayment = () => {
    setPayment(true);
  };

  const addAmount = () => {
    if (amount.length < 1) {
      setPaymentLink(false);
      setAmountError("Please add an amount to proceed")
      return;
    }
    setAmountError(false)
    setPaymentLink(true);
  };

  const handlePaymentLink = () => {
    setAmountBox(true);
  };

  function copyURL(e) {
    e.preventDefault();
    let link = document.getElementById("payment-link");
    navigator.clipboard.writeText(link).then(
      () => {
        /* clipboard successfully set */
        setCopy(true);
      },
      () => {
        /* clipboard write failed */
      }
    );
  }
  return (
    <Container className={classes.container}>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LinearProgress color="primary" />
        </Box>
      )}
        <>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              mb: 2,
            }}
          >
            Payment
          </Typography>
          <Stack direction="row"flexWrap="wrap" spacing={2}>
            <Paper sx={{ padding: "2rem 1rem", flex: 1 }}  className={classes.fullWidth}>
              <TypographyHeading>Send payment link</TypographyHeading>
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    p: "2px 4px",
                    mt: 2,
                  }}
                  onClick={handlePaymentLink}
                >
                  Generate link
                </Button>
                {amountBox && (
                  <>                    
                    <Stack
                      direction="row"
                      alignItems="center" spacing={2} my={2}>
                      <TextField
                          size="small"
                          label="Amount"
                          placeholder="Amount"
                          name="amount"
                          type="text"                        
                          value={amount}
                          onChange={handleChange}
                          fullWidth
                        />
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontSize: "15px",
                          p: "6px 15px",
                          whiteSpace: "nowrap",
                        }}
                        onClick={addAmount}
                        >
                          Add amount
                        </Button>
                    </Stack>
                    {amountError && (
                      <Typography sx={{
                        color: "red",
                        marginTop: "-8px",
                        mb: 2,
                        fontSize: "13px",
                      }}>{amountError}</Typography>
                    )}
                  </>
                )}
                {paymentLink && (
                  <>
                    <Box>
                      <a
                        href={`https://printyo.net.au/payment?enquiryId=${
                          enquiry.id
                        }&cname=${enquiry.name}&email=${
                          enquiry && enquiry.email
                        }&phone=${enquiry && enquiry.phone}&amount=${
                          amount
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="payment-link"
                      >
                        {`https://printyo.net.au/payment?enquiryId=${
                          enquiry.id
                        }&cname=${enquiry.name}&email=${
                          enquiry && enquiry.email
                        }&phone=${enquiry.phone}&amount=${
                          amount
                        }`}
                      </a>
                    </Box>
                    <Stack direction="row" alignItems="center" my={2}>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontSize: "13px",
                          p: "2px 4px",
                        }}
                        onClick={copyURL}
                      >
                        {copy ? "Coped" : "Copy"}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          fontSize: "13px",
                          p: "2px 4px",
                          ml: 2,
                        }}
                      >
                        Send link
                      </Button>
                    </Stack>
                  </>
                )}
              </Box>
              <Box>
                <Button
                  sx={{ textTransform: "none", fontSize: "15px", p: "2px 4px" }}
                  onClick={handleManualPayment}
                >
                  Add payment manually
                </Button>
                {payment && (
                  <Box>
                    <Elements stripe={stripePromise}>
                      {/* <PaymentForm enquiry={enquiry} /> */}
                    </Elements>
                  </Box>
                )}
              </Box>
            </Paper>
          </Stack>
          <PaymentDetails id={id} />
        </>
    </Container>
  );
};

export default Payment;
