import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  IconButton,
  Container,
  LinearProgress,
} from "@material-ui/core";
import { Typography, Button, Stack, Paper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { getUsers, AddUserData } from "../../api";
import moment from "moment";
import AddUser from "./AddUser";
import Layout from "../../components/Layout";
import ConfirmationPopup from "./ConfirmationPop";
import EditUser from "./EditUser";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Edit</TableCell>
        <TableCell>Delete</TableCell>
        <TableCell>Created at</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: "1rem",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  deleteIcon: {
    marginLeft: "7px",
    cursor: "pointer",
  },
}));

const DeleteComponent = ({ id, res, setRes }) => {
  const classes = useStyles();
  const [openDeletepop, setDeletePop] = useState(false);

  const handleCloseDeletePop = () => {
    setDeletePop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <DeleteOutlinedIcon
          fontSize="small"
          className={classes.deleteIcon}
          onClick={() => setDeletePop(true)}
        />
      </Box>
      <ConfirmationPopup
        open={openDeletepop}
        handleClose={handleCloseDeletePop}
        setRes={setRes}
        id={id}
      />
    </>
  );
};

// Edit user
const EditComponent = ({ handleChange, open, id}) => {
  const [openEditPop, setEditPop] = useState(false);

  const handleCloseEditPop = () => {
    setEditPop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton onClick={() => setEditPop(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
      {openEditPop? 
        <EditUser
          openModel={openEditPop}
          handleClose={handleCloseEditPop}
          id={id}
        />
        : null 
      }
    </>
  );
};

const Users = () => {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [id, setId] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [nameValError, setNameValError] = useState({
    helperText: "",
    error: false,
  });
  const [emailValError, setEmailValError] = useState({
    helperText: "",
    error: false,
  });
  const [passValError, setPassValError] = useState({
    helperText: "",
    error: false,
  });
  const [passError, setPassError] = useState({ helperText: "", error: false });
  const emailRegex = "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$";

  const handleClose = () => {
    setOpenModel(false);
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleAddUser = async () => {
    setNameValError({ helperText: "", error: false });
    if (userInfo.name.length < 2) {
      setNameValError({ helperText: "Please enter user name", error: true });
      return;
    }

    if (userInfo.email.match(emailRegex)) {
      setEmailValError({ helperText: "", error: false });
    } else {
      setEmailValError({
        helperText: "Please enter a valid email address",
        error: true,
      });
      return;
    }

    setPassValError({ helperText: "", error: false });
    if (userInfo.password.length < 7) {
      setPassValError({
        helperText: "Please enter password with minimum 8 Characters",
        error: true,
      });
      return;
    }

    setPassError({ helperText: "", error: false });
    if (userInfo.password !== userInfo.password_confirmation) {
      setPassError({ helperText: "Password doesnot match", error: true });
      return;
    }
    var data = new FormData();
    data.append("name", userInfo.name);
    data.append("email", userInfo.email);
    data.append("password", userInfo.password);
    data.append("roleid", userInfo.roleid ? userInfo.roleid : 3);
    data.append("userid", id);
    setLoading(true);
    try {
      const response = await AddUserData(data);
      if (response.status === 200) {
        setUserInfo({ name: "", email: "", password: "" });
      }
      setRes(!res);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from add user", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      setId(id);
      try {
        setWaiting(true);
        const data = await getUsers();
        if (data.status === 200) {
          setUserList(data.users);
        }
        setWaiting(false);
      } catch (error) {
        console.log("error form Users", error);
      }
    };
    fetchUser();
  }, [res, id]);

  return (
    <Layout>
      <Container>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              my: 2,
            }}
          >
            Users
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: "none" }}
            onClick={() => setOpenModel(true)}
          >
            Add User
          </Button>
        </Stack>
        <AddUser
          openModel={openModel}
          handleClose={handleClose}
          handleChange={handleChange}
          userInfo={userInfo}
          handleAddUser={handleAddUser}
          loading={loading}
          passError={passError}
          nameValError={nameValError}
          emailValError={emailValError}
          passValError={passValError}
        />
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              <TableBody>
                {userList?.map((user, index) => {
                  let date = moment(user.created_at).format("ll");
                  return (
                    <>
                      <TableRow hover key={user.id}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                        <EditComponent
                            id={user.id}
                          />
                        </TableCell>
                        <TableCell>
                          <DeleteComponent
                            res={res}
                            setRes={setRes}
                            id={user.id}
                          />
                        </TableCell>
                        <TableCell>{date}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {waiting && <LinearProgress />}
          </TableContainer>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Users;
