import React from "react";
import { Route } from "react-router-dom";
import Users from "./app/users";
import Settings from "./app/settings";
import AdvSettings from "./app/advSettings";
import Role from "./app/role";

const AdminPrivateRoutes = () => {
  return (
    <>
      <Route path="/settings" exact component={Settings} />
      <Route path="/advanced-settings" exact component={AdvSettings} />
      <Route path="/users" exact component={Users} />
      <Route path="/role" exact component={Role} />
    </>
  );
};

export default AdminPrivateRoutes;
