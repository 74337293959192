import React from "react";
import TextField from "@mui/material/TextField";
import { Field } from "formik";
import { Stack } from "@mui/material";

const Shipping = ({ errors, setFieldValue }) => {
  const handleShippingDetailsChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };
  return (
    <>
      <Stack
        spacing={3}
        sx={{
          "& .MuiFormControl-root": {
            width: "100%",
            "& label": {
              fontSize: "14px",
            },
            "& input": {
              fontSize: "14px",
              color: "#6B7280",
            },
          },
        }}
      >
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="shipping_first_name"
            size="small"
            as={TextField}
            error={errors.shipping_first_name}
            helperText={errors.shipping_first_name}
            variant="outlined"
            placeholder="First Name"
            label="First Name"
            onChange={handleShippingDetailsChange}
          />
          <Field
            name="shipping_last_name"
            size="small"
            as={TextField}
            error={errors.shipping_last_name}
            helperText={errors.shipping_last_name}
            variant="outlined"
            placeholder="Last Name"
            label="Last Name"
            onChange={handleShippingDetailsChange}
          />
        </Stack>
        {/* <Stack alignItems='center' spacing={1} direction='row'>
                    <Field
                        name="shipping_email"
                        size="small"
                        as={TextField}
                        error={errors.first_name}
                        helperText={errors.first_name}
                        variant="outlined"
                        placeholder="Email address"
                        label="Email address"
                    /> */}
        <Field
          name="shipping_phone"
          size="small"
          as={TextField}
          error={errors.shipping_phone}
          helperText={errors.shipping_phone}
          variant="outlined"
          placeholder="Phone"
          label="Phone"
          onChange={handleShippingDetailsChange}
        />
        {/* </Stack> */}
        <Field
          name="shipping_company"
          size="small"
          as={TextField}
          error={errors.shipping_company}
          helperText={errors.shipping_company}
          variant="outlined"
          placeholder="Company name"
          label="Company name"
          onChange={handleShippingDetailsChange}
        />
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="shipping_address_1"
            size="small"
            as={TextField}
            error={errors.shipping_address_1}
            helperText={errors.shipping_address_1}
            variant="outlined"
            placeholder="Address line 1"
            label="Address line 1"
            onChange={handleShippingDetailsChange}
          />
          <Field
            name="shipping_address_2"
            size="small"
            as={TextField}
            error={errors.shipping_address_2}
            helperText={errors.shipping_address_2}
            variant="outlined"
            placeholder="Address line 2"
            label="Address line 2"
            onChange={handleShippingDetailsChange}
          />
        </Stack>
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="shipping_city"
            size="small"
            as={TextField}
            error={errors.shipping_city}
            helperText={errors.shipping_city}
            variant="outlined"
            placeholder="City"
            label="City"
            onChange={handleShippingDetailsChange}
          />
          <Field
            name="shipping_state"
            size="small"
            as={TextField}
            error={errors.shipping_state}
            helperText={errors.shipping_state}
            variant="outlined"
            placeholder="State"
            label="State"
            onChange={handleShippingDetailsChange}
          />
        </Stack>
        <Stack alignItems="center" spacing={1} direction="row">
          <Field
            name="shipping_country"
            size="small"
            as={TextField}
            error={errors.shipping_country}
            helperText={errors.shipping_country}
            variant="outlined"
            placeholder="Country"
            label="Country"
            onChange={handleShippingDetailsChange}
          />
          <Field
            name="shipping_postcode"
            size="small"
            as={TextField}
            error={errors.shipping_postcode}
            helperText={errors.shipping_postcode}
            variant="outlined"
            placeholder="Postcode / ZIP"
            label="Postcode / ZIP"
            onChange={handleShippingDetailsChange}
          />
        </Stack>
        {/* <TextField
                    id="standard-multiline-flexible"
                    multiline
                    maxRows={4}
                    name=''
                    // value={paymentStatus}
                    // onChange={handleChange}
                    placeholder="Customer notes about the order"
                    label="Customer notes about the order"
                    variant="outlined"
                    fullWidth
                /> */}
      </Stack>
    </>
  );
};

export default Shipping;
