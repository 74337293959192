import React, { useState, useCallback } from "react";
import { Button, Box, Typography, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { addEnquirytNotes } from "../../../api";
import { makeStyles } from "@material-ui/core";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
    },
  },
}));

const Note = ({ id, setRes, setOpen }) => {
  const classes = useStyles();
  const [value, setValue] = useState({
    notemessage: "",
  });
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);
  const userid = localStorage.getItem("auth_id");
  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  console.log(value)

  const handleAddNote = async () => {
    if (!value) {
      return;
    }
    const data = new FormData();
    data.append("enquirysendtocustomer", "0");
    data.append("userid", userid);
    data.append("notetext", value.notemessage);
    try {
      setLoading(true);
      let result = await addEnquirytNotes(id, data);
      console.log("result from note", result);
      setRes(result.success);
      setLoading(false);
      setValue("");
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setValue({
      ...value,
      ["notemessage"]: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };

  return (
    <Paper sx={{ p: 2, mt: 4, width: "70vw" }} className={classes.fullWidth}>
      <Typography
        sx={{
          mb: 2,
          color: "#3c434a",
          fontSize: "15px",
          lineHeight: "1.4em",
          fontWeight: "bold",
        }}
      >
        Add Note
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        mt={2}
        p={1}
        sx={{
          border: 1,
          borderColor: "grey.500",
          borderRadius: 1,
        }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onEditorStateChange}
          placeholder="If you want to add any note, please add it here."
          toolbar={{
            options: ["blockType", "inline"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
            },
            blockType: {
              inDropdown: false,
              options: ["H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
            },
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="end" mt={2}>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          disabled={loading}
          onClick={handleAddNote}
        >
          Add
        </Button>
      </Box>
    </Paper>
  );
};

export default Note;
