import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { getAllRoles, getSingleUser, updateUserData } from "../../api";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px !important",
    },
  },
}));

const AddUser = (props) => {
  const { openModel, handleClose, id } = props;
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(false);
  const [userdata, setUserData] = useState({});
  const [rolelist, setRoleList] = useState();
  const classes = useStyles();
  const [userroleid, setUserRoleId] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    roleid: "",
  });
  const [nameValError, setNameValError] = useState({
    helperText: "",
    error: false,
  });
  const [emailValError, setEmailValError] = useState({
    helperText: "",
    error: false,
  });
  const [passValError, setPassValError] = useState({
    helperText: "",
    error: false,
  });
  const [passError, setPassError] = useState({ helperText: "", error: false });
  const emailRegex = "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$";

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const fetchSingleUser = async () => {
      try {
        const data = await getSingleUser(id);
        if (data.status === 200) {
          setUserInfo({
            name: data.users.name,
            email: data.users.email,
            roleid: data.users.userrole.role_id,
          });
          //   setUserData(data.users);
          //   setUserRoleId(data.users.userrole.role_id);
        }
      } catch (error) {
        console.log("error form Users", error);
      }
    };
    fetchSingleUser();
  }, [res, id]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const data = await getAllRoles();
        if (data.status === 200) {
          setRoleList(data.roles);
        }
      } catch (error) {
        console.log("error form Users", error);
      }
    };
    fetchRoles();
  }, [res, id]);

  //   const handleEditUser = async () => {
  //     setNameValError({ helperText: "", error: false });
  //     if (userInfo.name.length < 2) {
  //       setNameValError({ helperText: "Please enter user name", error: true });
  //       return;
  //     }

  //     if (userInfo.email.match(emailRegex)) {
  //       setEmailValError({ helperText: "", error: false });
  //     } else {
  //       setEmailValError({
  //         helperText: "Please enter a valid email address",
  //         error: true,
  //       });
  //       return;
  //     }

  //     setPassValError({ helperText: "", error: false });
  //     if (userInfo.password.length < 7) {
  //       setPassValError({
  //         helperText: "Please enter password with minimum 8 Characters",
  //         error: true,
  //       });
  //       return;
  //     }

  //     setPassError({ helperText: "", error: false });
  //     if (userInfo.password !== userInfo.password_confirmation) {
  //       setPassError({ helperText: "Password doesnot match", error: true });
  //       return;
  //     }
  //     var data = new FormData();
  //     data.append("name", userInfo.name);
  //     data.append("email", userInfo.email);
  //     data.append("password", userInfo.password);
  //     data.append("roleid", 2);
  //     data.append("userid", id);
  //     setLoading(true);
  //     try {
  //       const response = await AddUserData(data);
  //       if (response.status === 200) {
  //         setUserInfo({ name: "", email: "", password: "" });
  //       }
  //       setRes(!res);
  //       setLoading(false);
  //       handleClose();
  //     } catch (error) {
  //       setLoading(false);
  //       handleClose();
  //       console.log("error from add user", error);
  //     }
  //   };

  const handleEditUser = async () => {
    var data = new FormData();
    if (userInfo.name) {
      data.append("name", userInfo.name);
    }
    if (userInfo.email) {
      data.append("email", userInfo.email);
    }
    if (userInfo.password) {
      data.append("password", userInfo.password);
    }
    if (userInfo.roleid) {
      data.append("roleid", userInfo.roleid ? userInfo.roleid : 3);
    }
    setLoading(true);
    try {
      const response = await updateUserData(id, data);
      if (response.status === 200) {
        setUserInfo({ name: "", email: "", password: "" });
      }
      setRes(!res);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from edit user", error);
    }
  };

  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>Edit User # {id}</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.fullWidth}>
        <TextField
          required
          helperText={nameValError.helperText}
          error={nameValError.error}
          size="small"
          label="Name"
          name="name"
          type="text"
          fullWidth
          onChange={handleChange}
          value={userInfo.name}
          style={{ marginBottom: "20px" }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          required
          helperText={emailValError.helperText}
          error={emailValError.error}
          size="small"
          label="Email"
          name="email"
          fullWidth
          type="email"
          onChange={handleChange}
          value={userInfo.email}
          style={{ marginBottom: "20px" }}
          InputLabelProps={{ shrink: true }}
        />
        {/* <TextField
          size="small"
          label="Current Role"
          fullWidth
          type="text"
          InputLabelProps={{ shrink: true }}
          value={rolelist?.map((role) => {
            return role.id == userdata.userrole.role_id ? role.role_name : null;
          })}
        /> */}
        <InputLabel id="demo-simple-select-label">User Role</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Select Role"
          value={userInfo.roleid}
          onChange={handleChange}
          fullWidth
          name="roleid"
          style={{ marginBottom: "20px" }}
        >
          {rolelist?.map((role) => {
            return <MenuItem value={role.id}>{role.role_name}</MenuItem>;
          })}
        </Select>
        <TextField
          required
          helperText={passValError.helperText}
          error={passValError.error}
          size="small"
          label="Password"
          name="password"
          type="password"
          onChange={handleChange}
          value={userInfo.password}
          fullWidth
          style={{ marginBottom: "20px" }}
        />
        <TextField
          required
          helperText={passError.helperText}
          error={passError.error}
          size="small"
          label="Confirm Password"
          name="password_confirmation"
          type="password"
          onChange={handleChange}
          value={userInfo.password_confirmation}
          fullWidth
        />
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <Button
          disabled={loading}
          onClick={handleEditUser}
          variant="contained"
          size="small"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;
