import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  EditInventoryProductType,
  getInventoryProductTypeById,
} from "../../api";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "bold",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    marginBottom: "1rem",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
}));

const Editproduct = (props) => {
  const { openModel, handleClose, res, setRes, id } = props;
  const [productInfo, setProductInfo] = useState({});
  const [editsizelist, seteditSizeList] = useState([{ producttypesizecatname: "", quantity: 0 }]);
  const [selectsizecheckbox, setSelectSizeCheckbox] = useState(false);
  const classes = useStyles();
  const userid = localStorage.getItem("auth_id");
  const [editloading, setEditLoading] = useState(false);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...editsizelist];
    list[index][name] = value;
    seteditSizeList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...editsizelist];
    list.splice(index, 1);
    seteditSizeList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    seteditSizeList([...editsizelist, { producttypesizecatname: "", quantity: 0 }]);
  };

  const handleChange = (e) => {
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const inventorydata = await getInventoryProductTypeById(id);
        if (inventorydata.status === 200) {
          setProductInfo(inventorydata.producttype);
          seteditSizeList(inventorydata.prodsubsize);
          if (inventorydata.prodsubsize.length > 0) {
            setSelectSizeCheckbox(true);
          }
          else {
            setSelectSizeCheckbox(false);
            seteditSizeList([{ producttypesizecatname: "", quantity: 0 }])
          }
        }
        console.log(inventorydata)
      } catch (error) {
        console.log("error form inventory", error);
      }
    };
    fetchProductData();
  }, [openModel, id]);

  const handleAddProduct = async () => {
    var data = new FormData();
    data.append("message", productInfo.message);
    data.append("producttypeid", id);
    data.append("quantity", productInfo.quantity);
    data.append("producttype", productInfo.producttype);
    data.append("userid", userid);
    data.append("issubsizecategory", selectsizecheckbox ? "yes" : "no");
    editsizelist.forEach((item, i) => {
      data.append(`subsizecategory[${i}][name]`, item.producttypesizecatname);
      data.append(`subsizecategory[${i}][quantity]`, item.quantity);
    });
    setEditLoading(true);
    //console.log(Object.fromEntries(data));
    try {
      const response = await EditInventoryProductType(id, data);
      if (response.status === 200) {
        setProductInfo({ message: "", quantity: 0, producttype: "" });
        seteditSizeList([{ producttypesizecatname: "", quantity: 0 }]);
      }
      setRes(!res);
      setEditLoading(false);
      handleClose();
      setSelectSizeCheckbox();
    } catch (error) {
      setEditLoading(false);
      handleClose();
      console.log("error from add producttype", error);
      setSelectSizeCheckbox();
    }
  };

  return (
    <Dialog
      open={openModel}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography className={classes.title}>
              Edit product type {productInfo.producttype}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          my={1}
        >
          <TextField
            size="small"
            label="Product Type"
            placeholder="Product Name"
            name="producttype"
            type="text"
            fullWidth
            onChange={handleChange}
            value={productInfo.producttype}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            label="Quantity"
            placeholder="Quantity"
            name="quantity"
            type="number"
            onChange={handleChange}
            value={productInfo.quantity}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
        <Box mb={1}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => setSelectSizeCheckbox(!selectsizecheckbox)}
                checked={selectsizecheckbox}
              />
            }
            label="Add product size category"
          />
        </Box>
        {selectsizecheckbox ? (
          <>
            {editsizelist && editsizelist.map((sizes, i) => {
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  my={2}
                  key={sizes.id}
                >
                  <TextField
                    size="small"
                    label="Product Size Category"
                    name="producttypesizecatname"
                    placeholder="Product Size Category name"
                    value={sizes.producttypesizecatname}
                    onChange={(e) => handleInputChange(e, i)}
                    type="text"
                    fullWidth
                  />
                  <TextField
                    size="small"
                    type="number"
                    label="Quantity"
                    placeholder="Quantity"
                    name="quantity"
                    value={sizes.quantity}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  <Box>
                    {editsizelist.length - 1 !== i && (
                      <IconButton
                        title="Delete"
                        edge="end"
                        aria-label="Delete"
                        onClick={() => handleRemoveClick(i)}
                      >
                        <DeleteIcon sx={{ color: "#d32f2f" }} />
                      </IconButton>
                    )}
                    {editsizelist.length - 1 === i && (
                      <IconButton
                        title="Add"
                        edge="end"
                        aria-label="Add"
                        onClick={handleAddClick}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Box>
                </Stack>
              );
            })}
          </>
        ) : null}
        <TextField
          size="small"
          label="Message"
          placeholder="Message"
          name="message"
          type="text"
          onChange={handleChange}
          value={productInfo.message}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions className={classes.actionButton}>
        <Button
          onClick={handleAddProduct}
          disabled={editloading}
          variant="contained"
          size="small"
        >
            {editloading? "Updating..." : "Update Product Type"}          
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Editproduct;
