import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  TablePagination,
  LinearProgress,
} from "@material-ui/core";
import {
  Button,
  Stack,
  Paper,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import moment from "moment";
import { getProductsList } from "../../api";
import Layout from "../../components/Layout";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeletePopup from "./DeletePop";
import EditPopup from "./EditPopup";
import SearchIcon from "@mui/icons-material/Search";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Number</TableCell>
        <TableCell>ID</TableCell>
        <TableCell> Product name</TableCell>
        <TableCell>Product type</TableCell>
        <TableCell>SKU</TableCell>
        <TableCell>View</TableCell>
        <TableCell>Delete</TableCell>
      </TableRow>
    </TableHead>
  );
}

// Edit product
const EditComponent = ({
  res,
  setRes,
  row,
  refreshProduct,
  setRefreshProduct,
}) => {
  const [openEditPop, setEditPop] = useState(false);

  const handleCloseEditPop = () => {
    setEditPop(false);
  };

  const handleOpenEditPop = () => {
    setEditPop(true);
    setRefreshProduct(!refreshProduct);
  };

  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton onClick={handleOpenEditPop}>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <EditPopup
        open={openEditPop}
        handleClose={handleCloseEditPop}
        res={res}
        setRes={setRes}
        row={row}
        refreshProduct={refreshProduct}
        setRefreshProduct={setRefreshProduct}
      />
    </>
  );
};

// Delete product
const DeleteComponent = ({ res, setRes }) => {
  const [openDeletepop, setDeletePop] = useState(false);

  const handleCloseDeletePop = () => {
    setDeletePop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton onClick={() => setDeletePop(true)}>
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <DeletePopup
        open={openDeletepop}
        handleClose={handleCloseDeletePop}
        res={res}
        setRes={setRes}
      />
    </>
  );
};

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pageId = url.split("/")[2];
  if (pageId === 1) {
    return 0;
  }
  return pageId - 1;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: "1rem",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  
}));

const Product = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentPageId = parseInt(getTheCurrentUrl());
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = React.useState(currentPageId);
  const [count, setCount] = useState(0);

  const [refreshProduct, setRefreshProduct] = useState(false);

  const currentRowsPerPage = localStorage.getItem("productsRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(currentRowsPerPage) ? parseInt(currentRowsPerPage) : 10
  );
  localStorage.setItem("productsNewPage", currentPageId + 1);

  // Search
  const [search, setSearch] = useState("");
  const [searchvalue, setSearchValue] = React.useState("");
  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearch("");
    }
    setPage(0);
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearch(searchvalue);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    if (e.target.value === "10") {
      localStorage.removeItem("productsRowsPerPage");
    } else {
      localStorage.setItem("productsRowsPerPage", e.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      pathname: `/product/${newPage + 1}`,
    });
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setCount(0);
        const data = await getProductsList(page + 1, rowsPerPage, search);
        setProducts(data.response);
        setCount(data.product_count.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error form inventory", error);
      }
    };
    fetchProducts();
  }, [page, res, rowsPerPage, search]);

  return (
    <Layout>
      <Box
        bgcolor="#f0f0f1"
        minHeight={`calc(100vh - 67px)`}
        px={3}
        py={3}
        className={classes.secBox}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.secTopBar}
        >
          <Typography className={classes.heading}>Products</Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            className={classes.searchInputBox}
          >
            <form onSubmit={handleSearchSubmit}>
              <TextField
                id="outlined-start-adornment"
                className={classes.searchInput}
                sx={{
                  width: "30ch",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                }}
                size="small"
                placeholder="search"
                onChange={handleSearch}
                value={searchvalue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon onClick={handleSearchSubmit} />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Stack>
        </Box>
        <Paper className={classes.paper}>
          {loading && <LinearProgress />}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              {products && products.length > 0 ? (
                <TableBody>
                  {products.map((row, index) => {
                    let total = index + 1;
                    return (
                      <>
                        <TableRow hover key={index}>
                          <TableCell>{total}</TableCell>
                          <TableCell>{row.product_id}</TableCell>
                          <TableCell>{row.product_name}</TableCell>
                          <TableCell>{row.product_type_name}</TableCell>
                          <TableCell>{row.sku}</TableCell>
                          <TableCell>
                            <EditComponent
                              row={row}
                              res={res}
                              setRes={setRes}
                              refreshProduct={refreshProduct}
                              setRefreshProduct={setRefreshProduct}
                            />
                          </TableCell>
                          <TableCell>
                            <DeleteComponent res={res} setRes={setRes} />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              ) : (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography>No Product(s) Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={count > 0 ? page : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Layout>
  );
};

export default Product;
