import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { unapproveMockup } from "../../../api";
import TrackEvents from "../../../api/amplitudeEvents";

const UnapproveMockup = ({
  id,
  handleClose,
  open,
  setUnapproveId,
  res,
  setRes,
  orderId,
}) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const userid = localStorage.getItem("auth_id");
  console.log(orderId);
  const handleConfirmed = async () => {
    if (!id || !msg) {
      return;
    }
    const data = new FormData();
    data.append("orderemailmockupattachmentsid", id);
    data.append("userid", userid);
    data.append("message", msg);
    try {
      setLoading(true);
      let result = await unapproveMockup(orderId, data);
      if (result.status === 200) {
        setRes(!res);
        TrackEvents(`${orderId}`, {
          process: "Mockup Unapproved In Pending Order",
        });
      } else {
        alert("oops something went wrong");
      }
      setLoading(false);
      handleClose();
      setUnapproveId(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleClose();
      setUnapproveId(null);
    }
  };

  const handleChange = (e) => {
    setMsg(e.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent dividers sx={{ width: 500 }}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography>Do you want to unapprove mockup with id #{id} </Typography>
        <TextField
          type="text"
          value={msg}
          onChange={handleChange}
          rows={2}
          multiline
          size="small"
          sx={{ mt: 2 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          variant="contained"
          size="small"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing" : "Confirmed"}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnapproveMockup;
