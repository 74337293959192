import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, IconButton, Button, Stack, TextField, FormControlLabel, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


const useStyles = makeStyles((theme) => ({
    subHeading: {
        color: '#3c434a',
        fontSize: '13px',
        lineHeight: '1.4em',
    },
    heading: {
        fontSize: '13px',
        lineHeight: '1.4em',
        fontWeight: 'bold'
    },
    typography: {
        fontSize: '13px',
        lineHeight: '1.4em',
        marginBottom: '1rem'
    },
    title: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '1.5em',
    },
    titleBtn: {
        textTransform: 'none',
        marginRight: '1rem'
    },
    status: {
        fontSize: '1rem'
    },
    margin: {
        marginBottom: '10px'
    }
}))

const Addproduct = (props) => {
    const { openModel, handleClose, handleAddProduct, loading, productInfo, handleChange, selectCheckbox, setSelectCheckbox, handleInputChange, inputList, handleAddClick, handleRemoveClick} = props
    const classes = useStyles();   
    
    return (
        <Dialog
            open={openModel}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box>
                        <Typography className={classes.title}>Add product type</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} my={1}>
                    <TextField
                        size="small"
                        label="Product Type"
                        placeholder="Product Name"
                        name="producttype"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        value={productInfo.producttype}
                    />
                    <TextField
                        size="small"
                        label="Quantity"
                        placeholder="Quantity"
                        name="quantity"
                        type="number"
                        onChange={handleChange}
                        value={productInfo.quantity}
                    />
                </Stack>
                <Box mb={1}>
                    <FormControlLabel control={<Checkbox 
                        onClick={() => setSelectCheckbox(!selectCheckbox)}
                        onChange={handleChange}
                        value={productInfo.issubsizecategory} />} 
                        label="Add product size category" />
                </Box>
                {selectCheckbox?
                    (
                        <>
                            {inputList.map((x, i) => {
                            return (
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} my={2} key={i}>
                                    <TextField
                                    size="small"
                                    label="Product Size Category"
                                    name="name"
                                    placeholder="Product Size Category name"
                                    value={x.firstName}
                                    onChange={e => handleInputChange(e, i)}
                                    type="text"
                                    fullWidth
                                    />
                                    <TextField
                                    size="small"
                                    type="number"
                                    label="Quantity"
                                    placeholder="Quantity"
                                    name="quantity"
                                    value={x.lastName}
                                    onChange={e => handleInputChange(e, i)}
                                    />
                                    <Box >
                                    {inputList.length -1 !== i &&
                                        <IconButton
                                        title="Delete"
                                        edge="end"
                                        aria-label="Delete"
                                        onClick={() => handleRemoveClick(i)}
                                        >
                                            <DeleteIcon sx={{ color: "#d32f2f" }} />
                                        </IconButton>}
                                    {inputList.length - 1 === i && 
                                        <IconButton
                                        title="Add"
                                        edge="end"
                                        aria-label="Add"
                                        onClick={handleAddClick}
                                        >
                                            <AddIcon />
                                        </IconButton>}
                                    </Box>
                                </Stack>
                            );
                          })}
                        </>
                    )
                    : null
                }                
                <TextField
                    size="small"
                    label="Message"
                    placeholder="Message"
                    name="message"
                    type="text"
                    onChange={handleChange}
                    value={productInfo.message}
                    fullWidth
                />
            </DialogContent>
            <DialogActions className={classes.actionButton}>
                <Button onClick={handleAddProduct} disabled={loading} variant="contained" size="small">Add</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Addproduct
