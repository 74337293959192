import React from 'react'
import { Dialog, DialogTitle, Box, IconButton, DialogContent, Typography, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    subHeading: {
        color: '#3c434a',
        fontSize: '13px',
        lineHeight: '1.4em',
    },
    heading: {
        fontSize: '13px',
        lineHeight: '1.4em',
        fontWeight: 'bold'
    },
    typography: {
        fontSize: '13px',
        lineHeight: '1.4em',

    },
    title: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '1.5em',
    },
    titleBtn: {
        textTransform: 'none',
        marginRight: '1rem'
    },
    status: {
        fontSize: '1rem'
    },
    margin: {
        marginBottom: '10px'
    },
    modal: { 
        "& .MuiDialog-paper" : {
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                margin: "16px",
            },
        },
    },
    modalContent: {    
        [theme.breakpoints.down('xs')]: {
            padding: "16px"
        },
    },
    fullWidth: {    
        [theme.breakpoints.down('xs')]: {
            width: "100% !important",
            minWidth: "210px",
        },
    },
}))

const SearchedProducts = (props) => {
    const classes = useStyles()
    const { openModel, handleClose, product_searched, name } = props
    return (
        <Dialog
            open={openModel}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.modal}
        >
            <DialogTitle id="form-dialog-title">
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Box>
                        <Typography className={classes.title}>Searched Products</Typography>
                        <Box display='flex' alignItems='center'>
                            <Typography className={classes.heading} style={{ marginRight: '10px' }}>Name:</Typography>
                            <Typography className={classes.typography}>{name}</Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers className={classes.modalContent}>
                <Box mb={3}>
                    <Box display='flex' alignItems='center' justifyContent='space-between' width={400}  className={classes.fullWidth}>
                        <Typography className={classes.heading}>Product Name</Typography>
                        <Typography className={classes.heading}>Quantity</Typography>
                    </Box>
                    <Box>
                        {product_searched ? product_searched.map((item, index) => {
                            return (
                                <Box display='flex' alignItems='center' justifyContent='space-between' key={index} mt={1.5}>
                                    <Typography className={classes.typography} style={{ marginRight: '10px', maxWidth: "80%", }}>{item.name}</Typography>
                                    <Typography className={classes.typography}>{item.quantity}</Typography>
                                </Box>
                            )
                        }) : null}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SearchedProducts
