const axios = require("axios");

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

//GET The CSRF token for the
export const getCSRF = async () => {
  return axios
    .get(`https://printyocrm.printyo.net.au/sanctum/csrf-cookie`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Login URL
export const getLoginDetails = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/login`, data)
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      } else if (res.status === 401) {
        console.log(res.data);
        return res.data;
      } else if (res.status === 404) {
        console.log(res.data);
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const Logout = async () => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/logout`)
    .then((res) => {
      if (res.status === 20) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getOrders = async (page = 1, per_page = 10, search = "") => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/wc/v3/orders?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&page=1&per_page=10`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getOtherOrders = async (page = 1, per_page = 10, search = "") => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/order?page=${page}&search=${search}&tokken=123456&postperpage=${per_page}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getOrderDetails = async (id) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/wc/v3/orders/${id}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getOrderImg = async (id) => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/py/v1/artworkdeatils/order/${id}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getTotalOrderCount = async (id) => {
  return axios
    .get(`https://www.printyo.net.au/wp-json/py/v1/orders/totalcount`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const addOrder = async (data) => {
  return axios
    .post(
      `https://www.printyo.net.au/wp-json/wc/v3/orders?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getTodayOrderCount = async (id) => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/py/v1/orders/count/today?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getCustomerLead = async (page = 1) => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/py/v1/customer/leads/${page}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getLeadOnDate = async (date) => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/py/v1/customer/leads/d/${date}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getTotalLeads = async () => {
  return axios
    .get(`https://www.printyo.net.au/wp-json/py/v1/leads/totalcount`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getCustomerNotes = async (id) => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/wc/v3/orders/${id}/notes?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getCustomerExtraNotes = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/note/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const addNotes = async (id, data, noteType) => {
  return axios
    .post(
      `https://www.printyo.net.au/wp-json/wc/v3/orders/${id}/notes?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&customer_note=${noteType}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const addNewNotes = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/note/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getCustomers = async (value = "") => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/wc/v3/customers?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&search=${value}&per_page=20`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getProducts = async (value = "", sku = "", type = "") => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/wc/v3/products?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&search=${value}&sku=${sku}&type=${type}&per_page=20`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getVariation = async (id, variation) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/wc/v3/products/${id}/variations/${variation}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getMockupMail = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/email/mockup/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getEmail = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/email/activity/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// export const getShippingPrice = async (data) => {
//     // var config = {
//     //     method: 'get',
//     //     url: 'https://digitalapi.auspost.com.au/postage/parcel/domestic/calculate.json?from_postcode=3014&to_postcode=3076&length=5&width=5&height=2&weight=4&service_code=AUS_PARCEL_REGULAR',
//     //     headers: {
//     //         'AUTH-KEY': 'cb05757b-60ef-4fed-bea7-7f1c77d27331',
//     //         "Content-Type": "application/json"
//     //     }
//     // };
//     // axios(config)
//     //     .then(function (response) {
//     //         console.log(JSON.stringify(response.data));
//     //     })
//     //     .catch(function (error) {
//     //         console.log(error);
//     //     });
// }
// export const getShippingPrice = async (data) => {
//   const config = {
//     method: "get",
//     url: `https://digitalapi.auspost.com.au/postage/parcel/domestic/calculate.json?from_postcode=3074&to_postcode=${data.to_postcode}&length=${data.length}&width=${data.width}&height=${data.height}&weight=${data.weight}&service_code=${data.service_code}`,
//     headers: {
//       "AUTH-KEY": "cb05757b-60ef-4fed-bea7-7f1c77d27331",
//     },
//   };
//   return axios(config)
//     .then((res) => {
//       console.log("shipping response", res);
//       return res.data;
//     })
//     .catch((error) => {
//       console.log("error", error);
//     });
// };

export const getShippingPrice = async (data) => {
  const config = {
    method: "get",
    url: `https://www.printyo.net.au/wp-json/py/v1/getshipping/charges?to_postcode=${data.to_postcode}&length=${data.length}&width=${data.width}&height=${data.height}&weight=${data.weight}&service_code=${data.service_code}`,
  };
  return axios(config)
    .then((res) => {
      console.log("shipping response", res);
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const sendSms = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/sms/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getSms = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/sms/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const addMail = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/email/activity/postmark/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const addMockupMail = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/email/mockup/postmark/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const updateStatus = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/status/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
// print orders ============================================================================================x
export const getFullfilledOrder = async (
  page = 1,
  per_page = 10,
  value = "",
  orderbyformat = "",
  fullfillmentcenter,
  productsearchvalue
) => {
  if (!fullfillmentcenter) {
    fullfillmentcenter = "";
  }
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/fullfilled/order?page=${page}&search=${value}&tokken=123456&orderby=${orderbyformat}&postperpage=${per_page}&fullfilmentcenter=${fullfillmentcenter}&product_type=${productsearchvalue}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const changeFullfilledDate = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/status/printdate/custom/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const addPrintNotes = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/printjobs/note/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const trackingOrder = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/tracking/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// mockups ----------------------------------------------------------------------------------------------------

export const getApprovedMockup = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/mockup/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const approveMockup = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/mockup/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const unapproveMockup = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/mockup/unapprove/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getAllMockup = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/mockup/all/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// inventory Products ---------------------------------------------------------------------------------------X
export const getInventoryitem = async (
  id,
  page = 1,
  per_page = 10,
  search = ""
) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/product/${id}?postperpage=${per_page}&page=${page}&search=${search}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const getInventoryProductType = async (
  page = 1,
  per_page = 10,
  search = ""
) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/inventory/producttype?postperpage=${per_page}&page=${page}&search=${search}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export const AddInventoryProductType = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/inventory/producttype`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const AddInventoryProducts = async (data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/product/add`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//-------------------------------------------------------------------------------------------Customer Notes api
export const getCustomerTabNotes = async (id) => {
  return axios
    .get(`https://www.printyo.net.au/wp-json/py/v1/order/customernotes/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getCustomerTabExtraNotes = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/printjobs/note/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//-------------------------------------------------------------------------------------------Print Files api

export const addPrintJobNotes = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/printjobs/printfiles/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getPrintTabNotes = async (id) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/order/printjobs/printfiles/${id}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//payment api
export const getPaymentDetails = async (id) => {
  return axios
    .get(`https://printyo.net.au/wp-json/py/v1/order/paymentdata/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Delete Products From the inventory
export const deleteProductFromProductType = async (data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/productdelete`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Users
export const getUsers = async () => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/alluser`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const AddUserData = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/register`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const deleteUser = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/user/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getUserUnreadNotification = async () => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/user/notifications`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const markUserNotificationAsRead = async (notifyid) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/user/notifications/markread/${notifyid}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getUserUnreadNotificationCount = async () => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/user/notifications/count`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get the Dispatch Order
export const getDispatchOrder = async (
  page = 1,
  per_page = 10,
  value = "",
  orderbyformat = ""
) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/dispatch/order?page=${page}&search=${value}&tokken=123456&orderby=${orderbyformat}&postperpage=${per_page}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Printyo Add Order API
export const addOrderOnPrintyo = async (data) => {
  return axios
    .post(
      `https://www.printyo.net.au/wp-json/wc/v3/orders?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`,
      data
    )
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => {
      //console.log("error", error.response.data);
      return error.response.data;
    });
};

//Printyo Add Order API
export const addCustomerOnPrintyo = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/addcustomerbyorder`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get Processing Orders
export const getProcessingOrder = async (
  page = 1,
  per_page = 10,
  search = ""
) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/processing/order?page=${page}&search=${search}&tokken=123456&postperpage=${per_page}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getProcessingOrderByDate = async (
  page = 1,
  per_page = 10,
  search = "",
  from,
  to
) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/processing/order/bydate?page=${page}&search=${search}&tokken=123456&from=${from}&to=${to}&postperpage=${per_page}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get The Unread Notification By Order ID
export const getCurrentUserNotificationByOrderid = async (orderid) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/order/notification/${orderid}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getSingleOrderDetails = async (id) => {
  return axios
    .get(`https://printyo.net.au/wp-json/py/v1/getorder/single/details/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Order update order
export const updateOrderOnPrintyo = async (id, data) => {
  return axios
    .post(
      `https://www.printyo.net.au/wp-json/wc/v3/orders/${id}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get Order Filter By Order Status
export const getOrderByStatus = async (
  page = 1,
  per_page = 10,
  search = "",
  orderstatus = "",
  fullfillmentcenter
) => {
  if (!fullfillmentcenter) {
    fullfillmentcenter = "";
  }
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/bystatus/order?page=${page}&search=${search}&tokken=123456&orderstatus=${orderstatus}&postperpage=${per_page}&fullfilmentcenter=${fullfillmentcenter}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Pin Order
export const pinOrder = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/pinned/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const unpinOrder = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/pinned/delete/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Important Orders
export const getImportantOrders = async (
  page = 1,
  per_page = 10,
  search = ""
) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getorder/pinned/order?page=${page}&search=${search}&tokken=123456&postperpage=${per_page}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Edit ProductInventory Type
export const EditInventoryProductType = async (producttypeid, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/${producttypeid}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getInventoryProductTypeById = async (producttypeid) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/${producttypeid}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const deleteProductType = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/delete/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//User Role
export const AddUsersRole = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/user/addroles`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getAllRoles = async () => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/user/getroles`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const UpdateUsersRole = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/user/updateroles`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getUsersRoleById = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/user/updateroles/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getUserRolesById = async (userrolesId) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/user/role/${userrolesId}/users`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getSingleUser = async (userid) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/user/single/${userid}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const updateUserData = async (userid, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/user/update/${userid}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Get Products

export const getProductsList = async (page = 1, per_page = 10, search = "") => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/getproduct/single/producttype?page=${page}&tokken=123456&productperpage=${per_page}&search=${search}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const UpdateProduct = async (data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/inventory/producttype/product/printyo/add`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// Get Enquiry
export const getEnquiryList = async (page = 1, per_page = 10, search = "") => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/customer/enquiredproducts?page=${page}&search=${search}&postperpage=${per_page}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getSingleEnquiryDetails = async (id) => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/py/v1/customer/enquiredproducts/single/${id}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const addEnquiryMail = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/enquiry/email/activity/postmark/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getEnquiryEmail = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/enquiry/email/activity/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const updateEnquiryStatus = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/enquiry/status/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getEnquirySms = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/enquiry/sms/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const sendEnquirySms = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/sms/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const addEnquirytNotes = async (id, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/enquiry/note/create/${id}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getEnquirytNotes = async (id) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/enquiry/getenquirynote/${id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const addEnquiry = async (data) => {
  return axios
    .post(
      `https://printyo.net.au/wp-json/py/v1/customer/enquiredproducts/single/add`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

// API(s) for the E-Parcel
export const getParcelItemId = async (parceldata) => {
  let data = JSON.stringify({
    from: {
      postcode: "3074",
    },
    to: {
      postcode: parceldata.to,
    },
    items: [
      {
        length: parceldata.length,
        height: parceldata.height,
        width: parceldata.width,
        weight: parceldata.weight,
        item_reference: parceldata.orderid,
        features: {
          TRANSIT_COVER: {
            attributes: {
              cover_amount: 1000,
            },
          },
        },
      },
    ],
  });

  let config = {
    method: "post",
    url: "https://digitalapi.auspost.com.au/shipping/v1/prices/items",
    headers: {
      Authorization:
        "Basic Njk4YTk5MmItMjc3ZC00MTY1LWE2OGYtMGRjNGVlZTRiMjlhOkFAMzM1MjFiVzQj",
      "Account-Number": "0007654171",
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getParcel = async (parceldata) => {
  return axios
    .post("https://printyocrm.printyo.net.au/api/auspostlabel", parceldata)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const getShippment = async (shippmentid) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/getshippmentdetails?shippmentid=${shippmentid}`
    )
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

//Generate Aus Post Order
export const generateOrder = async (shippmentids) => {
  return axios
    .post(
      "https://printyocrm.printyo.net.au/api/auspostgenerateorder",
      shippmentids
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getAusPostLabels = async (accountnumber) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/auspostlabels?ac=${accountnumber}`
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Mark Order As Paid
export const makeorderpaid = async (orderid, data) => {
  return axios
    .post(
      `https://printyocrm.printyo.net.au/api/order/markorderaspaid/${orderid}`,
      data
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Get Order As Paid
export const getorderpaid = async (orderid) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/order/markorderaspaid/${orderid}`
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Added Log TO DB
export const createLogs = async (data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/logs`, data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Get Logs By Id(s)
export const fetchLogs = async (orderid) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/logs/${orderid}`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Get All Logs
export const fetchLogsId = async (orderid) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/getalllogsorder`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

//Add Fullfillment center on printyo
export const addFullFillmentCenter = async (data, orderid) => {
  return axios
    .post(
      `https://www.printyo.net.au/wp-json/wc/v3/orders/${orderid}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get all generated Order
export const getAllAusPostGeneratedOrder = async (data, orderid) => {
  return axios
    .get(`https://printyocrm.printyo.net.au/api/auspostgeneratedorder`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get all generated Order by month for total cost
export const getAllAusPostMontlyOrderCost = async (month, year) => {
  return axios
    .get(
      `https://printyocrm.digitalgood.com.au/api/auspostmonthlysummary?month=${month}&year=${year}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const fetchAllCategoryForTool = async () => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/py/v1/getcategory/all/producttype?tokken=123456`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const updateProductTypeForTool = async (producttypeid, data) => {
  return axios
    .post(
      `https://printyocrm.digitalgood.com.au/api/inventory/updateproducttypefortool/${producttypeid}`,
      data
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Change Enquiry Assign User
export const updateEnquiryAssignUser = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/enquiry/user/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get Total Sale For the day

export const getTodaySales = async () => {
  // var today = new Date();
  // let currentmonth = today.getMonth() + 1;
  // let currentday = today.getDate();
  // if (currentmonth < 10) {
  //   currentmonth = "0" + currentmonth;
  // }
  // if (currentday < 10) {
  //   currentday = "0" + currentday;
  // }

  // let formatdate = today.getFullYear() + "-" + currentmonth + "-" + currentday;
  return axios
    .get(`https://printyo.net.au/wp-json/py/v1/order/cost/today`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getProductsForTool = async (value = "") => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/wc/v3/products?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&search=${value}&per_page=20`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Get Single Product Details
export const getSingleProduct = async (productId = "") => {
  return axios
    .get(
      `https://printyo.net.au/wp-json/wc/v3/products/${productId}?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Change Enquiry Assign User
export const getShippingPriceForOrders = async (page, limit) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/shipping/price?page=${page}&limit=${limit}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

//Change Order Assign User
export const updateOrderAssignUser = async (id, data) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/order/assign/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getAllCustomers = async (page, limit, search) => {
  return axios
    .get(
      `https://www.printyo.net.au/wp-json/py/v1/user/customer/all?page=${page}&postperpage=${limit}&search=${search}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const getAllPods = async (page, limit) => {
  return axios
    .get(
      `https://printyocrm.printyo.net.au/api/pods/get-all?page=${page}&limit=${limit}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const createPodCustomer = async (data) => {
  return axios
    .post(
      `https://printyo.net.au/wp-json/wc/v3/customers?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&page=1&per_page=10`,
      data
    )
    .then((res) => {
      console.log(res);
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      return { data: error.response.data, status: error.response.status };
    });
};

export const createPodCustomerCoupon = async (data) => {
  return axios
    .post(
      `https://printyo.net.au/wp-json/wc/v3/coupons?consumer_key=ck_96a492b8b543c2bc78939e693f122a45e8fa4723&consumer_secret=cs_cdd33a15f6edfb06a3862943c3ccf213152d247f&page=1&per_page=10`,
      data
    )
    .then((res) => {
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      return { data: error.response.data, status: error.response.status };
    });
};

export const updatePodCustomer = async (data, id) => {
  return axios
    .post(`https://printyocrm.printyo.net.au/api/pods/update/${id}`, data)
    .then((res) => {
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("error", error);
      return { data: error.response.data, status: error.response.status };
    });
};
