import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Typography,
  Box
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { updateEnquiryStatus } from "../api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogBoxPadding: {
    padding: "10px 25px !important",
  },
}));

const ConfirmedStatusPopup = (props) => {
  const classes = useStyles();
  const {
    id,
    status,
    handleClose,
    open,
    setStatus,
    res, setRes,
    changedEnquiryStatus, setChangedEnquiryStatus,
  } = props;
  const userid = localStorage.getItem("auth_id");  
  const [loading, setLoading] = useState(false);

  const handleConfirmed = async () => {
    console.log(status, id);    
    try {
      setRes(true);
      setLoading(true);
      const data = new FormData();
      data.append("userid", userid);
      data.append("enqurirystatus", status);
      data.append("enqurirystatusmessage", "Updated Successfully");
      const res = await updateEnquiryStatus(id, data);
      if (res.status === 200) {
        setLoading(false);
        setRes(false);
      } else {
        alert("Opps something went wrong");
        setLoading(false);
        setRes(false);
      }
      handleClose();
      setStatus("");
      console.log(status, "from Enquirycomfirmedpopup");
      setChangedEnquiryStatus(status);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setRes(false);
      handleClose();
      setStatus("");
    }
  };  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={classes.dialogBoxPadding}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography>
          Do you want to change the status of the enquiry # {id} to {status}{" "}
        </Typography>
      </DialogContent>      
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          variant="contained"
          size="small"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing" : "Confirm"}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmedStatusPopup;
