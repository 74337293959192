import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { pinOrder } from "../../api";
import { TypographyHeading, TypographySubHeading } from "../../styles";
import { Divider, makeStyles } from "@material-ui/core";
import TrackEvents from "../../api/amplitudeEvents";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "16px",
      },
    },
  },
  fullWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      minWidth: "210px",
      padding: "16px !important",
    },
  },
}));

const PinPopup = (props) => {
  const classes = useStyles();
  const {
    id,
    handleClose,
    open,
    res,
    setRes,
    setChangeImportantorderstatus,
    changeImportantorderstatus,
  } = props;
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const handleConfirmed = async () => {
    setError("");
    if (!id || !msg) {
      setError(
        "Please enter message why you want to mark this order important"
      );
      return;
    }
    const data = new FormData();
    data.append("pinnedmessage", msg);
    try {
      setLoading(true);
      let result = await pinOrder(id, data);
      if (result.status === 200) {
        setRes(!res);
        setChangeImportantorderstatus(!changeImportantorderstatus);
        setMsg("");
        TrackEvents(`${id}`, {
          process: "Order Marked As Important",
        });
      } else {
        alert("oops something went wrong");
      }
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleClose();
    }
  };

  const handleChange = (e) => {
    setMsg(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogContent dividers sx={{ width: 500 }} className={classes.fullWidth}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TypographyHeading>Important Order</TypographyHeading>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <TypographySubHeading sx={{ color: "red" }}>
          {error}
        </TypographySubHeading>
        <TypographySubHeading>
          Do you really want to mark this order #{id} as important?
        </TypographySubHeading>
        <TextField
          type="text"
          value={msg}
          onChange={handleChange}
          rows={2}
          multiline
          size="small"
          sx={{ mt: 2 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          variant="contained"
          size="small"
          color="primary"
          disabled={loading}
        >
          {loading ? "Processing" : "Confirmed"}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PinPopup;
