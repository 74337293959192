import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import Layout from "../../components/Layout";
import AddUserRole from "./AddUserRole";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  IconButton,
  Container,
  LinearProgress,
} from "@material-ui/core";
import { Typography, Button, Stack, Paper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { AddUsersRole, getAllRoles } from "../../api";
import moment from "moment";
import EditUserRole from "./EditUserRole";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Edit</TableCell>
        <TableCell>View</TableCell>
        <TableCell>Created at</TableCell>
      </TableRow>
    </TableHead>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: "1rem",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: "23px",
    lineHeight: 1.3,
    marginRight: "1rem",
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  deleteIcon: {
    marginLeft: "7px",
    cursor: "pointer",
  },
}));

// Edit useRole
const EditComponent = ({ loading, userInfo, handleChange, res, setRes, id}) => {
  const [openEditPop, setEditPop] = useState(false);

  const handleCloseEditPop = () => {
    setEditPop(false);
  };
  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton onClick={() => setEditPop(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
      {openEditPop? 
        <EditUserRole
          openModel={openEditPop}
          handleClose={handleCloseEditPop}
          id={id}
          res={res}
          setRes={setRes}
          handleChange={handleChange}
          userInfo={userInfo}
          loading={loading}
        />
        : null 
      }
    </>
  );
};

function UserRole() {
  // Add user role
  const classes = useStyles();
  const history = useHistory();
  const [waiting, setWaiting] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(false);
  const [id, setId] = useState(null);
  const [userInfo, setUserInfo] = useState({
    rolename: "",
  });
  const [userCheckbox, setUserCheckbox] = useState({
    rolepermissions: [],
  });

  const [nameValError, setNameValError] = useState({
    helperText: "",
    error: false,
  });

  const [rolelist, setRoleList] = useState();

  const handleClose = () => {
    setOpenModel(false);
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleChangeCheckbox = (e) => {
    setUserCheckbox({
      rolepermissions: [...userCheckbox.rolepermissions, e.target.value],
    });
  };

  const handleAddUser = async () => {
    setLoading(true);
    setNameValError({ helperText: "", error: false });
    if (userInfo.rolename.length < 2) {
      setNameValError({ helperText: "Please enter user role", error: true });
      return;
    }
    var data = new FormData();
    data.append("userrole", userInfo.rolename);
    userCheckbox.rolepermissions.forEach((item) => {
      data.append("usercapbility[]", item);
    });
    try {
      const response = await AddUsersRole(data);
      if (response.status === 200) {
        setUserInfo({ rolename: "" });
        setUserCheckbox({ rolepermissions: [] });
      }
      setRes(!res);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      console.log("error from add user role", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setId(id);
      try {
        setWaiting(true);
        const data = await getAllRoles();
        if (data.status === 200) {
          setRoleList(data.roles);
        }
        setWaiting(false);
      } catch (error) {
        console.log("error form Users", error);
      }
    };
    fetchRoles();
  }, [res, id]);

  const handleNavigation = useCallback(
    (id) => {
      history.push(`/user-roles/${id}/details`);
    },
    [history]
  );

  return (
    <Layout>
      <Container>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              my: 2,
            }}
          >
            Users Role
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: "none" }}
            onClick={() => setOpenModel(true)}
          >
            Add User Role
          </Button>
        </Stack>

        <AddUserRole
          openModel={openModel}
          handleClose={handleClose}
          handleChange={handleChange}
          handleChangeCheckbox={handleChangeCheckbox}
          userInfo={userInfo}
          handleAddUser={handleAddUser}
          loading={loading}
          nameValError={nameValError}
        />
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              <TableBody>
                {rolelist?.map((role, index) => {
                  let date = moment(role.created_at).format("ll");
                  return (
                    <>
                      <TableRow hover key={role.id}>
                      <TableCell>{role.id}</TableCell>
                        <TableCell>{role.role_name}</TableCell>
                        <TableCell>
                        <EditComponent
                            id={role.id}
                            res={res}
                            setRes={setRes}
                            handleChange={handleChange}
                            userInfo={userInfo}
                            loading={loading}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleNavigation(role.id)}
                          >
                            <VisibilityOutlinedIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        <TableCell>{date}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {waiting && <LinearProgress />}
          </TableContainer>
        </Paper>
      </Container>
    </Layout>
  );
}

export default UserRole;
