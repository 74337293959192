import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Divider,
  Paper,
  Box,
  Container,
  IconButton,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import {
  getSingleOrderDetails,
  getOrderImg,
  updateOrderOnPrintyo,
} from "../../api";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { Formik, Form, Field } from "formik";
import Billing from "../order/components/Billing";
import Shipping from "../order/components/Shipping";
import { useParams } from "react-router";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Notes from "./customerNotes";

const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
  marginBottom: "1rem",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
  marginTop: "1rem",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  subHeading: {
    color: "#3c434a",
    fontSize: "13px",
    lineHeight: "1.4em",
  },
  heading: {
    fontSize: "13px",
    lineHeight: "1.4em",
    fontWeight: "400",
  },
  typography: {
    fontSize: "13px",
    lineHeight: "1.4em",
    // margin: "0",
    // fontWeight: "400",
    // letterSpacing: "0.00938em",
    // fontSize: "13px",
    // lineHeight: "1.4em",
  },
  title: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "1.5em",
  },
  titleBtn: {
    textTransform: "none",
    marginRight: "1rem",
  },
  status: {
    fontSize: "1rem",
  },
  margin: {
    marginBottom: "10px",
  },
  image: {
    width: "250px",
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  orderDetailTitle: {
    [theme.breakpoints.down("xs")]: {
      flex: "unset !important",
    },
  },
  orderDetailsNotes: {
    "& .MuiButton-root": {
      display: "none",
    },
    "& .MuiTypography-root": {
      margin: "0",
    },
    "& .MuiPaper-root": {
      width: "100%",
      marginTop: "24px",
    },
  },
}));

const OrderDetails = ({ id }) => {
  const classes = useStyles();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [toggleShipping, setToggelShipping] = useState(false);
  const [toggleBilling, setToggelBilling] = useState(false);
  const [toggleShippingPrice, setToggelShippingPrice] = useState(false);
  const [toggleShippingCompany, setToggelShippingCompany] = useState(false);
  const [toggleChangeOrder, setToggelChangeOrder] = useState(false);
  const [artworkimages, setArtworkImages] = useState();
  const { print } = useParams();
  const [updatetoorder, setUpdateToOrder] = useState(false);
  const [orderupdated, setOrderUpdated] = useState(false);
  const [alertString, setAlertString] = useState("");
  const [alert, setAlert] = useState(false);
  const [responseSeverity, setResponseSeverity] = useState("info");
  const [changedShippingPrice, setChangedShippingPrice] = useState(0);
  const [changedShippingLabel, setChangedShippingLabel] = useState("");

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const data = await getSingleOrderDetails(id);
        console.log(data);
        setOrder(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (id) {
      fetchOrder();
    }
  }, [id, orderupdated]);

  useEffect(() => {
    const fetchArtwork = async () => {
      try {
        setLoading(true);
        const data = await getOrderImg(id);
        setArtworkImages(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (id) {
      fetchArtwork();
    }
  }, [id]);

  const handleSubmit = async (values, setSubmitting) => {
    setUpdateToOrder(true);
    var shipping = {};
    // if (billingchecked || !values.shipping_first_name) {
    //   shipping.first_name = values.billing_first_name;
    //   shipping.last_name = values.billing_last_name;
    //   shipping.address_1 = values.billing_address_1;
    //   shipping.address_2 = values.billing_address_2;
    //   shipping.city = values.billing_city;
    //   shipping.state = values.billing_state;
    //   shipping.postcode = values.billing_postcode;
    //   shipping.country = values.billing_country;
    // } else {
    shipping.first_name = values.shipping_first_name;
    shipping.last_name = values.shipping_last_name;
    shipping.address_1 = values.shipping_address_1;
    shipping.address_2 = values.shipping_address_2;
    shipping.city = values.shipping_city;
    shipping.state = values.shipping_state;
    shipping.postcode = values.shipping_postcode;
    shipping.country = values.shipping_country;
    // }
    const data = {
      shipping_total: changedShippingPrice
        ? changedShippingPrice
        : order.shipping_total,
      shipping_tax: "0.00",
      shipping_lines: [
        {
          method_id: "printyo_custom_shipping_method",
          method_title: changedShippingLabel
            ? changedShippingLabel
            : order.shipping_lines[0]?.method_title,
          total: changedShippingPrice
            ? changedShippingPrice
            : order.shipping_total,
          total_tax: "0.00",
        },
      ],
      billing: {
        first_name: values.billing_first_name,
        last_name: values.billing_last_name,
        address_1: values.billing_address_1,
        address_2: values.billing_address_2,
        city: values.billing_city,
        state: values.billing_state,
        postcode: values.billing_postcode,
        country: values.billing_country,
        email: values.billing_email,
        phone: values.billing_phone,
      },
      shipping,
    };
    try {
      console.log(id, data);
      const pushdata = await updateOrderOnPrintyo(order.id, data);
      console.log(data);
      setAlert(true);
      setAlertString("Order Created Successfully");
      setResponseSeverity("success");
      setToggelBilling(false);
      setToggelShipping(false);
      setToggelShippingCompany(false);
      setToggelShippingPrice(false);
    } catch (e) {
      setAlert(true);
      setAlertString("Something went wrong while updating order");
      setResponseSeverity("error");
      setToggelBilling(false);
      setToggelShipping(false);
      setToggelShippingCompany(false);
      setToggelShippingPrice(false);
    }
    setUpdateToOrder(false);
    setOrderUpdated(!orderupdated);
  };

  //
  const [productPriceValue, setProductPriceValue] = useState([]);

  const handleProductPriceChange = (e) => {
    setProductPriceValue({
      ...productPriceValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleProductsChanges = () => {
    console.log(productPriceValue);
  };

  return (
    <>
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="50vh"
          width="50vw"
        >
          <CircularProgress color="primary" />
        </Box>
      )}

      {order ? (
        <Container className={classes.container}>
          {alert ? (
            <Alert
              severity={responseSeverity}
              sx={{
                margin: "10px 0 20px",
              }}
            >
              {alertString}
            </Alert>
          ) : null}
          <Formik
            enableReinitialize
            const
            initialValues={{
              billing_first_name: order ? order.billing.first_name : "",
              billing_last_name: order ? order.billing.last_name : "",
              billing_country: order ? order.billing.country : "",
              billing_address_1: order ? order.billing.address_1 : "",
              billing_address_2: order ? order.billing.address_2 : "",
              billing_city: order ? order.billing.city : "",
              billing_company: order ? order.billing.company : "",
              billing_email: order ? order.billing.email : "",
              billing_phone: order ? order.billing.phone : "",
              billing_postcode: order ? order.billing.postcode : "",
              billing_state: order ? order.billing.state : "",
              shipping_first_name: order ? order.shipping.first_name : "",
              shipping_last_name: order ? order.shipping.last_name : "",
              shipping_country: order ? order.shipping.country : "",
              shipping_address_1: order ? order.shipping.address_1 : "",
              shipping_address_2: order ? order.shipping.address_2 : "",
              shipping_city: order ? order.shipping.city : "",
              shipping_company: order ? order.shipping.company : "",
              shipping_email: order ? order.shipping.email : "",
              shipping_phone: order ? order.shipping.phone : "",
              shipping_postcode: order ? order.shipping.postcode : "",
              shipping_state: order ? order.shipping.state : "",
              newCustomer: "",
              customer_note: "",
            }}
            //validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <Form>
                <Paper sx={{ padding: "1rem" }}>
                  <Box sx={{ display: "flex", flexWrap: "wrap", mb: 2 }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "1.5em",
                        marginBottom: "1rem",
                        marginRight: "1rem",
                        alignItems: "flex-start",
                        flex: 2,
                      }}
                      className={classes.orderDetailTitle}
                    >
                      Order #{id} details
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "16px", display: "none" }}
                      sx={{
                        textTransform: "none",
                        justifyContent: "right",
                        alignItems: "flex-end",
                        flex: 1,
                      }}
                      type="button"
                      align
                      onClick={() => setToggelChangeOrder(!toggleChangeOrder)}
                    >
                      Change Order
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        textTransform: "none",
                        justifyContent: "right",
                        alignItems: "flex-end",
                        flex: 1,
                      }}
                      type="submit"
                      disabled={updatetoorder}
                      align
                    >
                      {updatetoorder ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        " Update order"
                      )}
                    </Button>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      color: "#777",
                      lineHeight: "1.6em",
                      fontSize: "14px",
                      marginTop: "2px",
                    }}
                  >
                    {order ? (
                      <>
                        <div>{`Payment via ${order.payment_method}`}</div>
                        <div>{`Paid on ${moment(order.date_created.date).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}.`}</div>
                        <div>{`Customer IP : ${order.customer_ip_address}.`}</div>
                      </>
                    ) : null}
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <TypographyHeading>General</TypographyHeading>
                      <TypographySubHeading>Date created:</TypographySubHeading>
                      <CustomTypography>
                        {moment(order.date_created.date).format(
                          "MMMM DD YYYY, h:mm:ss a"
                        )}
                      </CustomTypography>
                      <TypographySubHeading>Status:</TypographySubHeading>
                      <CustomTypography>{order.status}</CustomTypography>
                      <TypographySubHeading>Customer:</TypographySubHeading>
                      <CustomTypography>{`${order.billing.first_name} ${order.billing.last_name}`}</CustomTypography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pr={2}
                      >
                        <TypographyHeading>Billing</TypographyHeading>
                        {!print && (
                          <IconButton
                            onClick={() => setToggelBilling(!toggleBilling)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                        {print && ""}
                      </Box>

                      {toggleBilling ? (
                        <Billing
                          errors={errors}
                          customer={order}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        <>
                          <CustomTypography>
                            {`${order.billing.first_name} ${order.billing.last_name}`}
                          </CustomTypography>
                          <CustomTypography>
                            {`${order.billing.address_1} ${order.billing.address_2}`}
                          </CustomTypography>
                          <CustomTypography>
                            {`${order.billing.city} ${order.billing.state} ${order.billing.country} ${order.billing.postcode}`}
                          </CustomTypography>
                          <TypographySubHeading>
                            Email address:
                          </TypographySubHeading>
                          <CustomTypography>
                            {order.billing.email}
                          </CustomTypography>
                          <TypographySubHeading>Phone:</TypographySubHeading>
                          <CustomTypography>
                            {order.billing.phone}
                          </CustomTypography>
                        </>
                      )}
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pr={2}
                      >
                        <TypographyHeading>Shipping</TypographyHeading>
                        {!print && (
                          <IconButton
                            onClick={() => setToggelShipping(!toggleShipping)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                        {print && ""}
                      </Box>
                      {toggleShipping ? (
                        <Shipping
                          errors={errors}
                          customer={order}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        <>
                          <CustomTypography>
                            {`${order.shipping.first_name} ${order.shipping.last_name}`}
                          </CustomTypography>
                          <CustomTypography>
                            {`${order.shipping.address_1} ${order.shipping.address_2}`}
                          </CustomTypography>
                          <CustomTypography>
                            {`${order.shipping.city} ${order.shipping.state} ${order.shipping.country} ${order.shipping.postcode}`}
                          </CustomTypography>
                          <TypographySubHeading>
                            Customer provided note:
                          </TypographySubHeading>
                          <CustomTypography>
                            {order.customer_note}
                          </CustomTypography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={9} sm={8} xs={6}>
                      <TypographyHeading>Product</TypographyHeading>
                    </Grid>
                    <Grid item md={1} sm={1} xs={2} textAlign="center">
                      <TypographySubHeading>Price</TypographySubHeading>
                    </Grid>
                    <Grid item md={1} sm={2} xs={2} textAlign="center">
                      <TypographySubHeading>Quantity</TypographySubHeading>
                    </Grid>
                    <Grid item md={1} sm={1} xs={2} textAlign="center">
                      <TypographySubHeading> Total</TypographySubHeading>
                    </Grid>
                  </Grid>
                  <Divider />
                  {order.line_items
                    ? order.line_items.map((item) => {
                        // console.log(item.subtotal, item.quantity);
                        return (
                          <Grid
                            container
                            key={item.id}
                            sx={{ marginTop: "10px" }}
                            justifyContent="space-between"
                          >
                            <Grid item md={9} sm={8} xs={6}>
                              <Typography
                                sx={{
                                  color: "#3c434a",
                                  fontSize: "13px",
                                  lineHeight: "1.4em",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Box display="flex" alignItems="center">
                                <CustomTypography>sku:</CustomTypography>
                                <Link
                                  href={item.permalink}
                                  underline="always"
                                  target="_blank"
                                >
                                  <CustomTypography>
                                    {item.sku}
                                  </CustomTypography>
                                </Link>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <CustomTypography>
                                  Variation ID:
                                </CustomTypography>
                                <CustomTypography>
                                  {item.variation_id}
                                </CustomTypography>
                              </Box>
                              {item.meta_data
                                ? item.meta_data.map((attributes) => {
                                    return (
                                      <Box display="flex" alignItems="center">
                                        <CustomTypography>
                                          {attributes.display_key}:
                                        </CustomTypography>
                                        <CustomTypography>
                                          {attributes.display_value}
                                        </CustomTypography>
                                      </Box>
                                    );
                                  })
                                : null}
                            </Grid>

                            <Grid item md={1} textAlign="center">
                              <CustomTypography>
                                {toggleChangeOrder ? (
                                  <Field
                                    name="product_price_change"
                                    size="small"
                                    as={TextField}
                                    defaultValue={item.subtotal / item.quantity}
                                    variant="outlined"
                                    placeholder="Price"
                                    onChange={handleProductPriceChange}
                                  />
                                ) : (
                                  `${(item.subtotal / item.quantity).toFixed(
                                    2
                                  )}`
                                )}
                              </CustomTypography>
                            </Grid>
                            <Grid item md={1} sm={2} xs={2} textAlign="center">
                              <CustomTypography>
                                {toggleChangeOrder ? (
                                  <Field
                                    name="product_qty_change"
                                    size="small"
                                    as={TextField}
                                    defaultValue={item.quantity}
                                    variant="outlined"
                                    placeholder="Quantity"
                                    onChange={handleProductPriceChange}
                                    sx={{
                                      width: "50px",
                                    }}
                                  />
                                ) : (
                                  `x${item.quantity}`
                                )}
                              </CustomTypography>
                            </Grid>
                            <Grid item md={1} sm={1} xs={2} textAlign="center">
                              <CustomTypography>
                                {" "}
                                {"$"}{" "}
                                {(
                                  (item.subtotal / item.quantity) *
                                  item.quantity
                                ).toFixed(2)}
                              </CustomTypography>
                            </Grid>
                          </Grid>
                        );
                      })
                    : null}
                  <Grid
                    container
                    sx={{ marginTop: "10px", marginBottom: "10px" }}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#3c434a",
                          fontSize: "13px",
                          lineHeight: "1.4em",
                          fontWeight: "bold",
                        }}
                      >
                        Shipping price
                      </Typography>
                    </Grid>
                    <Grid item textAlign="center">
                      <CustomTypography>
                        {"$"}{" "}
                        {toggleShippingPrice ? (
                          <TextField
                            id="standard-basic"
                            label="Shipping Price"
                            variant="standard"
                            onChange={(e) => {
                              setChangedShippingPrice(e.target.value);
                            }}
                          />
                        ) : (
                          order.shipping_total
                        )}
                        {!print && (
                          <IconButton
                            onClick={() =>
                              setToggelShippingPrice(!toggleShippingPrice)
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}{" "}
                      </CustomTypography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{ marginTop: "10px", marginBottom: "10px" }}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#3c434a",
                          fontSize: "13px",
                          lineHeight: "1.4em",
                          fontWeight: "bold",
                        }}
                      >
                        Shipping details
                      </Typography>
                    </Grid>
                    <Grid item textAlign="center">
                      <CustomTypography>
                        {toggleShippingCompany ? (
                          <TextField
                            id="standard-basic"
                            label="Shipping Label"
                            variant="standard"
                            onChange={(e) => {
                              setChangedShippingLabel(e.target.value);
                            }}
                          />
                        ) : (
                          order.shipping_lines[order.shipping_lines.length - 1]
                            .method_title
                        )}
                        {!print && (
                          <IconButton
                            onClick={() =>
                              setToggelShippingCompany(!toggleShippingCompany)
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}{" "}
                      </CustomTypography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item md={11}>
                      <TypographyHeading>Discount</TypographyHeading>
                    </Grid>
                    <Grid item md={1} textAlign="center">
                      <TypographyHeading>
                        {" "}
                        {"$"} {order.discount_total}
                      </TypographyHeading>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item md={11}>
                      <TypographyHeading>Total price</TypographyHeading>
                    </Grid>
                    <Grid item md={1} textAlign="center">
                      <TypographyHeading>
                        {" "}
                        {"$"} {order.total}
                      </TypographyHeading>
                    </Grid>
                  </Grid>
                  {toggleChangeOrder ? (
                    <Grid container>
                      <Grid item md={8}></Grid>
                      <Grid item md={4} textAlign="center">
                        <Button
                          sx={{
                            textTransform: "none",
                            justifyContent: "right",
                            alignItems: "flex-end",
                            flex: 1,
                          }}
                          color="primary"
                          type="button"
                          align="end"
                          variant="contained"
                          onClick={handleProductsChanges}
                        >
                          Update Product
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Paper>
              </Form>
            )}
          </Formik>
        </Container>
      ) : null}
      <Container sx={{ mt: 4 }} className={classes.container}>
        <Paper sx={{ padding: "1rem" }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "1.5em",
              marginBottom: "1rem",
            }}
          >
            Artwork #{id} details
          </Typography>
          <Divider />
          {artworkimages && artworkimages[0] ? (
            artworkimages.map((items, index) => {
              return (
                <Box dividers>
                  {index === 0 ? null : <Divider />}
                  <Box mb={2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TypographyHeading>Artwork Type:</TypographyHeading>
                      <CustomTypography>
                        {items ? items.design_work_details.design_style : null}
                      </CustomTypography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TypographyHeading>Product Name:</TypographyHeading>
                      <CustomTypography>
                        {items ? items.design_work_details_prodname : null}
                      </CustomTypography>
                    </Box>
                    {artworkimages &&
                    items.design_work_details.additional_information ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <TypographyHeading>
                          Additional Information:
                        </TypographyHeading>
                        <CustomTypography>
                          {
                            artworkimages[0].design_work_details
                              .additional_information
                          }
                        </CustomTypography>
                      </Box>
                    ) : null}
                    {items && items.design_work_image
                      ? items.design_work_image.map((item, index) => {
                          return (
                            <Box
                              display="flex"
                              alignItems="center"
                              key={item.id}
                              justifyContent="space-between"
                            >
                              <CustomTypography>
                                Artwork Image:
                              </CustomTypography>
                              <a
                                href={item.image_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <CustomTypography>
                                  View Image-{index}
                                </CustomTypography>
                              </a>
                            </Box>
                          );
                        })
                      : null}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <TypographyHeading>No Artwork Found</TypographyHeading>
            </Box>
          )}
        </Paper>
      </Container>
      <Box sx={{ mt: 4 }} className={classes.orderDetailsNotes}>
        <Notes order={order} id={id} />
      </Box>
    </>
  );
};

export default OrderDetails;
